<template>
	<div class="wx-modal_wrapper">
		<el-dialog title="" custom-class="dialog_wrapper" :visible.sync="dialogVisible" :show-close="false" width="50%">
			<div class="wx-modal_top">
				<p class="font_16">凭证</p>
				<i @click="get_close"></i>
			</div>
			<div style="padding: 1px 34px;">
				<div class="flex01 m_t20">
					<div class="font_12 w_70">订单编号：</div>
					<div class="wx-modal_input">
						<p class="font_14">{{ detail.order_no }}</p>
					</div>
				</div>

				<div class="hid_" style="display: flex;justify-content: space-between;">
					<div class="w2" v-if="detail.imgs && detail.imgs.length > 0">
						<div class="wx-up_image flex03">
							<div class="font_12 w_70 flex01" style="height: 30px;min-width: 70px;">文件：</div>
							<div style="display: flex;flex-wrap: wrap;width: 100%;">
								<dl class="new-dl">
									<dd v-for="(item, index) in detail.imgs" :key="index">
										<!-- <el-image style="width: 60px; height: 60px;cursor: pointer;" :src="require('../../assets/pdf.png')" fit="cover"
            		  	 @click="get_img_big(item.images_text)" v-if="item.images_text.substring(item.images_text.length-3) === 'pdf'"></el-image>
            		  	<el-image style="width: 60px; height: 60px;cursor: pointer;" :src="item.images_text" fit="cover" @click="get_img_big(item.images_text)"
            		  	 v-else></el-image> -->
										<span class="name" v-if="item.voucher">{{ item.voucher.name }}</span>
										<a :href="item.images_text" target="_blank" class="down_btn">预览</a>
										<a :href="item.images_text" :download="'凭证' + (index + 1)" target="_blank"
											class="down_btn">下载</a>
										<i class="close_icon" v-if="type_num !== 1"
											@click="get_img_close(index, item)" style="position: static;"></i>
									</dd>
								</dl>

							</div>
						</div>


					</div>
					<div class="w2">
						<div class="flex01 m_t20" v-if="type_num !== 1">
							<div class="font_12 w_70">文件类型：</div>
							<div class="wx-modal_input">
								<el-select v-model="detail.type_id" placeholder="请选择" @change="getType">
									<el-option v-for="item in options" :key="item.id" :label="item.name"
										:value="item.id">
									</el-option>
								</el-select>
							</div>
						</div>
						<div class="wx-up_image flex03">
							<div class="font_12 w_70 flex01" style="height: 30px;min-width: 70px;">上传文件：</div>
							<div style="display: flex;flex-wrap: wrap;width: 100%;">
								<dl class="new-dl">
									<dd v-for="(item, index) in imgs" :key="index">
										<!-- <el-image style="width: 60px; height: 60px;cursor: pointer;" :src="require('../../assets/pdf.png')" fit="cover"
                  	 @click="get_img_big(item.images_text)" v-if="item.images_text.substring(item.images_text.length-3) === 'pdf'"></el-image> -->
										<!-- <el-image style="width: 60px; height: 60px;cursor: pointer;" src="require('../../assets/file.png')" fit="cover" @click="get_img_big(item.images_text)"></el-image> -->

										<span class="name">{{ item.belongTypeName }}</span>
										<img src="../../assets/file.png" alt="" style="width: 40px; height: 40px;">
										<!-- <a :href="item.images_text" :download="'凭证'+(index+1)" target="_blank" class="down_btn">预览</a>
                    <a :href="item.images_text" target="_blank" class="down_btn">下载</a> -->
										<i class="close_icon" v-if="type_num !== 1"
											@click="get_img_close(index, item)" style="position: static;"></i>
									</dd>
								</dl>
								<el-upload :disabled="uploadDisabled" class="avatar-uploader" multiple
									:action="up_url" :show-file-list="false" :on-change="beforeUpload"
									:on-success="handleAvatarSuccess" v-if="type_num !== 1">
									<i class="up_icon"></i>
								</el-upload>
								<el-dialog :visible.sync="dialogVisible_">
									<img width="100%" :src="dialogImageUrl" alt="">
								</el-dialog>
							</div>
						</div>
						<div class="btn01" style="margin: 50px auto;" @click="get_save" v-if="type_num !== 1">保存</div>
					</div>

				</div>

			</div>
		</el-dialog>
	</div>
</template>

<script>
import {
	costImageDel, voucherIndexApi,
	DegressionImageDel
} from '@/api/index';

export default {
	props: {
		dialogVisible: {
			type: Boolean
		},
		detail: {
			type: Object
		},
		type: {
			type: Number
		},
		type_num: {
			type: Number
		}

	},
	data() {
		return {
			up_url: '', // 上传图片地址
			dialogVisible_: false,
			dialogImageUrl: '',
			options: [],
			optionsId: '', //类型id
			imgs: [],
			fileId: false,
			uploadDisabled: true
		}
	},
	created() {
		console.log('detail', this.detail)
		if (this.fileId) {
			this.uploadDisabled = false
		} else {
			this.uploadDisabled = true
		}
		this.up_url = this.$publicFun.get_up_img();
		voucherIndexApi({ limit: 999, page: 1 }).then(res => {
			this.options = res.data.data;
			console.log(res.data)
		})
	},
	methods: {
		getType(e) {
			let item = this.options.find(n => n.id === e)
			this.fileChange = true;
			this.detail.type_name = item.name;
			this.fileId = item.id;
			if (this.fileId) {
				this.uploadDisabled = false
			} else {
				this.uploadDisabled = true
			}
		},
		beforeUpload() {
			if (!this.fileId) {
				this.$message.error('请先选择文件类型');
				return;
			}
		},
		handleAvatarSuccess(e) {
			console.log('eeee', e)
			this.imgs.push({
				images: e.data,//图片相对路径
				images_text: this.$publicFun.get_host() + e.data,//图片全路径
				type: e.data.substring(e.data.length - 3),//图片格式.png
				belongTypeId: this.fileId, //图片所属分类id
				belongTypeName: this.detail.type_name,//图片所属分类名称
			})
			console.log('eeee-imgs', this.imgs)
			this.$forceUpdate();
		},
		get_img_big(e) {
			// this.dialogVisible_ = true;
			// this.dialogImageUrl = e;
		},
		get_img_close(index, e) {
			if (e.id) {
				this.$confirm('确定删除吗？', '提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(() => {
					if (this.type === 1) {
						DegressionImageDel({
							id: e.id
						}).then(res => {
							this.detail.imgs.splice(index, 1);
							this.$message.success('删除成功');
						})
					} else {
						costImageDel({
							id: e.id
						}).then(res => {
							this.detail.imgs.splice(index, 1);
							this.$message.success('删除成功');
						})
					}
				})
			}

			if (!e.id) {
				this.detail.imgs.splice(index, 1);
				this.$message.success('删除成功');
			}
		},
		// 关闭
		get_close() {
			this.$emit('getModalClose', false);
			this.imgs = [];
		},
		// 保存
		get_save() {
			let data = JSON.parse(JSON.stringify(this.detail));
			console.log('保存data', data)
			////// data.type_id = this.fileId;
			data.imgs = this.imgs;
			console.log(data.type_id, this.fileId)
			if (data.imgs.length === 0 && data.type_id === this.fileId) return this.$message('请上传凭证111');
			data.type_id = this.fileId;
			this.$emit('get_data_amend', data);
			setTimeout(() => {
				this.imgs = [];
				this.fileChange = false;
				console.log('aaaa')
			}, 100)
		}
	}
}
</script>

<style lang="less">
.new-dl {
	width: 100%;
}

.new-dl dd {
	margin: 0 0 15px 0;
	display: flex;
	align-items: center;
	justify-content: space-between;
}

.m01 {
	margin: 228px auto 26px auto;
}

.m02 {
	margin: 42px auto 26px auto;
}

.wrapper {
	width: 50%;
	display: inline-block;
	vertical-align: top;
}

.dialog_wrapper {
	border-radius: 8px !important;

	.el-dialog__header {
		padding: 0;
	}

	.el-dialog__body {
		padding: 0;
	}

	.el-upload {
		display: block;
	}

	.i .el-image {
		margin: 0;
	}

	.wx-modal_input {
		.el-input__inner {
			border-radius: 10px;
			border-color: #D2D2D2;

			&:focus {
				border-color: #D2D2D2;
			}
		}

		.el-textarea__inner {
			border-radius: 10px;
			border-color: #D2D2D2;

			&:focus {
				border-color: #D2D2D2;
			}
		}
	}
}

.wx-modal_top {
	height: 60px;
	display: flex;
	position: relative;
	align-items: center;
	justify-content: center;
	border-bottom: 1px solid #D0D0D0;

	i {
		top: 17px;
		right: 20px;
		display: block;
		width: 26px;
		height: 26px;
		cursor: pointer;
		position: absolute;
		background: url(../../assets/wx/05@2x.png) no-repeat center;
		background-size: 100% 100%;
	}
}

.wx-up_image {
	margin-top: 23px;
}

.wx-modal_input {
	flex: 1;
}

.up_icon {
	display: block;
	width: 60px;
	height: 60px;
	background: url(../../assets/wx/06@2x.png) no-repeat center;
	background-size: 100% 100%;
}

.close_icon {
	top: -10px;
	right: -10px;
	width: 20px;
	height: 20px;
	display: block;
	position: absolute;
	cursor: pointer;
	background: url(../../assets/wx/07.png) no-repeat center;
	background-size: 100% 100%;
}

.down_btn {
	color: #5B8EF0;
	text-align: center;
	display: block;
	margin: 5px auto;
}

.hid_ .w2 {
	width: 40%;
}
</style>
