<template>
	<div class="box01">
		<v-crumbs :crumbsType='crumbsType_num'></v-crumbs>
		<div class="in_class">
			<div class="form_wrapper" style="position: relative;">
				<div class="shade_wrapper" v-if="crumbsType_num == 2"></div>
				<div class="form_wrapper_left">
					<div class="form_wrapper_block">
						<div class="form_wrapper_title">
							<p></p>
							<p>基本信息</p>
						</div>
						<div class="info_wrapper">
							<div class="info_wrapper_block">
								<div class="form_input_wrapper">
									<p class="form_input_label">订单编号:</p>
									<div class="form_input">
										<el-input v-enter-next-input v-model="submit_info.order_no" readonly placeholder="保存时自动生成"></el-input>
									</div>
								</div>
							</div>
							<div class="info_wrapper_block">
								<div class="form_input_wrapper">
									<p class="form_input_label"><span class="c_ff6f6f">*</span>报价人员:</p>
									<div class="form_input">
										<el-input v-enter-next-input v-model="submit_info.bidder" placeholder="请填写报价人员"></el-input>
									</div>
								</div>
							</div>
							<div class="info_wrapper_block">
								<div class="form_input_wrapper">
									<p class="form_input_label"><span class="c_ff6f6f">*</span>报价有效期:</p>
									<div class="form_input">
										<el-date-picker  v-enter-next-input v-model="submit_info.validity" type="date" value-format="yyyy-MM-dd" placeholder="选择日期"></el-date-picker>
									</div>
								</div>
							</div>
							<div class="info_wrapper_block">
								<div class="form_input_wrapper">
									<p class="form_input_label"><span class="c_ff6f6f">*</span>服务类型:</p>
									<div class="form_input">
										<v-select  v-enter-next-input :icon_num="true" :list="serve_list" :value_name="submit_info.service_type_id_name" @result="get_serve_type"></v-select>
									</div>
								</div>
							</div>
							<div class="form_choose_wrapper">
								<p class="form_input_label"><span class="c_ff6f6f">*</span>服务范围:</p>
								<div class="form_choose_content" style="flex-wrap: wrap;">
									<div class="form_choose_block" :class="{'active': item.id == submit_info.services_scope_id}" v-for="(item, index) in services_scope_list">
										<i @click="submit_info.services_scope_id = item.id"></i>
										<p>{{item.name}}</p>
									</div>
								</div>
							</div>
							<div class="form_choose_wrapper">
								<p class="form_input_label"><span class="c_ff6f6f">*</span>运输方式:</p>
								<div class="form_choose_content" style="flex-wrap: wrap;">
									<div class="form_choose_block" :class="{'active': item.id == submit_info.transport_id}" v-for="(item, index) in transport_list">
										<i @click="submit_info.transport_id = item.id"></i>
										<p>{{item.name}}</p>
									</div>
								</div>
							</div>
							<div class="form_choose_wrapper">
								<p class="form_input_label"><span class="c_ff6f6f">*</span>运输流向:</p>
								<div class="form_choose_content" style="flex-wrap: wrap;">
									<div class="form_choose_block" :class="{'active': item.id == submit_info.transportation_id}" v-for="(item, index) in transportation_list">
										<i @click="submit_info.transportation_id = item.id"></i>
										<p>{{item.name}}</p>
									</div>
								</div>
							</div>
							<div class="info_wrapper_block">
								<div class="form_input_wrapper">
									<p class="form_input_label">航司/船司:</p>
									<div class="form_input">
										<v-select  v-enter-next-input :list="company_list" :value_name="submit_info.shipping_company_name" :from="1" @result="get_ship"></v-select>
									</div>
								</div>
							</div>
							<div class="info_wrapper_block">
								<div class="form_input_wrapper">
									<p class="form_input_label">线路:</p>
									<div class="form_input">
										<v-select  v-enter-next-input :icon_num="true" :list="luxian_list" :value_name="submit_info.circuit_id_name" @result="get_luxian"></v-select>
									</div>
								</div>
							</div>
							<div class="info_wrapper_block">
								<div class="form_input_wrapper">
									<p class="form_input_label">运单编号:</p>
									<div class="form_input w_510">
										<el-input v-enter-next-input v-model="submit_info.waybill_no" placeholder="请填写运单编号"></el-input>
									</div>
								</div>
							</div>
							<div class="info_wrapper_block">
								<div class="form_input_wrapper">
									<p class="form_input_label w_118"><span class="c_ff6f6f">*</span>起运地/起运港:</p>
									<div class="form_input w_480">
										<v-select v-enter-next-input :icon_num="true" :list="start_harbor_list" :value_name="submit_info.shipment_id_name" :from="2"
										 :service_type="submit_info.service_type_id" @result="get_harbor"></v-select>
									</div>
								</div>
							</div>
							<div class="info_wrapper_block">
								<div class="form_input_wrapper">
									<p class="form_input_label w_118"><span class="c_ff6f6f">*</span>目的地/目的港:</p>
									<div class="form_input w_480">
										<v-select v-enter-next-input :icon_num="true" :list="end_harbor_list" :value_name="submit_info.destination_id_name" :from="3"
										 :service_type="submit_info.service_type_id" @result="get_end_harbor"></v-select>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div class="form_wrapper_block">
						<div class="form_wrapper_title">
							<p></p>
							<p>货物信息</p>
						</div>
						<div class="form_table_wrapper">
							<div class="form_table_add">
								<div class="form_table_add_block">
									<img src="../../assets/04@2x.png" @click="get_add_huowu" />
									<p>新增一组</p>
								</div>
							</div>
							<div>
								<div class="form_table_list" v-for="(item, index) in huowu_list" :key="index">
									<div class="form_delete_icon">
										<i @click="get_del_huowu(item, index)"></i>
									</div>
									<div class="info_wrapper_block">
										<div class="form_input_wrapper">
											<p class="form_input_label"><span class="c_ff6f6f">*</span>件数:</p>
											<div class="form_input">
												<el-input v-enter-next-input v-model="item.cases" placeholder="请填写件数"></el-input>
											</div>
										</div>
									</div>
									<div class="info_wrapper_block">
										<div class="form_input_wrapper">
											<p class="form_input_label"><span class="c_ff6f6f">*</span>包装:</p>
											<div class="form_input">
												<v-select v-enter-next-input :list="pack_list" :index="index" :value_name="item.pack" @result="get_pak"></v-select>
												<!-- <el-input v-model="item.pack" placeholder="请填写包装"></el-input> -->
											</div>
										</div>
									</div>
									<div class="info_wrapper_block">
										<div class="form_input_wrapper">
											<p class="form_input_label"><span class="c_ff6f6f">*</span>毛重:</p>
											<div class="form_input">
												<el-input v-enter-next-input v-model="item.rough_weight" placeholder="请填写毛重"></el-input>
											</div>
										</div>
									</div>
									<div class="info_wrapper_block">
										<div class="form_input_wrapper">
											<p class="form_input_label"><span class="c_ff6f6f">*</span>计重:</p>
											<div class="form_input" style="display: flex;align-items: center;">
												<el-input v-enter-next-input v-model="item.weight" placeholder="自动算出"></el-input>
												<p class="form_input_unit">KG</p>
											</div>
										</div>
									</div>
									<div class="info_wrapper_block">
										<div class="form_input_wrapper">
											<p class="form_input_label"><span class="c_ff6f6f"></span>长:</p>
											<div class="form_input">
												<el-input v-enter-next-input v-model="item.long" placeholder="请填写长度"></el-input>
											</div>
										</div>
									</div>
									<div class="info_wrapper_block">
										<div class="form_input_wrapper">
											<p class="form_input_label"><span class="c_ff6f6f"></span>宽:</p>
											<div class="form_input">
												<el-input v-enter-next-input v-model="item.broad" placeholder="请填写宽度"></el-input>
											</div>
										</div>
									</div>
									<div class="info_wrapper_block">
										<div class="form_input_wrapper">
											<p class="form_input_label"><span class="c_ff6f6f"></span>高:</p>
											<div class="form_input">
												<el-input v-enter-next-input v-model="item.tall" placeholder="请填写高度"></el-input>
											</div>
										</div>
									</div>
									<div class="info_wrapper_block">
										<div class="form_input_wrapper dis">
											<p class="form_input_label"><span class="c_ff6f6f">*</span>体积:</p>
											<div class="form_input" style="display: flex;align-items: center;">
												<el-input v-enter-next-input v-model="item.bulk" placeholder="自动算出"></el-input>
												<p class="form_input_unit">CBM</p>
											</div>
										</div>
									</div>
									<div class="info_wrapper_block">
										<div class="form_input_wrapper">
											<p class="form_input_label"><span class="c_ff6f6f">*</span>中文品名:</p>
											<div class="form_input">
												<el-input v-enter-next-input v-model="item.chinese_name" placeholder="请填写中文品名"></el-input>
											</div>
										</div>
									</div>
									<div class="info_wrapper_block">
										<div class="form_input_wrapper">
											<p class="form_input_label">英文品名:</p>
											<div class="form_input w_476">
												<el-input v-model="item.english_name" placeholder="请填写英文品名"></el-input>
											</div>
										</div>
									</div>
									<div class="info_wrapper_block">
										<div class="form_input_wrapper">
											<p class="form_input_label">HS编码:</p>
											<div class="form_input">
												<v-select v-enter-next-input :list="hs_list" :index="index" :value_name="item.hs_code_name" :from="4" @result="get_hs"></v-select>
											</div>
										</div>
									</div>
									<div class="info_wrapper_block">
										<div class="form_input_wrapper">
											<p class="form_input_label">货值:</p>
											<div class="form_input">
												<el-input v-enter-next-input v-model="item.low_value" placeholder="请填写货值"></el-input>
											</div>
										</div>
									</div>
									<div class="info_wrapper_block">
										<div class="form_input_wrapper">
											<p class="form_input_label">币制:</p>
											<div class="form_input">
												<v-select v-enter-next-input :list="currency_list" :index="index" :value_name="item.currency_system_name" @result="get_currency"></v-select>
											</div>
										</div>
									</div>
									<div class="info_wrapper_block">
										<div class="form_input_wrapper">
											<p class="form_input_label">船名/航次:</p>
											<div class="form_input">
												<el-input v-enter-next-input v-model="item.flight_name" placeholder="请填写船名/航次"></el-input>
											</div>
										</div>
									</div>
									<div class="info_wrapper_block">
										<div class="form_input_wrapper">
											<p class="form_input_label">起运时间:</p>
											<div class="form_input">
												<el-date-picker v-enter-next-input v-model="item.shipment_date" value-format="yyyy-MM-dd" type="date" placeholder="选择日期"></el-date-picker>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div class="form_wrapper_block">
						<div class="form_wrapper_title">
							<p></p>
							<p>费用信息</p>
						</div>
						<div class="form_table_wrapper">
							<div class="form_table_add">
								<div class="form_table_add_block">
									<img src="../../assets/04@2x.png" @click="get_add_feiyong" />
									<p>新增费用</p>
								</div>
							</div>
							<div>
								<div class="form_th_wrapper">
									<div class="form_th_list">
										<div class="form_th_list_block">序号</div>
										<div class="form_th_list_block active">费用名称</div>
										<div class="form_th_list_block">数量</div>
										<div class="form_th_list_block">单价</div>
										<div class="form_th_list_block">金额</div>
										<div class="form_th_list_block">币制</div>
										<div class="form_th_list_block active">备注</div>
										<div class="form_th_list_block active">操作</div>
									</div>
									<div class="form_th_list" v-for="(item, index) in feiyong_list" :key="index">
										<div class="form_th_list_block">{{index + 1}}</div>
										<div class="form_th_list_block active" v-if="!item.is_save">
											<el-select v-enter-next-input v-model="item.name_id_name" filterable placeholder="请选择" @change="get_change(1, $event, index)">
												<el-option v-for="item in feiyong_name_list" :key="item.id" :label="item.name" :value="item"></el-option>
											</el-select>
										</div>
										<div class="form_th_list_block active" v-else>{{item.name_id_name}}</div>
										<div class="form_th_list_block" v-if="!item.is_save">
											<el-input v-enter-next-input placeholder="请输入数量" v-model="item.number"></el-input>
										</div>
										<div class="form_th_list_block" v-else>{{item.number}}</div>
										<div class="form_th_list_block" v-if="!item.is_save">
											<el-input v-enter-next-input placeholder="请输入单价" v-model="item.money"></el-input>
										</div>
										<div class="form_th_list_block" v-else>{{item.money}}</div>
										<div class="form_th_list_block" v-if="!item.is_save">
											<el-input v-enter-next-input placeholder="请输入金额" v-model="item.price"></el-input>
										</div>
										<div class="form_th_list_block" v-else>{{item.price}}</div>
										<div class="form_th_list_block" v-if="!item.is_save">
											<el-select v-enter-next-input v-model="item.currency_system_name" filterable placeholder="请选择" @change="get_change(2, $event, index)">
												<el-option v-for="item in currency_list" :key="item.value" :label="item.label" :value="item"></el-option>
											</el-select>
										</div>
										<div class="form_th_list_block" v-else>{{item.currency_system_name}}</div>
										<div class="form_th_list_block active" v-if="!item.is_save">
											<el-input v-enter-next-input placeholder="请输入备注" v-model="item.remark"></el-input>
										</div>
										<div class="form_th_list_block active" v-else>{{item.remark?item.remark: '-'}}</div>
										<div class="form_th_list_block operat active">
											<div class="c_5bbef0 pointer_" @click="get_resource_btn(item)">{{item.is_save?'编辑': '保存'}}</div>
											<div class="c_ff6f6f pointer_ m_l21" @click="get_resource_del(index, item)">删除</div>
										</div>
									</div>
								</div>
							</div>
							<div class="form_total">
								<div style="flex: 1;display: flex;flex-wrap: wrap;">
									<div class="form_total" v-if="item.id !== 7" v-for="(item, index) in hv" :key="index">
										<p>{{item.name}}*汇率：</p>
										<div class="form_total_input">
											<el-input v-enter-next-input placeholder="" v-model="item.hl"></el-input>
										</div>
									</div>
								</div>
								<div>
									<div class="form_total">
										<p>合计：</p>
										<div class="form_total_input">
											<el-input v-enter-next-input placeholder="" v-model="submit_info.total_price"></el-input>
										</div>
										<p>RMB</p>
									</div>
									<p class="c_ff6f6f hj_btn" style="margin-left: 67px;cursor: pointer;" @click="get_heji">点击获取合计数据</p>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div class="form_wrapper_right form_wrapper_block">
					<div class="form_wrapper_info">
						<p class="form_wrapper_info_title"><span class="c_ff6f6f">*</span>发货人信息:</p>
						<div>
							<el-input  v-enter-next-input type="textarea" placeholder="请填写发货人信息" v-model="submit_info.shipper_info"></el-input>
						</div>
					</div>
					<div class="form_wrapper_info">
						<p class="form_wrapper_info_title"><span class="c_ff6f6f">*</span>收货人信息:</p>
						<div>
							<el-input v-enter-next-input type="textarea" placeholder="请填写收货人信息" v-model="submit_info.consignee_info"></el-input>
						</div>
					</div>
					<div class="form_wrapper_info">
						<p class="form_wrapper_info_title">唛头:</p>
						<div>
							<el-input v-enter-next-input type="textarea" placeholder="请填写唛头" v-model="submit_info.mark"></el-input>
						</div>
					</div>
					<div class="form_input_wrapper">
						<p class="form_input_label"><span class="c_ff6f6f">*</span>客户名称：</p>
						<div class="form_input">
							<v-select v-enter-next-input :icon_num="true" :list="kehu_list" :index="1" :value_name="submit_info.company_id_name" @result="get_kehu_serch"></v-select>
						</div>
					</div>
					<div class="form_input_wrapper">
						<p class="form_input_label"><span class="c_ff6f6f">*</span>联系人：</p>
						<div class="form_input">
							<v-select v-enter-next-input :icon_num="true" :list="lxr_list" :index="2" :value_name="submit_info.linkman_id_name" @result="get_kehu_serch"></v-select>
						</div>
					</div>
					<div class="form_input_wrapper">
						<p class="form_input_label"><span class="c_ff6f6f">*</span>联系电话：</p>
						<div class="form_input">
							<el-input v-enter-next-input v-model="submit_info.mobile" placeholder="请填写联系电话"></el-input>
						</div>
					</div>
					<div class="form_input_wrapper">
						<p class="form_input_label">联系邮箱：</p>
						<div class="form_input">
							<el-input v-enter-next-input v-model="submit_info.email" placeholder="请填写联系邮箱"></el-input>
						</div>
					</div>
					<div class="form_input_wrapper">
						<p class="form_input_label"><span class="c_ff6f6f">*</span>结算账期:</p>
						<div class="form_input">
							<el-input v-enter-next-input v-model="submit_info.payment_days_name" placeholder="请填写联系邮箱" readonly=""></el-input>
							<!-- <v-select :icon_num="true" :list="zhangqi_list" :index="3" :value_name="submit_info.payment_days_name" @result="get_kehu_serch"></v-select> -->
						</div>
					</div>
					<div class="form_input_wrapper">
						<p class="form_input_label"><span class="c_ff6f6f">*</span>结算方式:</p>
						<div class="form_input">
							<v-select :icon_num="true" :list="fangshi_list" :index="4" :value_name="submit_info.pay_type_name" @result="get_kehu_serch"></v-select>
						</div>
					</div>
					<div class="form_choose_wrapper">
						<p class="form_input_label">报价确认：</p>
						<div class="form_choose_content">
							<div class="form_choose_block" :class="{'active': submit_info.offer_confirm === 1}">
								<i @click="submit_info.offer_confirm = 1"></i>
								<p>是</p>
							</div>
							<div class="form_choose_block" :class="{'active': submit_info.offer_confirm === 0}">
								<i @click="submit_info.offer_confirm = 0"></i>
								<p>否</p>
							</div>
						</div>
					</div>
					<div class="form_choose_wrapper">
						<p class="form_input_label">转入订单：</p>
						<div class="form_choose_content">
							<div class="form_choose_block" :class="{'active': submit_info.turn_order === 1}">
								<i @click="submit_info.turn_order = 1"></i>
								<p>是</p>
							</div>
							<div class="form_choose_block" :class="{'active': submit_info.turn_order === 0}">
								<i @click="submit_info.turn_order = 0"></i>
								<p>否</p>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div class="form_wrapper edit" v-if="crumbsType_num == 2">
				<div class="form_wrapper_block" style="width: 100%;">
					<div class="form_wrapper_title">
						<p></p>
						<p>成本费用</p>
					</div>
					<!-- <div class="form_delete_icon">
						<i @click="get_del_chengben"></i>
					</div> -->
					<div class="form_edit_wrapper">
						<div class="form_wrapper_left">
							<div class="form_table_add">
								<div class="form_table_add_block">
									<img src="../../assets/04@2x.png" @click="get_add_chengben_feiyong" />
									<p>新增费用</p>
								</div>
							</div>
							<div class="form_th_wrapper" style="margin-top: 0;">
								<div class="form_th_list">
									<div class="form_th_list_block">序号</div>
									<div class="form_th_list_block active">费用名称</div>
									<div class="form_th_list_block">数量</div>
									<div class="form_th_list_block">单价</div>
									<div class="form_th_list_block">金额</div>
									<div class="form_th_list_block">币制</div>
									<div class="form_th_list_block active">备注</div>
									<div class="form_th_list_block active">操作</div>
								</div>
								<div class="form_th_list" v-for="(item, index) in chengben_feiyong_list" :key="index">
									<div class="form_th_list_block">{{index + 1}}</div>
									<div class="form_th_list_block active" v-if="!item.is_save">
										<el-select v-enter-next-input v-model="item.name_id_name" filterable placeholder="请选择" @change="get_change(3, $event, index)">
											<el-option v-for="item in feiyong_name_list" :key="item.id" :label="item.name" :value="item"></el-option>
										</el-select>
									</div>
									<div class="form_th_list_block active" v-else>{{item.name_id_name}}</div>
									<div class="form_th_list_block" v-if="!item.is_save">
										<el-input v-enter-next-input placeholder="请输入数量" v-model="item.number"></el-input>
									</div>
									<div class="form_th_list_block" v-else>{{item.number}}</div>
									<div class="form_th_list_block" v-if="!item.is_save">
										<el-input v-enter-next-input placeholder="请输入单价" v-model="item.money"></el-input>
									</div>
									<div class="form_th_list_block" v-else>{{item.money}}</div>
									<div class="form_th_list_block" v-if="!item.is_save">
										<el-input v-enter-next-input placeholder="请输入金额" v-model="item.price"></el-input>
									</div>
									<div class="form_th_list_block" v-else>{{item.price}}</div>
									<div class="form_th_list_block" v-if="!item.is_save">
										<el-select v-model="item.currency_system_name" filterable placeholder="请选择" @change="get_change(4, $event, index)">
											<el-option v-for="item in currency_list" :key="item.value" :label="item.label" :value="item"></el-option>
										</el-select>
									</div>
									<div class="form_th_list_block" v-else>{{item.currency_system_name}}</div>
									<div class="form_th_list_block active" v-if="!item.is_save">
										<el-input v-enter-next-input placeholder="请输入备注" v-model="item.remark"></el-input>
									</div>
									<div class="form_th_list_block active" v-else>{{item.remark?item.remark: '-'}}</div>
									<div class="form_th_list_block operat active">
										<div class="c_5bbef0 pointer_" @click="get_resource_btn(item, 1)">{{item.is_save?'编辑': '保存'}}</div>
										<div class="c_ff6f6f pointer_ m_l21" @click="get_resource_chenben_del(index, item)">删除</div>
									</div>
								</div>
							</div>
							<div class="form_total">
								<div style="flex: 1;display: flex;flex-wrap: wrap;">
									<div class="form_total" v-if="item.id !== 7" v-for="(item, index) in chengben_hv" :key="index">
										<p>{{item.name}}*汇率：</p>
										<div class="form_total_input">
											<el-input v-enter-next-input placeholder="" v-model="item.hl"></el-input>
										</div>
									</div>
								</div>
								<div>
									<div class="form_total">
										<p>合计：</p>
										<div class="form_total_input">
											<el-input v-enter-next-input placeholder="" v-model="chengben_submit_info.cost_total_price"></el-input>
										</div>
										<p>RMB</p>
									</div>
									<p class="c_ff6f6f hj_btn" style="margin-left: 67px;cursor: pointer;" @click="get_chengben_heji">点击获取合计数据</p>
								</div>
							</div>
							<!-- <div class="form_total">
								<p>合计：</p>
								<div class="form_total_input">
									<el-input placeholder="" v-model="chengben_submit_info.cost_total_price"></el-input>
								</div>
								<p>RMB</p>
							</div> -->
						</div>
						<div style="width: 1.4%;display: flex;justify-content: center;">
							<div class="form_edit_line"></div>
						</div>
						<div class="form_wrapper_right p_tb8">
							<div class="form_input_wrapper">
								<p class="form_input_label"><span class="c_ff6f6f">*</span>供应商名称:</p>
								<div class="form_input">
									<v-select v-enter-next-input :icon_num="true" :list="gongyingshang_list" :index="5" :value_name="chengben_submit_info.company_id_name"
									 @result="get_kehu_serch"></v-select>
								</div>
							</div>
							<div class="form_input_wrapper">
								<p class="form_input_label"><span class="c_ff6f6f">*</span>联系人：</p>
								<div class="form_input">
									<v-select v-enter-next-input :icon_num="true" :list="lxr_list" :index="6" :value_name="chengben_submit_info.linkman_id_name"
									 @result="get_kehu_serch"></v-select>
								</div>
							</div>
							<div class="form_input_wrapper">
								<p class="form_input_label"><span class="c_ff6f6f">*</span>联系电话：</p>
								<div class="form_input">
									<el-input v-enter-next-input v-model="chengben_submit_info.mobile" placeholder="请填写联系电话"></el-input>
								</div>
							</div>
							<div class="form_input_wrapper">
								<p class="form_input_label">结算账期:</p>
								<div class="form_input">
									<el-input v-enter-next-input v-model="chengben_submit_info.payment_days_name" placeholder="请填写结算账期" readonly=""></el-input>
									<!-- <v-select :icon_num="true" :list="zhangqi_list" :index="7" :value_name="chengben_submit_info.payment_days_name"
									 @result="get_kehu_serch"></v-select> -->
								</div>
							</div>
							<div class="form_input_wrapper">
								<p class="form_input_label"><span class="c_ff6f6f">*</span>结算方式:</p>
								<div class="form_input">
									<v-select v-enter-next-input :icon_num="true" :list="fangshi_list" :index="8" :value_name="chengben_submit_info.pay_type_name"
									 @result="get_kehu_serch"></v-select>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div class="form_wrapper">
				<div class="form_wrapper_block" style="width: 100%;">
					<!-- 成本编辑 -->
					<div class="form_wrapper_btn_wrapper" v-if="crumbsType_num == 2">
						<div class="btn01" @click="get_chengben_save_info">保存</div>
						<div class="btn01" @click="get_reset">重置</div>
						<!-- <div class="btn01" @click="get_pay">生成应付结算</div> -->
					</div>
					<div class="form_wrapper_btn_wrapper" v-else>
						<div class="btn01" @click="get_save_info(1)">保存</div>
						<div class="btn01" @click="get_muban">生成模版</div>
						<div class="btn01" @click="$publicFun.get_link('/offer/pdf?id=' + id, {})">预览</div>
						<div class="btn01" @click="$publicFun.get_link('/offer/pdf?id=' + id, {})">导出</div>
					</div>
				</div>
			</div>
		</div>
		<!-- 模态 -->
		<v-modal :dialogVisible="dialogVisible" :type="7" :detail="detail" @getModalClose="getModalClose" @get_data_amend="get_data_amend"></v-modal>
	</div>
</template>

<script>
	import crumbs from '../../components/crumbs/index.vue'; // 面包屑
	import select05 from '../../components/select/index05.vue'; // 模糊查询
	import modal from '../../components/wx-modal/index.vue'; // 模板模态

	import {
		hsCode,
		currency,
		companyList,
		startHarbor,
		endHarbor,
		addDcList,
		aviationCompan,
		shipCompany,
		getAllList,
		offerAdd,
		serviceType,
		services,
		tspMode,
		transport,
		circuit,
		costName,
		getAllLinkman,
		payDays,
		payType,
		offerShiftToTemplate,
		offerShow,
		offerEdit,
		offerCargoDel,
		offerCostDel,
		degressionInfoAdd,
		degressionInfoEdit,
		degressionInfoShow,
		degressionInfoDel,
		offerReceivable,
		checkReceivable,
		packing,
		companyDetail
	} from '@/api/index';

	export default {
		components: {
			'v-modal': modal,
			'v-crumbs': crumbs,
			'v-select': select05
		},
		data() {
			return {
				id: '',
				pack_list: [],
				is_chengben: false,
				crumbsType_num: 2,
				dialogVisible: false,
				feiyong_all: '',
				chengbne_feiyong_all: 0,
				kehu_list: [],
				gongyingshang_list: [],
				lxr_list: [],
				zhangqi_list: [],
				fangshi_list: [],
				hs_list: [], // hs编码
				luxian_list: [], // 路线
				serve_list: [], // 服务类型
				currency_list: [], // 币制
				company_list: [], // 航司/船司
				supplier_list: [], // 供应商
				transport_list: [], // 运输方式
				transportation_list: [], // 运输流向
				services_scope_list: [], // 服务范围
				end_harbor_list: [], // 目的地/目的港
				start_harbor_list: [], // 起运地/起运港		
				feiyong_name_list: [], // 费用名称
				detail: {
					name: '',
				},
				submit_info: {
					order_no: '', // 报价编号
					bidder: '', // 报价人员
					validity: '', // 报价有效期
					service_type_id: 0, // 服务类型
					service_type_id_name: '',
					services_scope_id: '', // 服务范围
					transport_id: '', // 运输方式
					transportation_id: '', // 运输流向
					circuit_id: '', // 线路
					circuit_id_name: '',
					waybill_no: '', // 运单编号
					shipping_company: '', // 航司/船司
					shipping_company_name: '',
					shipment_id: '', // 起运地/起运港
					shipment_id_name: '',
					destination_id: '', // 目的地/目的港
					destination_id_name: '',
					shipper_info: '', // 发货人信息
					consignee_info: '', // 收货人信息
					mark: '', // 唛头
					company_id: '', // 客户名称
					company_id_name: '',
					linkman_id: '', // 联系人
					linkman_id_name: '',
					mobile: '', // 联系电话
					email: '', // 邮箱
					payment_days: '', // 计算账期
					payment_days_name: '',
					pay_type: '', // 结算方式
					pay_type_name: '',
					offer_confirm: 0, // 报价确认1:是0否
					turn_order: 0, // 转入订单1:是0否
					cargo: [], // 货物信息
					cost: [], // 费用信息	
					total_price: '', // 总计
					rate: []
				},
				huowu_list: [{
					cases: '', // 件数
					pack: '', // 包装
					rough_weight: '', // 毛重
					weight: '', // 计重
					long: '', // 长
					tall: '', // 高
					broad: '', // 宽
					bulk: '', // 体积
					chinese_name: '', // 中文品名
					english_name: '', // 英文品名
					hs_code: '', // HS编码
					hs_code_name: '',
					low_value: '', // 货值
					currency_system: '', // 币制
					currency_system_name: '',
					flight_name: '', // 船名/航次
					shipment_date: '' // 起运日期
				}], // 货物信息
				feiyong_list: [{
					is_save: false,
					name_id: '', // 费用名称
					name_id_name: '',
					number: '', // 数量
					money: '', // 单价
					currency_system: '', // 币制
					currency_system_name: '',
					remark: '', // 备注
					price: '' // 金额
				}],
				// 成本
				chengben_submit_info: {
					order_id: '',
					type_id: 2,
					company_id: '',
					company_id_name: '',
					linkman_id: '',
					linkman_id_name: '',
					mobile: '',
					payment_days: '',
					payment_days_name: '',
					pay_type: '',
					pay_type_name: '',
					info: [],
					cost_total_price: '',
					rate: []
				},
				chengben_feiyong_list: [{
					is_save: false,
					name_id: '',
					name_id_name: '',
					number: '',
					money: '',
					currency_system: '',
					currency_system_name: '',
					remark: '',
					price: ''
				}],
				hv: [],
				chengben_hv: [],
			}
		},
		created() {
			this.id = this.$route.query.id;

			if (this.$route.query.type) {
				this.crumbsType_num = 222
			} else {
				this.get_chengben_detail();
			}

			this.get_detail();

			this.get_serve_list();
			this.getServicesList();
			this.getTspModeList();
			this.getTransportList();
			// this.get_getAllList();
			this.get_luxian_list();
			// this.getStartHarborList();
			// this.getEndHarborList();
			// this.getHsCodeList();
			this.getCurrencyList();
			this.getCostNameList();
			this.getCompanyList();
			// this.getPayDays();
			// this.getPayTypeList();
			this.getPackingList();
		},
		watch: {
			huowu_list: {
				deep: true,
				handler(val) {
					// 体积重量（kg）= 件数*长*宽*高/6000;
					// 体积（cbm）= 体积重量/167;
					// 计重 = 毛重or体积重量，取最大值;
					let list_ = val;

					list_.forEach((n, k) => {
						if (n.long && n.broad && n.tall && n.cases) {
							n.tj = Number(n.cases) * Number(n.long) * Number(n.broad) * Number(n.tall) / 1000000; // 体积
							setTimeout(() => {
								n.bulk = Math.round(n.tj * 100) / 100;
							}, 300)
						} else {
							n.tj = '';
							n.bulk = '';
						}

						if (n.tj) {
							n.tj_zl = Number(n.tj) * 167;
						} else {
							n.tj_zl = '';
						}

						if (n.rough_weight && n.tj_zl) {
							n.jz = Number(n.rough_weight) > Number(n.tj_zl) ? n.rough_weight : n.tj_zl;
							
							if (!n.bulk) {
							 	n.weight = Math.round(n.jz * 100) / 100;
							}
						} else {
							n.jz = '';
							// n.weight = '';
						}
					})
				}
			},
			feiyong_list: {
				deep: true,
				handler(val) {
					let list_ = val;
					let ids = [];
					let ids_list = [];

					this.hv = [];

					list_.forEach((n, k) => {
						if (n.money && n.number) {
							n.price = (Number(n.money) * Number(n.number)).toFixed(2);
						} else {
							n.price = '';
						}

						ids.push(n.currency_system_name);
						ids_list.push(n.currency_system);
					})

					let ids_name = [ids[0]]; //结果数组
					let ids_ = [ids_list[0]]; //结果数组
					//从第二项开始遍历
					for (var i = 1; i < ids.length; i++) {
						if (ids.indexOf(ids[i]) == i) ids_name.push(ids[i]);
						if (ids_list.indexOf(ids_list[i]) == i) ids_.push(ids_list[i]);
					}

					ids_name.forEach((n, k) => {
						if (n) {
							this.hv.push({
								name: n,
								hl: '1',
								id: ids_[k]
							})
						}
					})
				}
			},
			chengben_feiyong_list: {
				deep: true,
				handler(val) {
					let list_ = val;
					let ids = [];
					let ids_list = [];

					this.chengben_hv = [];

					list_.forEach((n, k) => {
						if (n.money && n.number) {
							n.price = (Number(n.money) * Number(n.number)).toFixed(2);
						} else {
							n.price = '';
						}

						ids.push(n.currency_system_name);
						ids_list.push(n.currency_system);
					})

					let ids_name = [ids[0]]; //结果数组
					let ids_ = [ids_list[0]]; //结果数组
					//从第二项开始遍历
					for (var i = 1; i < ids.length; i++) {
						if (ids.indexOf(ids[i]) == i) ids_name.push(ids[i]);
						if (ids_list.indexOf(ids_list[i]) == i) ids_.push(ids_list[i]);
					}

					ids_name.forEach((n, k) => {
						if (n) {
							this.chengben_hv.push({
								name: n,
								hl: '1',
								id: ids_[k]
							})
						}
					})
				}
			}
		},
		methods: {
			// 合计
			get_heji() {
				let array = this.hv;
				let p_ = 0;

				array.forEach((n_, k_) => {
					array[k_].list = [];
					array[k_].price = 0
				})

				this.feiyong_list.forEach((n, k) => {
					array.forEach((n_, k_) => {
						if (n.currency_system == n_.id) {
							array[k_].list.push(n)
						}
					})
				})

				array.forEach((n_, k_) => {
					if (n_.list.length > 0) {
						n_.list.forEach((n, k) => {
							n_.price = n_.price + Number(n.price)
						})
					}
				})

				array.forEach((n_, k_) => {
					n_.price = ((n_.price.toFixed(2)) * n_.hl).toFixed(2);

					p_ = p_ + Number(n_.price);
				})

				this.submit_info.total_price = p_.toFixed(2);
			},
			// 成本合计
			get_chengben_heji() {
				let array = this.chengben_hv;
				let p_ = 0;

				array.forEach((n_, k_) => {
					array[k_].list = [];
					array[k_].price = 0
				})

				this.chengben_feiyong_list.forEach((n, k) => {
					array.forEach((n_, k_) => {
						if (n.currency_system == n_.id) {
							array[k_].list.push(n)
						}
					})
				})

				array.forEach((n_, k_) => {
					if (n_.list.length > 0) {
						n_.list.forEach((n, k) => {
							n_.price = n_.price + Number(n.price)
						})
					}
				})

				array.forEach((n_, k_) => {
					n_.price = ((n_.price.toFixed(2)) * n_.hl).toFixed(2);

					p_ = p_ + Number(n_.price);
				})

				this.chengben_submit_info.cost_total_price = p_.toFixed(2);
			},
			// 包装列表
			getPackingList() {
				packing({
					page: 1,
					limit: 100000
				}).then(res => {
					let list_ = res.data.data;

					list_.forEach((n, k) => {
						this.pack_list.push({
							label: n.name,
							value: n.id
						})
					})
				})
			},
			get_pak(e) {
				this.huowu_list[e.index].pack = e.label;
			},
			// 获取详情
			get_detail() {
				offerShow({
					id: this.id
				}).then(res => {
					res.data.rate = [];
					this.submit_info = res.data;
					let l_ = this.submit_info.offer_rate; // 汇率

					this.huowu_list = res.data.cargo;
					this.feiyong_list = res.data.cost;

					this.feiyong_list.forEach((n, k) => {
						n.is_save = true;
					})

					setTimeout(() => {
						this.hv = [];
						if (l_.length > 0) {
							l_.forEach((n, k) => {
								this.hv.push({
									name: n.rate_name,
									hl: n.input_id,
									id: n.rate_id
								})
							})
						}
					}, 300)

					this.get_all_price();
					this.get_detail_(this.submit_info.company_id, 5);
				})
			},
			// 成本编辑详情
			get_chengben_detail() {
				degressionInfoShow({
					id: this.id,
					order_id: this.id,
					type_id: 2
				}).then(res => {
					if (res.data.degression_info) {
						this.is_chengben = true;
						res.data.degression_info.id = this.id;
						this.chengben_submit_info = res.data.degression_info;
						this.chengben_feiyong_list = res.data.degression;

						this.chengben_feiyong_list.forEach((n, k) => {
							n.is_save = true;
						})

						let l_ = res.data.offer_degression_rate; // 汇率

						setTimeout(() => {
							this.chengben_hv = [];
							if (l_.length > 0) {
								l_.forEach((n, k) => {
									this.chengben_hv.push({
										name: n.rate_name,
										hl: n.input_id,
										id: n.rate_id
									})
								})
							}
						}, 300)

						this.get_all_price(1);
						this.get_detail_(this.chengben_submit_info.company_id, 6);
					}
				})
			},
			// 服务类型
			get_serve_list() {
				serviceType({
					page: 1,
					limit: 100000
				}).then(res => {
					let list_ = res.data.data;

					list_.forEach((n, k) => {
						this.serve_list.push({
							label: n.name,
							value: n.id
						})
					})
				})
			},
			get_serve_type(e) {
				this.submit_info.service_type_id = e.value;
				this.submit_info.service_type_id_name = e.label;

				this.getStartHarborList();
				this.getEndHarborList();
				this.submit_info.shipment_id = '';
				this.submit_info.shipment_id_name = '';
				this.submit_info.destination_id = '';
				this.submit_info.destination_id_name = '';
			},
			// 服务范围
			getServicesList() {
				services({
					page: 1,
					limit: 100000
				}).then(res => {
					this.services_scope_list = res.data.data;
				})
			},
			// 运输方式
			getTspModeList() {
				tspMode({
					page: 1,
					limit: 100000
				}).then(res => {
					this.transport_list = res.data.data;
				})
			},
			// 运输流向
			getTransportList() {
				transport({
					page: 1,
					limit: 100000
				}).then(res => {
					this.transportation_list = res.data.data;
				})
			},
			// 获取船司/航司
			get_getAllList() {
				getAllList({
					page: 1,
					limit: 100000
				}).then(res => {
					let list_ = res.data;

					list_.forEach((n, k) => {
						this.company_list.push({
							label: n.ship_name,
							value: n.id
						})
					})
				})
			},
			get_ship(e) {
				this.submit_info.shipping_company = e.value;
				this.submit_info.shipping_company_name = e.label;
			},
			// 路线
			get_luxian_list() {
				circuit({
					page: 1,
					limit: 100000
				}).then(res => {
					let list_ = res.data.data;

					list_.forEach((n, k) => {
						this.luxian_list.push({
							label: n.name,
							value: n.id
						})
					})
				})
			},
			get_luxian(e) {
				this.submit_info.circuit_id = e.value;
				this.submit_info.circuit_id_name = e.label;
			},
			// 获取起运港列表
			getStartHarborList() {
				startHarbor({
					page: 1,
					limit: 100000,
					service_type: this.submit_info.service_type_id
				}).then(res => {
					let list_ = res.data.data;
					this.start_harbor_list = [];

					list_.forEach((n, k) => {
						this.start_harbor_list.push({
							label: n.name + '/' + n.code,
							value: n.id
						})
					})
				})
			},
			get_harbor(e) {
				this.submit_info.shipment_id = e.value;
				this.submit_info.shipment_id_name = e.label;
			},
			// 获取目的港列表
			getEndHarborList() {
				endHarbor({
					page: 1,
					limit: 100000,
					service_type: this.submit_info.service_type_id
				}).then(res => {
					let list_ = res.data.data;
					this.end_harbor_list = [];

					list_.forEach((n, k) => {
						this.end_harbor_list.push({
							label: n.name + '/' + n.code,
							value: n.id
						})
					})
				})
			},
			get_end_harbor(e) {
				this.submit_info.destination_id = e.value;
				this.submit_info.destination_id_name = e.label;
			},
			// 获取hs编码列表
			getHsCodeList() {
				hsCode({
					page: 1,
					limit: 100000
				}).then(res => {
					let list_ = res.data.data;

					list_.forEach((n, k) => {
						this.hs_list.push({
							label: n.code,
							value: n.id
						})
					})
				})
			},
			get_hs(e) {
				this.huowu_list[e.index].hs_code = e.value;
				this.huowu_list[e.index].hs_code_name = e.label;
			},
			// 获取币制列表
			getCurrencyList() {
				currency({
					page: 1,
					limit: 100000
				}).then(res => {
					let list_ = res.data.data;

					list_.forEach((n, k) => {
						this.currency_list.push({
							label: n.name,
							value: n.id
						})
					})
				})
			},
			get_currency(e) {
				this.huowu_list[e.index].currency_system = e.value;
				this.huowu_list[e.index].currency_system_name = e.label;
			},
			// 费用名称
			getCostNameList() {
				costName({
					page: 1,
					limit: 100000
				}).then(res => {
					res.data.data.forEach((n, k) => {
						n.name = n.name + '/' + n.english_name
					})

					this.feiyong_name_list = res.data.data;
				})
			},
			// 新增货物信息
			get_add_huowu() {
				let check_info = true;

				this.huowu_list.forEach((n, k) => {
					for (let i in n) {
						if (!n[i] && i !== 'is_delete') {
							check_info = false;
						}
					}
				})

				// if (!check_info) return this.$message('请完善现有货物信息再添加下一条吧！')

				this.huowu_list.push({
					cases: '', // 件数
					pack: '', // 包装
					rough_weight: '', // 毛重
					weight: '', // 计重
					long: '', // 长
					tall: '', // 高
					broad: '', // 宽
					bulk: '', // 体积
					chinese_name: '', // 中文品名
					english_name: '', // 英文品名
					hs_code: '', // HS编码
					hs_code_name: '',
					low_value: '', // 货值
					currency_system: '', // 币制
					currency_system_name: '',
					flight_name: '', // 船名/航次
					shipment_date: '' // 起运日期
				})
			},
			// 删除货物信息
			get_del_huowu(item, index) {
				if (this.huowu_list.length === 1) return this.$message.error('至少保留一条数据');

				this.$confirm('确定删除吗？', '提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(() => {
					if (item.id) {
						offerCargoDel({
							id: item.id
						}).then(res => {
							this.huowu_list.splice(index, 1);
						})
					} else {
						this.huowu_list.splice(index, 1);
					}
				})
			},
			// 成本费用
			get_add_chengben_feiyong() {
				this.chengben_feiyong_list.push({
					is_save: false,
					name_id: '',
					name_id_name: '',
					number: '',
					money: '',
					currency_system: '',
					currency_system_name: '',
					remark: '',
					price: ''
				})
			},
			// 费用
			// 新增费用
			get_add_feiyong() {
				this.feiyong_list.push({
					is_save: false,
					name_id: '', // 费用名称
					name_id_name: '',
					number: '', // 数量
					money: '', // 单价
					currency_system: '', // 币制
					currency_system_name: '',
					remark: '', // 备注
					price: '' // 金额
				})
			},
			get_change(type, e, index) {
				// 费用名称
				if (type === 1) {
					this.feiyong_list[index].name_id = e.id;
					this.feiyong_list[index].name_id_name = e.name;
				}

				// 币制
				if (type === 2) {
					this.feiyong_list[index].currency_system = e.value;
					this.feiyong_list[index].currency_system_name = e.label;
				}

				// 成本费用名称
				if (type === 3) {
					this.chengben_feiyong_list[index].name_id = e.id;
					this.chengben_feiyong_list[index].name_id_name = e.name;
				}

				// 成本费用币制
				if (type === 4) {
					this.chengben_feiyong_list[index].currency_system = e.value;
					this.chengben_feiyong_list[index].currency_system_name = e.label;
				}
			},
			// 保存/编辑
			get_resource_btn(item, index) {
				for (let i in item) {
					if (!item[i] && i !== 'is_save' && i !== 'createtime_text' && i !== 'cost_name' && i !== 'createtime' && i !==
						'updatetime' && i !== 'is_delete') return this.$message('请将内容填写完整');
				}

				item.is_save = !item.is_save;

				if (item.is_save) {
					// 保存 计算金额
					if (index) {
						this.chengbne_feiyong_all = 0;
						this.get_all_price(index);
					} else {
						// this.feiyong_all = 0;
						this.get_all_price();
					}
				}

				this.$forceUpdate();
			},
			// 删除
			get_resource_del(index, item) {
				if (this.feiyong_list.length === 1) return this.$message.error('至少保留一条数据');

				this.$confirm('确定删除吗？', '提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(() => {
					if (item.id) {
						offerCostDel({
							id: item.id
						}).then(res => {
							this.feiyong_list.splice(index, 1);
							this.get_all_price();
						})
					} else {
						this.feiyong_list.splice(index, 1);
						this.get_all_price();
					}
				})
			},
			get_all_price(index) {
				if (this.feiyong_list.length !== 0) {
					if (index) {
						this.chengbne_feiyong_all = 0;

						this.chengben_feiyong_list.forEach((n, k) => {
							if (n.is_save) {
								this.chengbne_feiyong_all += parseFloat(n.price)
							}
						})
					} else {
						// this.feiyong_all = 0;

						this.feiyong_list.forEach((n, k) => {
							if (n.is_save) {
								// this.feiyong_all += parseFloat(n.price)
							}
						})
					}
				}
			},
			// 右侧
			// 客户名称
			getCompanyList() {
				companyList({
					page: 1,
					limit: 100000,
					partner_type: 2
				}).then(res => {
					let list_ = res.data.data;

					list_.forEach((n, k) => {
						this.kehu_list.push({
							label: n.company_name,
							value: n.id
						})
					})
				})

				companyList({
					page: 1,
					limit: 100000,
					partner_type: 1
				}).then(res => {
					let list_ = res.data.data;

					list_.forEach((n, k) => {
						this.gongyingshang_list.push({
							label: n.company_name,
							value: n.id
						})
					})
				})
			},
			// 根据企业id 获取联系人
			get_linkman_list(id) {
				getAllLinkman({
					company_id: id
				}).then(res => {
					let list_ = res.data;
					this.lxr_list = [];

					list_.forEach((n, k) => {
						this.lxr_list.push({
							label: n.username,
							value: n.id
						})
					})
				})
			},
			// 结算账期
			getPayDays(type) {
				payDays({
					page: 1,
					limit: 100000
				}).then(res => {
					let name_ = '';
					let list_ = res.data.data;
					let id_zq = '';

					if (type == 5) {
						id_zq = this.submit_info.payment_days
					}

					if (type == 6) {
						id_zq = this.chengben_submit_info.payment_days
					}

					list_.forEach((n, k) => {
						if (n.id == id_zq) {
							name_ = n.name
						}

						this.zhangqi_list.push({
							label: n.name,
							value: n.id
						})
					})

					if (type == 5) {
						this.submit_info.payment_days_name = name_;
					}

					if (type == 6) {
						this.chengben_submit_info.payment_days_name = name_;
					}
				})
			},
			// 结算方式
			getPayTypeList(ids, type) {
				payType({
					page: 1,
					limit: 100000
				}).then(res => {
					this.fangshi_list = [];
					let list_ = res.data.data;
					let ids_array = ids.split(',');

					list_.forEach((n, k) => {
						ids_array.forEach((n_, k_) => {
							if (n.id == n_) {
								n.is_show = true;
							}
						})
					})

					list_.forEach((n, k) => {
						if (n.is_show) {
							this.fangshi_list.push({
								label: n.name,
								value: n.id
							})
						}
					})
				})
			},
			// 根据客户名称查结算账期 结算方式
			// 详情数据
			get_detail_(id, type) {
				companyDetail({
					id: id
				}).then(res => {
					if (type == 5) {
						this.submit_info.payment_days = res.data.finance.payment_days;
					}

					if (type == 6) {
						this.chengben_submit_info.payment_days = res.data.finance.payment_days;
					}

					this.getPayDays(type);
					this.getPayTypeList(res.data.finance.pay_type, type);
				})
			},
			get_kehu_serch(e) {
				if (e.index === 1) {
					// 客户名称
					this.submit_info.company_id = e.value;
					this.submit_info.company_id_name = e.label;
					this.get_linkman_list(this.submit_info.company_id);
					this.get_detail_(this.submit_info.company_id, 5);
					this.submit_info.linkman_id = '';
					this.submit_info.linkman_id_name = '';
					this.submit_info.pay_type = '';
					this.submit_info.pay_type_name = '';
				}

				if (e.index === 2) {
					// 联系人
					this.submit_info.linkman_id = e.value;
					this.submit_info.linkman_id_name = e.label;
				}

				if (e.index === 3) {
					// 结算账期
					this.submit_info.payment_days = e.value;
					this.submit_info.payment_days_name = e.label;
				}

				if (e.index === 4) {
					this.submit_info.pay_type = e.value;
					this.submit_info.pay_type_name = e.label;
				}

				if (e.index === 5) {
					// 成本客户名称
					this.chengben_submit_info.company_id = e.value;
					this.chengben_submit_info.company_id_name = e.label;
					this.get_linkman_list(this.chengben_submit_info.company_id);
					this.get_detail_(this.chengben_submit_info.company_id, 6);
					this.chengben_submit_info.linkman_id = '';
					this.chengben_submit_info.linkman_id_name = '';
					this.chengben_submit_info.pay_type = '';
					this.chengben_submit_info.pay_type_name = '';
				}

				if (e.index === 6) {
					// 成本联系人
					this.chengben_submit_info.linkman_id = e.value;
					this.chengben_submit_info.linkman_id_name = e.label;
				}

				if (e.index === 7) {
					// 结算账期
					this.chengben_submit_info.payment_days = e.value;
					this.chengben_submit_info.payment_days_name = e.label;
				}

				if (e.index === 8) {
					this.chengben_submit_info.pay_type = e.value;
					this.chengben_submit_info.pay_type_name = e.label;
				}
			},
			// 编辑保存
			get_save_info(index) {
				// for (let i in this.submit_info) {
				// 	if (!this.submit_info[i] && i !== 'cargo' && i !== 'cost' && i !== 'order_no' && i !== 'is_delete' && i !==
				// 		'offer_confirm' && i !== 'turn_order') return this.$message(
				// 		'请将内容填写完整');
				// }

				if (this.huowu_list.length === 0) return this.$message('请填写货物信息');
				if (this.feiyong_list.length === 0) return this.$message('请填写费用信息');

				let check_info = true;

				this.huowu_list.forEach((n, k) => {
					for (let i in n) {
						if (!n[i] && i !== 'is_delete') {
							check_info = false;
						}
					}
				})

				// if (!check_info) return this.$message('请完善货物信息');

				let cost_list = [];

				this.feiyong_list.forEach((n, k) => {
					if (n.is_save) {
						cost_list.push(n)
					}
				})

				if (cost_list.length === 0) return this.$message('请完善费用信息');

				this.submit_info.cargo = this.huowu_list;
				this.submit_info.cost = this.feiyong_list;

				if (this.hv.length > 0) {

					this.submit_info.rate = [];

					this.hv.forEach((n, k) => {
						this.submit_info.rate.push({
							rate_id: n.id,
							rate_name: n.name,
							input_id: n.hl
						})
					})
				}

				if (index == 1) {
					// 编辑
					offerEdit(this.submit_info).then(res => {
						this.$message.success('编辑成功！');
						this.$publicFun.get_link('/', {});
					})
				}

				if (index == 2) {
					this.dialogVisible = true;
				}
			},
			// 生成模板
			get_muban() {
				this.get_save_info(2)
			},
			get_data_amend(e) {
				this.dialogVisible = false;

				offerShiftToTemplate({
					id: this.id,
					name: e.name
				}).then(res_ => {
					this.$message.success('转入成功！');
					this.$publicFun.get_link('/offer/form/list', {});
				})
			},
			getModalClose(e) {
				this.dialogVisible = e;
			},
			// 成本费用
			// 成本删除
			get_resource_chenben_del(index, item) {
				if (this.chengben_feiyong_list.length === 1) return this.$message.error('至少保留一条数据');

				this.$confirm('确定删除吗？', '提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(() => {
					if (item.id) {
						degressionInfoDel({
							id: item.id,
							type_id: 2
						}).then(res => {
							this.chengben_feiyong_list.splice(index, 1);
							this.get_all_price(1);
						})
					} else {
						this.chengben_feiyong_list.splice(index, 1);
						this.get_all_price(1);
					}
				})
			},
			// 重置
			get_reset() {
				if (this.is_chengben) {
					this.get_chengben_detail();
				}

				if (!this.is_chengben) {
					this.chengben_submit_info = {
						order_id: '',
						type_id: 2,
						company_id: '',
						company_id_name: '',
						linkman_id: '',
						linkman_id_name: '',
						mobile: '',
						payment_days: '',
						payment_days_name: '',
						pay_type: '',
						pay_type_name: '',
						info: []
					}
					this.chengben_feiyong_list = [{
						is_save: false,
						name_id: '',
						name_id_name: '',
						number: '',
						money: '',
						currency_system: '',
						currency_system_name: '',
						remark: '',
						price: ''
					}]
				}
			},
			// 生成应付结算单
			get_pay() {
				this.$confirm('确定将此报价单生成应付结算单吗？', '提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(() => {
					offerReceivable({
						order_id: this.id,
						type_id: 2
					}).then(res => {
						this.$message.success('成功！');
						this.$publicFun.get_link('/', {});
					})
				})
			},
			// 保存
			get_chengben_save_info() {
				this.chengben_submit_info.order_id = this.id;

				console.log(this.chengben_submit_info)

				// for (let i in this.chengben_submit_info) {
				// 	if (!this.chengben_submit_info[i] && i !== 'collected' && i !== 'email' && i !== 'is_delete' && i !== 'receivable' &&
				// 		i !== 'received' && i !== 'settlement' && i !== 'settlement_text' && i !== 'make_invoice' && i !==
				// 		'reconciliation_id') return this.$message(
				// 		'请将内容填写完整');
				// }

				if (this.chengben_feiyong_list.length === 0) return this.$message('请填写成本费用信息');

				let info_list = [];

				this.chengben_feiyong_list.forEach((n, k) => {
					if (n.is_save) {
						info_list.push(n)
					}
				})

				if (info_list.length === 0) return this.$message('请完善成本费用信息');

				this.chengben_submit_info.info = info_list;

				if (this.chengben_hv.length > 0) {

					this.chengben_submit_info.rate = [];

					this.chengben_hv.forEach((n, k) => {
						this.chengben_submit_info.rate.push({
							rate_id: n.id,
							rate_name: n.name,
							input_id: n.hl
						})
					})
				}

				if (this.is_chengben) {
					degressionInfoEdit(this.chengben_submit_info).then(res => {
						this.$message.success('编辑成功！');
						this.$publicFun.get_link('/', {});
					})
				} else {
					// 添加
					degressionInfoAdd(this.chengben_submit_info).then(res => {
						this.$message.success('添加成功！');
						this.$publicFun.get_link('/', {});
					})
				}
			}
		}
	}
</script>

<style lang="less" scoped>
	.info_wrapper {
		padding: 12px 9px;
	}

	.hj_btn {
		&:hover {
			font-weight: bold;
		}
	}

	.in_class {

		/deep/ input::-webkit-input-placeholder,
		/deep/ textarea::-webkit-input-placeholder {
			color: #5B8EF0;
		}

		/deep/ input::-moz-placeholder,
		/deep/ textarea::-webkit-input-placeholder {
			color: #5B8EF0;
		}

		/deep/ input:-moz-placeholder,
		/deep/ textarea::-webkit-input-placeholder {
			color: #5B8EF0;
		}

		/deep/ input:-ms-input-placeholder,
		/deep/ textarea::-webkit-input-placeholder {
			color: #5B8EF0;
		}
	}
</style>
