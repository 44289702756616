<template>
	<div class="select_wrapper01">
		<el-input :placeholder="placeText" v-model="value_content" @input="get_input" clearable></el-input>
	</div>
</template>

<script>
	export default {
		props: {
			placeText: {
				type: String
			},
			type_num: {
				type: Number
			},
			value_content: {
				type: String
			}
		},
		data() {
			return {
				
			}
		},
		methods: {
			get_input(e) {
				this.$emit('get_input_value', {
					value: e,
					type_num: this.type_num
				});
			}
		}
	}
</script>

<style lang="less" scoped>
	.select_wrapper01 {
		position: relative;

		/deep/ .el-select {
			width: 100%;
		}

		/deep/ .el-input__inner {
			border-radius: 10px;
			padding-left: 17px;
			font-size: 12px;
		}

		.icon {
			top: 0;
			right: 0;
			width: 28px;
			bottom: 0;
			position: absolute;
			background-color: #5B8EF0;
			border-radius: 0 10px 10px 0;

			img {
				width: 10px;
			}
		}
	}
</style>
