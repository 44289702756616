<template>
	<div>
		<div class="a4_list">
			<div class="a4_btn_wrapper">
				<div class="btn01 a4_btn" @click="getPdf('应付结算单' + info.handle_no)">导出</div>
				<div class="a4_btn flex03 right" v-if="ids.length > 1">
					<p class="btn01" @click="get_btn('up')">上一个</p>
					<p class="btn01" style="margin-left: 20px;" @click="get_btn('down')">下一个</p>
				</div>
			</div>
			<div class="a4" :id="'应付结算单' + info.handle_no">
				<p class="a4_title">应 付 结 算 单</p>
				<div class="a4_top">
					<img :src="$publicFun.get_LostName().logo1" />
					<p>应付结算单编号：{{info.handle_no}}</p>
				</div>
				<p class="a4_top_line"></p>
				<p class="a4_top_line"></p>
				<div class="a4_info_block" style="padding-bottom: 0;">
					<p class="bold_">致 {{info.receivable_info.company_id_name}}</p>
				</div>
				<div class="a4_info">
					<div class="a4_info_block" style="padding-top: 0;">
						<div class="a4_info_block_list">
							<p>联系人：</p>
							<p>{{info.receivable_info.linkman_id_name}}</p>
						</div>
						<div class="a4_info_block_list">
							<p>电话：</p>
							<p>{{info.mobile}}</p>
						</div>
						<div class="a4_info_block_list">
							<p>邮箱：</p>
							<p>{{info.email}}</p>
						</div>
					</div>
					<div class="a4_info_block" style="padding-top: 0;">
						<div class="a4_info_block_list">
							<p>日期：</p>
							<p>{{now_date}}</p>
						</div>
						<div class="a4_info_block_list">
							<p>订单编号：</p>
							<p>{{info.order_no}}</p>
						</div>
						<div class="a4_info_block_list">
							<p>服务类型：</p>
							<p>{{info.service_type_id_name}}</p>
						</div>
					</div>
				</div>
				<div class="a4_b_line">
					<div class="a4_info_block" style="padding-bottom: 0;">
						<div class="a4_info_block_list">
							<p>运单号：</p>
							<p>{{info.waybill_no}}</p>
						</div>
					</div>
					<div class="a4_info" style="border-bottom: none;">
						<div class="a4_info_block" style="padding-top: 0;">
							<div class="a4_info_block_list">
								<p>船名/航次：</p>
								<p>{{info.flight_name}}</p>
							</div>
							<div class="a4_info_block_list">
								<p>毛重：</p>
								<p>{{info.rough_weight}} KG</p>
							</div>
							<div class="a4_info_block_list">
								<p>起运港：</p>
								<p>{{info.shipment_id_name}}</p>
							</div>
						</div>
						<div class="a4_info_block" style="padding-top: 0;">
							<div class="a4_info_block_list">
								<p>日期：</p>
								<p>{{info.date_}}</p>
							</div>
							<div class="a4_info_block_list">
								<p>件数：</p>
								<p>{{info.cases}}</p>
							</div>
							<div class="a4_info_block_list">
								<p>计重：</p>
								<p>{{info.weight}} KG</p>
							</div>
							<div class="a4_info_block_list">
								<p>目的港：</p>
								<p>{{info.destination_id_name}}</p>
							</div>
						</div>
					</div>
					<p class="a4_des01"><span>应付费用明细如下：</span></p>
					<div>
						<div class="a4_table_list">
							<div class="a4_table_block title_">
								<p>费用名称</p>
								<p>数量</p>
								<p>单价</p>
								<p>金额</p>
								<p>币种</p>
								<p>备注</p>
							</div>
							<div class="a4_table_content">
								<div class="a4_table_block" v-for="(item, index) in info.receivable_info.receivable_data" :key="index">
									<p>{{item.name_id_name}}</p>
									<p>{{item.number}}</p>
									<p>{{item.money}}</p>
									<p>{{item.price}}</p>
									<p>{{item.currency_system_name}}</p>
									<p>{{item.remark}}</p>
								</div>
							</div>
						</div>
						<div class="a4_table_des">
							<div class="a4_table_des_list">
								<p class="a4_table_des_label">多币种汇总合计：</p>
								<p class="a4_table_des_content">
									<span v-for="(item, index) in currency_list" :key="index">{{item.name}} {{item.all_price.toFixed(2)}}</span>
								</p>
							</div>
							<div class="a4_table_des_list">
								<p class="a4_table_des_label bold_">总计金额：</p>
								<p class="a4_table_des_content">
									<span class="bold_">RMB {{info.receivable_info.total_price}}</span>
								</p>
							</div>
						</div>
					</div>
				</div>
				<!-- 报价说明 -->
				<div class="a4_explain_wrapper">
					<div style="margin: 10px 0 20px 0;">
						<p class="a4_explain_title">我司开票信息如下：</p>
						<div class="a4_explain_table">
							<div class="a4_explain_table_list">
								<p>开票抬头</p>
								<p>:</p>
								<p>{{$publicFun.get_LostName().companyInfo.name}}</p>
							</div>
							<div class="a4_explain_table_list">
								<p>公司地址</p>
								<p>:</p>
								<p>{{$publicFun.get_LostName().companyInfo.address}}</p>
							</div>
							<div class="a4_explain_table_list">
								<p>电话</p>
								<p>:</p>
								<p>{{$publicFun.get_LostName().companyInfo.phone}}</p>
							</div>
							<div class="a4_explain_table_list">
								<p>税务登记号</p>
								<p>:</p>
								<p>{{$publicFun.get_LostName().companyInfo.num}}</p>
							</div>
							<div class="a4_explain_table_list">
								<p>开户银行名称</p>
								<p>:</p>
								<p>{{$publicFun.get_LostName().companyInfo.bankAllName}}</p>
							</div>
							<div class="a4_explain_table_list">
								<p>开户银行账户</p>
								<p>:</p>
								<p>{{$publicFun.get_LostName().companyInfo.bankAllAaccount}}</p>
							</div>
						</div>
					</div>
					<div style="margin-bottom: 20px;">
						<p class="a4_explain_title">收款信息如下：</p>
						<div class="a4_explain_table">
							<div class="a4_explain_table_list">
								<p>开户名</p>
								<p>:</p>
								<p>{{company_info.company_name}}</p>
							</div>
							<div class="a4_explain_table_list">
								<p>开户银行名称</p>
								<p>:</p>
								<p>{{company_info.finance.bank_name}}</p>
							</div>
							<div class="a4_explain_table_list">
								<p>开户银行账户</p>
								<p>:</p>
								<p>{{company_info.finance.bank_number}}</p>
							</div>
						</div>
					</div>
					<!-- 章 -->
					<div class="chapter_icon" style="top: 70px;bottom: 0;">
						<img :src="$publicFun.get_LostName().zhangImg" />
					</div>
					<!-- <div class="a4_explain_des_text">
						<p>备注：</p>
					</div> -->
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import {
		DegressionShow,
		companyDetail
	} from '@/api/index';

	export default {
		data() {
			return {
				info: {
					receivable_info: {}
				},
				company_info: {
					finance: {}
				},
				index: 0,
				all_price: 0,
				ids: [], // id合集
				oo_ids: [],
				company_id: '', // 公司id
				now_id: '', // 当前的id
				now_ids: '',
				currency_list: [], // 多币种
				now_date: '',
			}
		},
		created() {
			this.now_date = this.$publicFun.addDate();

			this.ids = this.$route.query.id.split(',');
			this.oo_ids = this.$route.query.ids.split(',');
			this.get_int();
		},
		methods: {
			// 详情数据
			get_detail() {
				DegressionShow({
					order_id: this.now_id,
					id: this.now_ids
				}).then(res => {
					this.info = res.data;
					this.company_id = res.data.receivable_info.company_id;

					this.get_compeny_info();

					// 费用名称
					let cost_list = res.data.receivable_info.receivable_data;

					if (cost_list.length > 0) {
						let tempArr = [];

						for (let i = 0; i < cost_list.length; i++) {
							this.all_price += parseFloat(cost_list[i].price);

							if (tempArr.indexOf(cost_list[i].currency_system_name) === -1) {
								this.currency_list.push({
									name: cost_list[i].currency_system_name,
									origin: [cost_list[i]]
								});
								tempArr.push(cost_list[i].currency_system_name);
							} else {
								for (let j = 0; j < this.currency_list.length; j++) {
									if (this.currency_list[j].name == cost_list[i].currency_system_name) {
										this.currency_list[j].origin.push(cost_list[i]);
										break;
									}
								}
							}
						}

						this.currency_list.forEach((n, k) => {
							n.all_price = 0;

							n.origin.forEach((n_, k_) => {
								n.all_price += parseFloat(n_.price);
							})
						})
					}

					// 货物信息
					let cargo_ = res.data.cargo;
					let flight_name = ''; // 船名/航次
					let rough_weight = 0; // 毛重
					let cases = 0; // 件数
					let weight = 0; // 计重

					if (cargo_.length > 0) {
						cargo_.forEach((n, k) => {
							flight_name += n.flight_name + '/';
							rough_weight += parseFloat(n.rough_weight);
							cases += parseFloat(n.cases);
							weight += parseFloat(n.weight);
						})

						this.info.date_ = cargo_[0].shipment_date;
					}

					this.info.flight_name = flight_name.substr(0, flight_name.length - 1);
					this.info.rough_weight = rough_weight.toFixed(2);
					this.info.cases = cases;
					this.info.weight = weight.toFixed(2);
				})
			},
			// 获取公司信息
			get_compeny_info() {
				companyDetail({
					id: this.company_id
				}).then(res => {
					this.company_info = res.data;
				})
			},
			//
			get_int() {
				this.all_price = 0;
				this.currency_list = [];
				this.now_id = this.ids[this.index];
				this.now_ids = this.oo_ids[this.index];
				this.get_detail();
			},
			get_btn(e) {
				if (e === 'up') {
					// 上
					if (this.index === 0) return this.$message('没有上一个了！');

					this.index--;
					this.get_int();
				}

				if (e === 'down') {
					// 下
					if (this.index === (this.ids.length - 1)) return this.$message('没有下一个了！');

					this.index++;
					this.get_int();
				}
			}
		}
	}
</script>

<style>
</style>
