<template>
	<div class="box01">
		<v-crumbs :crumbsType='27'></v-crumbs>
		<div>
			<div class="form_wrapper">
				<div class="form_wrapper_left">
					<div class="form_wrapper_block">
						<div class="form_wrapper_title">
							<p></p>
							<p>关于我们</p>
						</div>
						<div class="info_wrapper">
							<div class="info_wrapper_block">
								<div class="form_input_wrapper" style="display: block;">
									<div class="form_input" style="background-color: #fff;width: 100%;border-radius: 10px;overflow: hidden;">
										<quill-editor v-model="info.content" ref="myQuillEditor" :options="editorOption"></quill-editor>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div class="form_wrapper_block">
						<div class="form_wrapper_title">
							<p></p>
							<p>合作伙伴</p>
						</div>
						<div class="info_wrapper">
							<div class="info_wrapper_block">
								<div class="form_input_wrapper" style="align-items: flex-start;">
									<p class="form_input_label" style="line-height: 90px;">上传图片:</p>
									<div class="form_input flex01" style="flex-wrap: wrap;">
										<div style="margin: 15px 15px 15px 0;position: relative;" v-for="(item, index) in img_array" :key="index">
											<el-image style="width: 60px; height: 60px;cursor: pointer;" :src="item.url_text" fit="cover" @click="get_img_big(item.url_text)"></el-image>
											<i class="close_icon" @click="get_img_close(index)"></i>
										</div>
										<el-upload :action="up_url" :show-file-list="false" multiple :on-success="handleAvatarSuccess">
											<i class="add_img"></i>
										</el-upload>
										<el-dialog :visible.sync="dialogVisible">
											<img width="100%" :src="dialogImageUrl" alt="">
										</el-dialog>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div class="form_wrapper_block">
						<div class="form_wrapper_title">
							<p></p>
							<p>联系方式</p>
						</div>
						<div class="info_wrapper" style="padding-right: 400px;">
							<div class="info_wrapper_block">
								<div class="form_input_wrapper">
									<p class="form_input_label">电话:</p>
									<div class="form_input">
										<el-input v-model="info.mobile" placeholder="请填写电话"></el-input>
									</div>
								</div>
							</div>
							<div class="info_wrapper_block">
								<div class="form_input_wrapper">
									<p class="form_input_label">邮箱:</p>
									<div class="form_input">
										<el-input v-model="info.email" placeholder="请填写邮箱"></el-input>
									</div>
								</div>
							</div>
							<div class="info_wrapper_block">
								<div class="form_input_wrapper">
									<p class="form_input_label">地址:</p>
									<div class="form_input">
										<el-input v-model="info.address" placeholder="请填写地址"></el-input>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div class="form_wrapper_block">
						<div class="form_wrapper_title">
							<p></p>
							<p>首页弹窗图片</p>
						</div>
						<div class="info_wrapper" style="padding-right: 400px;">
							<div class="form_input_wrapper" style="align-items: flex-start;">
								<!-- <p class="form_input_label" style="line-height: 90px;"></p> -->
								<div class="form_input flex01" style="flex-wrap: wrap;">
									<div style="margin: 15px 15px 15px 0;position: relative;" v-if="info.show_img">
										<el-image style="width: 60px; height: 60px;cursor: pointer;" :src="show_img_all" fit="cover" @click="dialogImageUrl = show_img_all;dialogVisible = true;"></el-image>
										<i class="close_icon" @click="info.show_img = '';show_img_all = '';"></i>
									</div>
									<el-upload :action="up_url" :show-file-list="false" multiple :on-success="handleAvatarSuccess1" v-else>
										<i class="add_img"></i>
									</el-upload>
								</div>
							</div>
							<p class="font_12 c_6d9fff">289*321比例</p>
						</div>
					</div>
					<div class="form_wrapper_block">
						<div class="form_wrapper_btn_wrapper">
							<div class="btn01" @click="get_save_info">保存</div>
						</div>
					</div>
				</div>
				<div class="form_wrapper_right form_wrapper_block" style="height: 0;"></div>
			</div>
		</div>
	</div>
</template>

<script>
	import {
		quillEditor
	} from "vue-quill-editor"; // 调用编辑器

	import 'quill/dist/quill.core.css';
	import 'quill/dist/quill.snow.css';
	import 'quill/dist/quill.bubble.css';
	import crumbs from '../../components/crumbs/index.vue'; // 面包屑

	import {
		aboutShow,
		aboutAdd
	} from '@/api/index';

	export default {
		components: {
			'v-crumbs': crumbs,
			quillEditor
		},
		data() {
			return {
				info: {},
				up_url: '',
				show_img_all: '',
				img_array: [],
				editorOption: {},
				dialogImageUrl: '',
				dialogVisible: false,
			}
		},
		created() {
			this.get_detail();
			this.up_url = this.$publicFun.get_up_img();
		},
		methods: {
			// 获取详情
			get_detail() {
				aboutShow().then(res => {
					this.info = res.data;

					let img_ = res.data.partner.split(',');

					res.data.partner_text.forEach((n, k) => {
						this.img_array.push({
							url_text: n,
							url: img_[k]
						})
					})

					this.show_img_all = this.$publicFun.get_host() + res.data.show_img;
				})
			},
			// 图片
			handleAvatarSuccess(e) {
				this.img_array.push({
					url: e.data,
					url_text: this.$publicFun.get_host() + e.data
				})
			},
			get_img_close(index) {
				this.img_array.splice(index, 1);
			},
			get_img_big(url) {
				this.dialogImageUrl = url;
				this.dialogVisible = true;
			},
			handleAvatarSuccess1(e) {
				this.info.show_img = e.data;
				this.show_img_all = this.$publicFun.get_host() + e.data;
			},
			// 保存
			get_save_info() {
				let img_partner = []

				if (!this.info.content) return this.$message('请输入内容');
				if (this.img_array.length === 0) return this.$message('请上传合作伙伴图片');
				if (!this.info.mobile) return this.$message('请输入联系方式');
				if (!this.info.email) return this.$message('请输入邮箱');
				if (!this.info.address) return this.$message('请输入地址');
				if (!this.info.show_img) return this.$message('请上传首页弹窗图片');

				this.img_array.forEach((n, k) => {
					img_partner.push(n.url)
				})

				this.info.partner = img_partner.join(',');

				aboutAdd({
					content: this.info.content,
					partner: this.info.partner,
					mobile: this.info.mobile,
					email: this.info.email,
					address: this.info.address,
					id: this.info.id ? this.info.id : '',
					show_img: this.info.show_img
				}).then(res => {
					this.$message.success('提交成功');
					this.img_array = [];
					this.get_detail();
				})
			}
		},
		computed: {
			editor() {
				return this.$refs.myQuillEditor.quill;
			},
		}
	}
</script>

<style lang="less" scoped>
	.info_wrapper {
		padding: 12px 9px;

		.info_wrapper_block {
			display: block;
			padding-right: 244px;
		}

		.form_input {
			flex: 1;
		}
	}

	.add_img {
		width: 60px;
		height: 60px;
		display: block;
		background: url(../../assets/wx/06@2x.png) no-repeat center;
		background-size: 100% 100%;
	}

	/deep/ .el-upload--picture-card {
		background: transparent;
		border: none;
		width: 60px;
		height: 60px;
	}

	/deep/ .el-upload-list--picture-card .el-upload-list__item {
		border: none;
		width: 60px;
		height: 60px;
		border-radius: 0;
		margin-bottom: 0;
		vertical-align: top;
	}

	/deep/ .ql-container.ql-snow {
		border: none;
	}

	.quill-editor {
		min-height: 500px;
	}

	/deep/ input::-webkit-input-placeholder,
	/deep/ textarea::-webkit-input-placeholder {
		color: #5B8EF0;
	}

	/deep/ input::-moz-placeholder,
	/deep/ textarea::-webkit-input-placeholder {
		color: #5B8EF0;
	}

	/deep/ input:-moz-placeholder,
	/deep/ textarea::-webkit-input-placeholder {
		color: #5B8EF0;
	}

	/deep/ input:-ms-input-placeholder,
	/deep/ textarea::-webkit-input-placeholder {
		color: #5B8EF0;
	}

	.close_icon {
		top: -10px;
		right: -10px;
		width: 20px;
		height: 20px;
		display: block;
		position: absolute;
		cursor: pointer;
		background: url(../../assets/wx/07.png) no-repeat center;
		background-size: 100% 100%;
	}
</style>
