<template>
	<div class="select_wrapper01" :style="'width: ' + width + 'px;'">
		<el-select v-model="value" :placeholder="p_text" @change="$emit('serch', value)">
			<el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value"></el-option>
		</el-select>
		<div class="icon flex04">
			<img src="../../assets/01.png" />
		</div>
	</div>
</template>

<script>
	export default {
		props: {
			width: {
				type: Number
			},
			p_text: {
				type: String
			},
			option: {
				type: Array
			},
			v_text: {
				type: String
			}
		},
		data() {
			return {
				value: '',
				options: this.option || []
			}
		},
		created() {
			this.value = this.v_text;
		}
	}
</script>

<style lang="less" scoped>
	.select_wrapper01 {
		position: relative;

		/deep/ .el-input__inner {
			border-radius: 5px;
			padding-left: 17px;
			border: none;
		}

		.icon {
			top: 0;
			right: 0;
			width: 28px;
			bottom: 0;
			position: absolute;
			background-color: #5B8EF0;
			border-radius: 0 5px 5px 0;

			img {
				width: 10px;
			}
		}
	}
</style>
