<template>
	<div id="app">
		<div v-if="is_head_show">
			<div class="head_box flex02">
				<div class="head_left flex01">
					<img :src="$publicFun.get_LostName().logo" />
					<p>作业系统</p>
				</div>
				<div class="head_right c_fff flex01 font_14">
					<div class="head_left" style="margin-right: 50px;font-size: 28px;">
						<p>{{ $publicFun.get_LostName().nameText }}</p>
					</div>
					<el-image style="width: 40px; height: 40px" :src="require('./assets/login/04@2x.png')"
						fit="cover">
					</el-image>
					<p style="margin: 0 23px 0 15px;">{{ nickname }}</p>
					<p>欢迎您！</p>
					<p class="line_fff_1" style="margin: 0 17px 0 14px;"></p>
					<p class="pointer_ exit" @click="get_exit">退出登录</p>
				</div>
			</div>
			<div class="content flex03">
				<div class="nav_box">
					<div class="nav_box_content">
						<el-menu :default-active="default_active_num" unique-opened class="el-menu-vertical-demo"
							background-color="#2E3D4E" text-color="#fff" active-text-color="#6D9FFF"
							@select="handleSelect">
							<el-submenu :index="item.title" v-for="(item, index) in default_list" :key="index">
								<template slot="title">
									<i class="el-icon-01 el-icon" v-if="item.title == 1"></i>
									<i class="el-icon-02 el-icon" v-if="item.title == 2"></i>
									<i class="el-icon-03 el-icon" v-if="item.title == 3"></i>
									<i class="el-icon-04 el-icon" v-if="item.title == 4"></i>
									<i class="el-icon-05 el-icon" v-if="item.title == 5"></i>
									<i class="el-icon-06 el-icon" v-if="item.title == 6"></i>
									<i class="el-icon-07 el-icon" v-if="item.title == 7"></i>
									<span class="font_14">{{ item.name }}</span>
								</template>
								<el-menu-item-group>
									<el-menu-item :index="item_.title"
										v-for="(item_, index_) in item.childlist">
										{{ item_.name }}</el-menu-item>
								</el-menu-item-group>
							</el-submenu>
						</el-menu>
						<!-- <el-menu :default-active="default_active_num" unique-opened class="el-menu-vertical-demo" background-color="#2E3D4E"
						 text-color="#fff" active-text-color="#6D9FFF" @select="handleSelect">
							<el-submenu index="1">
								<template slot="title">
									<i class="el-icon-01 el-icon"></i>
									<span class="font_14">报价管理</span>
								</template>
								<el-menu-item-group>
									<el-menu-item index="1-1">报价查询</el-menu-item>
									<el-menu-item index="1-2">报价模版</el-menu-item>
								</el-menu-item-group>
							</el-submenu>
							<el-submenu index="2">
								<template slot="title">
									<i class="el-icon-02 el-icon"></i>
									<span class="font_14">订单管理</span>
								</template>
								<el-menu-item-group>
									<el-menu-item index="2-1">订单查询</el-menu-item>
									<el-menu-item index="2-2">订舱单查询</el-menu-item>
								</el-menu-item-group>
							</el-submenu>
							<el-submenu index="3">
								<template slot="title">
									<i class="el-icon-03 el-icon"></i>
									<span class="font_14">结算管理</span>
								</template>
								<el-menu-item-group>
									<el-menu-item index="3-1">应收结算列表</el-menu-item>
									<el-menu-item index="3-2">应付结算列表</el-menu-item>
									<el-menu-item index="3-3">应收完成列表</el-menu-item>
									<el-menu-item index="3-4">应付完成列表</el-menu-item>
									<el-menu-item index="3-5">应付完成列表</el-menu-item>
								</el-menu-item-group>
							</el-submenu>
							<el-submenu index="4">
								<template slot="title">
									<i class="el-icon-04 el-icon"></i>
									<span class="font_14">报表管理</span>
								</template>
								<el-menu-item-group>
									<el-menu-item index="4-1">业务数据</el-menu-item>
									<el-menu-item index="4-2">财务数据</el-menu-item>
								</el-menu-item-group>
							</el-submenu>
							<el-submenu index="5">
								<template slot="title">
									<i class="el-icon-05 el-icon"></i>
									<span class="font_14">基础信息</span>
								</template>
								<el-menu-item-group>
									<el-menu-item index="5-1">企业管理</el-menu-item>
									<el-menu-item index="5-2">参数设置</el-menu-item>
								</el-menu-item-group>
							</el-submenu>
							<el-submenu index="6">
								<template slot="title">
									<i class="el-icon-06 el-icon"></i>
									<span class="font_14">小程序管理</span>
								</template>
								<el-menu-item-group>
									<el-menu-item index="6-1">参数配置</el-menu-item>
									<el-menu-item index="6-2">立即咨询列表</el-menu-item>
									<el-menu-item index="6-3">货物询价列表</el-menu-item>
									<el-menu-item index="6-4">关于我们</el-menu-item>
									<el-menu-item index="6-5">用户列表</el-menu-item>
								</el-menu-item-group>
							</el-submenu>
							<el-submenu index="7">
								<template slot="title">
									<i class="el-icon-07 el-icon"></i>
									<span class="font_14">权限管理</span>
								</template>
								<el-menu-item-group>
									<el-menu-item index="7-1">管理员列表</el-menu-item>
									<el-menu-item index="7-2">角色组</el-menu-item>
								</el-menu-item-group>
							</el-submenu>
						</el-menu> -->
					</div>
				</div>
				<div class="main_box">
					<keep-alive>
						<router-view v-if="$route.meta.keepAlive" />
					</keep-alive>
					<router-view v-if="!$route.meta.keepAlive" />
				</div>
			</div>
		</div>
		<div v-else>
			<router-view @loginSuccess="handlerLoginSuccess" />
		</div>
	</div>
</template>

<script>
import {
	userLogin
} from '@/api/index';

export default {
	data() {
		return {
			nickname: '',
			default_list: [],
			default_active_num: '1-1',
			is_head_show: false, // 除登录页外，其他页面都是展示顶部和左侧
		}
	},
	watch: {
		'$route'(to, from) { // 监听路由是否变化	
			console.log('监听路由是否变化', to, from)
			if (to.name == 'Login') this.is_head_show = false;
			if (to.name != 'Login') {
				this.is_head_show = true;
				this.default_list = JSON.parse(localStorage.getItem('groupList'))
				console.log('this.default_list====>', this.default_list)
				this.route_name(this.$route.path);

				// if (to.name == 'OfferList') {
				// 	this.default_active_num = localStorage.getItem('default_active_num');
				// } else {

				// }
			}
		}
	},
	created() {
		console.log('app.vue-created');
		this.get_left_tab();
	},
	mounted() {
		console.log('app.vue-mounted');
	},
	methods: {
		handlerLoginSuccess() {
			console.log('接收登录信号')
			this.getUserInfo();
		},
		// 导航
		get_left_tab() {
			if (this.$route.name == 'Login') this.is_head_show = false;
			if (this.$route.name != 'Login') this.is_head_show = true;

			if (localStorage.getItem('default_active_num')) {
				this.default_active_num = localStorage.getItem('default_active_num');
			}

			if (localStorage.getItem('groupList')) {
				this.default_list = JSON.parse(localStorage.getItem('groupList'))
			}

			if (this.$route.name == 'OfferList') this.default_active_num = '1-1';

			this.getUserInfo();

			console.log(this.default_active_num)
		},
		// 获取用户信息
		getUserInfo() {
			userLogin().then(res => {
				this.nickname = res.data.nickname;
			})
		},
		// 退出登录
		get_exit() {
			this.$confirm('此操作将退出当前登录, 是否继续?', '提示', {
				confirmButtonText: '确定',
				cancelButtonText: '取消',
				type: 'warning'
			}).then(() => {
				this.$message({
					type: 'success',
					message: '退出成功!'
				});
				localStorage.removeItem('token')
				localStorage.removeItem('default_active_num')
				localStorage.removeItem('groupList')
				this.$router.replace('/login')
			}).catch(() => {
				// 取消
				this.$message('已取消退出')
			});
		},
		handleSelect(e) {
			let router_path = ''
			this.default_active_num = e

			localStorage.setItem('default_active_num', e)

			if (e == '1-1') { // 
				router_path = '/'
			}

			if (e == '1-2') { //
				router_path = '/offer/form/list'
			}

			if (e == '2-1') {
				router_path = '/order/list'
			}

			if (e == '2-2') {
				router_path = '/order/module/list'
			}

			if (e == '3-1') {
				router_path = '/settle/list'
			}

			if (e == '3-2') {
				router_path = '/settle/pay/list'
			}

			if (e == '3-3') {
				router_path = '/settle/finish/list'
			}

			if (e == '3-4') {
				router_path = '/settle/pay_finish/list'
			}
			if (e == '3-5') {
				router_path = '/settle/collect/list'
			}
			if (e == '4-1') {
				router_path = '/tubiao/business/list'
			}

			if (e == '4-2') {
				router_path = '/tubiao/finance/list'
			}

			if (e == '5-1') {
				router_path = '/info/enterprise/list'
			}

			if (e == '5-2') {
				router_path = '/info/parameter/set01'
			}
			if (e == '5-3') {
				router_path = '/info/VoucherType'
			}

			if (e == '6-1') {
				router_path = '/weixin/parament/banner'
			}

			if (e == '6-2') {
				router_path = '/weixin/parament/consult/list'
			}

			if (e == '6-3') {
				router_path = '/weixin/parament/goods/list'
			}

			if (e == '6-4') {
				router_path = '/weixin/parament/about'
			}

			if (e == '6-5') {
				router_path = '/weixin/parament/user'
			}

			if (e == '7-1') {
				router_path = '/authority/administrator'
			}

			if (e == '7-2') {
				router_path = '/authority/role'
			}

			if (this.$route.path === router_path) {
				this.$router.go(0)
				return
			}

			this.$publicFun.get_link(router_path, {})
		},
		route_name(e) {
			let path_name = '';

			if (e == '/' || e == '/offer/edit' || e == '/offer/pdf' || e == '/offer/add') { //
				path_name = '1-1'
			}

			if (e == '/offer/form/list' || e == '/offer/form/edit') { //
				path_name = '1-2'
			}

			if (e == '/order/list' || e == '/order/edit' || e == '/order/add') {
				path_name = '2-1'
			}

			if (e == '/order/module/list' || e == '/order/module/add') {
				path_name = '2-2'
			}

			if (e == '/settle/list' || e == '/settle/collect/pdf' || e == '/settle/pdf' || e == '/settle/edit') {
				path_name = '3-1'
			}

			if (e == '/settle/pay/list' || e == '/settle/pay/pdf' || e == '/settle/collect/pdffz' || e == '/settle/pay/edit') {
				path_name = '3-2'
			}

			if (e == '/settle/finish/list' || e == '/settle/edit1') {
				path_name = '3-3'
			}

			if (e == '/settle/pay_finish/list' || e == '/settle/pay/edit1') {
				path_name = '3-4'
			}


			if (e == '/settle/collect/list' || e == '/settle/collect/show') {
				path_name = '3-5'
			}


			if (e == '/tubiao/business/list') {
				path_name = '4-1'
			}

			if (e == '/tubiao/finance/list') {
				path_name = '4-2'
			}

			if (e == '/info/enterprise/list' || e == '/info/enterprise/add') {
				path_name = '5-1'
			}

			for (let i = 0; i < 17; i++) {
				if (i < 10) {
					i = '0' + i
				}

				if (e == '/info/parameter/set' + i) {
					path_name = '5-2'
				}
			}

			if (e == '/weixin/parament/banner') {
				path_name = '6-1'
			}

			if (e == '/weixin/parament/consult/list') {
				path_name = '6-2'
			}

			if (e == '/weixin/parament/goods/list') {
				path_name = '6-3'
			}

			if (e == '/weixin/parament/about') {
				path_name = '6-4'
			}

			if (e == '/weixin/parament/user') {
				path_name = '6-5'
			}

			if (e == '/authority/administrator') {
				path_name = '7-1'
			}

			if (e == '/authority/role') {
				path_name = '7-2'
			}

			this.default_active_num = path_name;
		}
	}
}
</script>

<style lang="less">
#app {
	font-family: Avenir, Helvetica, Arial, sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	text-align: left;
	color: #333;
}

::-webkit-scrollbar {
	width: 8px;
	height: 8px;
	border-radius: 2px;
}

::-webkit-scrollbar-thumb {
	background: rgba(109, 159, 255, .2);
	border-radius: 4px;
}

.head_right img {
	border-radius: 50%;
}

.head_box {
	top: 0;
	left: 0;
	right: 0;
	width: 100%;
	height: 102px;
	position: fixed;
	z-index: 999;
	background: url(assets/head/01.png) no-repeat center;
	background-size: 100% 100%;

	.head_left {
		color: #FFFFFF;
		font-size: 24px;
		line-height: 30px;

		img {
			width: 100px;
			margin: 2px 25px 0 34px;
		}
	}

	.exit {
		margin-right: 45px;

		&:hover {
			color: rgba(255, 255, 255, 0.7);
		}
	}
}

.content {
	margin-top: 100px;
	min-height: calc(100vh - 100px);

	.nav_box {
		width: 170px;
		background-color: #2E3D4E;

		.nav_box_content {
			top: 100px;
			left: 0;
			width: 170px;
			position: fixed;
		}

		// element
		.el-menu {
			border-right: 0;
		}

		.el-submenu.is-opened>.el-submenu__title .el-submenu__icon-arrow,
		.el-submenu__icon-arrow {
			opacity: 0;
		}

		.el-submenu__title {
			height: 50px;
			font-size: 0;
			line-height: 50px;
			padding-left: 41px !important;
		}

		.el-menu-item {
			font-size: 12px;
			height: 40px;
			line-height: 40px;
			min-width: 0;
			padding: 0 0 0 65px !important;
		}

		.el-menu-item.is-active {
			background-color: #35465A !important;
			border-right: 3px solid #5B8EF0;
		}

		.el-icon {
			width: 14px;
			height: 14px;
			margin-right: 9px;
		}

		.el-icon-01 {
			background: url(assets/head/06@2x.png) no-repeat center;
			background-size: 100% 100%;
		}

		.el-icon-02 {
			background: url(assets/head/04@2x.png) no-repeat center;
			background-size: 100% 100%;
		}

		.el-icon-03 {
			background: url(assets/head/03@2x.png) no-repeat center;
			background-size: 100% 100%;
		}

		.el-icon-04 {
			background: url(assets/head/08@2x.png) no-repeat center;
			background-size: 100% 100%;
		}

		.el-icon-05 {
			background: url(assets/head/05@2x.png) no-repeat center;
			background-size: 100% 100%;
		}

		.el-icon-06 {
			background: url(assets/head/10@2x.png) no-repeat center;
			background-size: 100% 100%;
		}

		.el-icon-07 {
			background: url(assets/head/11@2x.png) no-repeat center;
			background-size: 100% 100%;
		}

		.el-menu-item-group__title {
			padding: 0;
			height: 0;
		}
	}

	.main_box {
		flex: 1;
		overflow: hidden;
		padding: 0 30px;
		box-sizing: border-box;
		background-color: rgba(34, 44, 57, 1);
	}
}

.mt10 {
	margin-top: 10px;
}

.mt15 {
	margin-top: 15px;
}

.mt20 {
	margin-top: 20px;
}

.mt25 {
	margin-top: 25px;
}

.mt30 {
	margin-top: 30px;
}

.mt40 {
	margin-top: 40px;
}

.mb10 {
	margin-bottom: 10px;
}

.mb15 {
	margin-bottom: 15px;
}

.mb20 {
	margin-bottom: 20px;
}

.mb25 {
	margin-bottom: 25px;
}

.mb30 {
	margin-bottom: 30px;
}

.mb40 {
	margin-bottom: 40px;
}

// table {
// 	width: 100% !important;
// }
</style>
