<template>
	<div class="box">
		<el-image style="width: 100%; height: 100vh" :src="require('../../assets/login/01.png')" fit="cover"></el-image>
		<div class="login_content bg_fff">
			<div class="font_26 bold_ text_center" style="margin: 16vh 0 17vh;">{{$publicFun.get_LostName().loginName}}</div>
			<div class="text_left p_lr70">
				<div class="font_20 bold_" style="margin-bottom: 2.8vh;">用户登录</div>
				<el-form :model="form" class="login_form" ref="form" :rules="fromRules">
					<el-form-item style="margin-bottom: 1.6vh;" prop="username">
						<p class="font_16" style="margin-bottom: 1.6vh;">用户名：</p>
						<el-input placeholder="请输入用户名" v-model="form.username" clearable></el-input>
					</el-form-item>
					<br>
					<el-form-item prop="password">
						<p class="font_16" style="margin-bottom: 1.6vh;">密码：</p>
						<el-input placeholder="请输入密码" v-model="form.password" show-password></el-input>
					</el-form-item>
				</el-form>
				<div class="remember_wrqapper flex01" style="margin: 1.9vh 0 5vh;">
					<el-checkbox v-model="checked"></el-checkbox>
					<p class="font_14" style="margin-left: 7px;">记住密码</p>
				</div>
				<div class="btn03 pointer_" @click="getSubmit">提交</div>
			</div>
			<!-- <div class="login_foot font_14 text_center">
				<p>恰巧科技版权所有</p>
				<p>V1.0.0</p>
			</div> -->
		</div>
	</div>
</template>

<script>
	import {
		dologin,
		getGroupList
	} from '@/api/index.js'

	export default {
		data() {
			return {
				checked: false, // 是否记住密码
				form: {
					username: '', // 用户名
					password: '', // 密码
				},
				fromRules: {
					username: [{
						required: true,
						message: '请输入用户名称',
						trigger: 'blur'
					}, ],
					password: [{
						required: true,
						message: '请输入用户密码',
						trigger: 'blur'
					}, ],
				}
			}
		},
		mounted() {
			if (localStorage.getItem('username')) {
				this.checked = true;
				this.form.username = localStorage.getItem('username');
				this.form.password = localStorage.getItem('password');
			}
		},
		methods: {
			getSubmit() {
				this.$refs.form.validate((valid) => {
					if (valid) {
						dologin(this.form).then(res => {
							localStorage.setItem('token', res.data.token)
							this.$message.success('登录成功!')
							localStorage.setItem('default_active_num', '1-1')

							setTimeout(() => {
								this.$router.replace('/');
								console.log('登录发送信号');
								this.$emit('loginSuccess');
							}, 500)

							getGroupList().then(res => {
								let list_ = res.data;
								localStorage.setItem('groupList', JSON.stringify(list_));
							})

							// 记住密码
							if (this.checked) {
								localStorage.setItem('username', this.form.username); // 用户名
								localStorage.setItem('password', this.form.password); // 密码
							}

							if (!this.checked) {
								localStorage.removeItem('username');
								localStorage.removeItem('password');
							}
						})
					}
				})
			}
		}
	}
</script>

<style lang="less" scoped>
	.box {}

	.login_content {
		top: 0;
		right: 0;
		bottom: 0;
		width: 500px;
		position: absolute;

		.login_foot {
			left: 0;
			right: 0;
			bottom: 42px;
			position: absolute;
		}
	}
</style>
