import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import ElementUI from 'element-ui';
import css from "./public/css/public.css";
import 'element-ui/lib/theme-chalk/index.css';
import publicFun from './public/js/public.js';
import htmlToPdf from './public/js/htmlToPdf.js';

Vue.use(ElementUI);
Vue.use(htmlToPdf);

import {
	Message
} from 'element-ui';

Vue.prototype.$message = Message;
Vue.prototype.$publicFun = publicFun;
// 自定义指令，实现按下enter后，光标自动跳转下一个输入框
Vue.directive('enterNextInput', {
  inserted: function (el) {
    el.addEventListener("keypress", function (e) {
      e = e || window.event;
      let charcode = typeof e.charCode == "number" ? e.charCode : e.keyCode;
      if (charcode == 13) {
        var dom = document.getElementsByTagName("input");
        for (var i = 0; i < dom.length; i++) {
          if (dom[i] == document.activeElement) {
            if (i == dom.length) {
              return;
            }
            dom[i + 1].focus();
            return;
          }
        }
      }
    });
  },
});
Vue.config.productionTip = false;

document.title = publicFun.get_LostName().loginName;

new Vue({
	router,
	store,
	render: (h) => h(App),
}).$mount("#app");
