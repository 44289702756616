<template>
	<div class="screen_wrapper" v-if="modal_show">
		<div class="screen_content bg_fff popMain">
			<p class="font_16 bold_ flex04 screen_title">高级搜索</p>
			<div class="p_lr26 bg_fff" style="overflow: hidden;">
				<div class="screen_form">
					<div>
						<p class="font_14 m_tb12">报价日期：</p>
						<div class="flex04 screen_date">
							<div class="w_137">
								<el-date-picker type="date" value-format="yyyy-MM-dd" placeholder="选择日期"
									v-model="submit_info.start_time"></el-date-picker>
							</div>
							<div class="screen_date_line"></div>
							<div class="w_137">
								<el-date-picker type="date" value-format="yyyy-MM-dd" placeholder="选择日期"
									v-model="submit_info.end_time"></el-date-picker>
							</div>
						</div>
					</div>
					<div>
						<p class="font_14 m_tb12">服务类型：</p>
						<v-select :placeText="'选择服务类型'" :list="fuwu_type_list" :index="1"
							:value_content="service_type_name" @get_search_value="get_search_value"></v-select>
					</div>
					<div>
						<p class="font_14 m_tb12">运单编号：</p>
						<el-input placeholder="" v-model="submit_info.waybill_no" clearable></el-input>
					</div>
					<div>
						<p class="font_14 m_tb12">报价人员：</p>
						<v-select04 :placeText="''" :type_num="0" :value_content="submit_info.bidder"
							@get_input_value="get_input_value"></v-select04>
					</div>
					<div>
						<p class="font_14 m_tb12">销售人员：</p>
						<el-input placeholder="" v-model="submit_info.sales_person" clearable></el-input>
					</div>
					<div>
						<p class="font_14 m_tb12">客户名称：</p>
						<v-select05 :placeText="''" :icon_num="true" :list="kehu_list" :index="2"
							:value_name="company_name" @result="get_search_value"></v-select05>
					</div>
					<div>
						<p class="font_14 m_tb12">应收结算：</p>
						<v-select :placeText="'请选择'" :list="yingshouList" :index="7"
							:value_content="offer_confirm_name" @get_search_value="get_search_value"></v-select>
					</div>
					<template v-if="modal_type == 1">
						<div>
							<p class="font_14 m_tb12">供应商名称：</p>
							<v-select05 :placeText="''" :icon_num="true" :list="gongyingshang_list" :index="6"
								:value_content="gongyingshang_name" @result="get_search_value"></v-select05>
						</div>
						<div>
							<p class="font_14 m_tb12">应付结算：</p>
							<v-select :placeText="'请选择'" :list="yingshouList" :index="8"
								:value_content="offer_confirm_name"
								@get_search_value="get_search_value"></v-select>
						</div>
					</template>

					<!-- <div v-if="modal_type == 1">
						<p class="font_14 m_tb12">供应商名称：</p>
						<v-select :placeText="''" :list="gongyingshang_list" :index="6" :value_content="gongyingshang_name"
						 @get_search_value="get_search_value"></v-select>
					</div> -->
					<div v-else>
						<p class="font_14 m_tb12">报价确认：</p>
						<v-select :placeText="''" :list="baojia" :index="3" :value_content="offer_confirm_name"
							@get_search_value="get_search_value"></v-select>
					</div>
					<div>
						<p class="font_14 m_tb12">起运地/起运港：</p>
						<v-select05 :placeText="''" :icon_num="true" :list="qiyundi_list" :index="4"
							:value_name="shipment_name" @result="get_search_value"></v-select05>
					</div>
					<div>
						<p class="font_14 m_tb12">目的地/目的港：</p>
						<v-select05 :placeText="''" :icon_num="true" :list="mudidi_list" :index="5"
							:value_name="destination_name" @result="get_search_value"></v-select05>
					</div>
				</div>
				<div class="flex02 m_tb40">
					<div class="btn02" @click="get_reset">重置</div>
					<div class="btn02" @click="get_exit">退出</div>
					<div class="btn01" @click="get_inquire">查询</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import select03 from '../select/index02.vue';
import select04 from '../select/index04.vue';
import select05 from '../select/index05.vue';

import {
	serviceType,
	companyList,
	startHarbor,
	endHarbor
} from '@/api/index';

export default {
	components: {
		'v-select': select03,
		'v-select04': select04,
		'v-select05': select05
	},
	props: {
		modal_show: {
			type: Boolean
		},
		modal_type: {
			type: Number
		}
	},
	data() {
		return {
			input: '',
			kehu_list: [],
			qiyundi_list: [],
			mudidi_list: [],
			gongyingshang_list: [],
			fuwu_type_list: [], // 服务类型
			service_type_name: '',
			company_name: '',
			gongyingshang_name: '',
			offer_confirm_name: '',
			shipment_name: '',
			destination_name: '',
			submit_info: {
				start_time: '',
				end_time: '',
				service_type_id: '',
				bidder: '',
				company_id: '',
				business_id: '',
				offer_confirm: '',
				shipment_id: '',
				destination_id: '',
				waybill_no: '',//运单编号
				sales_person: '',//销售人员
				receivable1_status: '',//应收结算状态
				handle1_status: '',//应付结算状态
			},
			baojia: [{
				id: 0,
				name: '否'
			}, {
				id: 1,
				name: '是'
			}],
			// 应收应付结算下拉
			yingshouList: [{
				id: 1,
				name: '未确认未开票'
			}, {
				id: 2,
				name: '已确认未开票'
			}, {
				id: 3,
				name: '已确认已开票'
			}, {
				id: 4,
				name: '部分回款'
			}, {
				id: 5,
				name: '全部回款'
			}],
		}
	},
	created() {
		this.getTypeList();
		this.getCompanyList();
		this.getStartHarborList();
		this.getEndHarborList();
	},
	mounted() {
		// document.addEventListener('click', (e) => {
		// 	console.log('eeeeeeee',e.target.className)
		// 	if (e.target.className != 'popMain' && e.target.className=='screen_wrapper') {
		// 		console.log('关闭',e.target.className)
		// 		this.get_int();
		// 		this.$emit('getExit')
		// 	}
		// });
		window.addEventListener("keydown", this.keyDown);
	},
	destroyed() {
		window.removeEventListener("keydown", this.keyDown, false);;
	},
	methods: {
		// 点击回车
		keyDown(e) {
			if (this.modal_show) {
				console.log('点击回车e', e)
				// 回车则执行登录方法 enter键的ASCII是13
				if (e.keyCode === 13) {
					this.get_inquire();
				}
			}
		},
		// 服务类型
		getTypeList() {
			serviceType({
				page: 1,
				limit: 100000
			}).then(res => {
				this.fuwu_type_list = res.data.data;
			})
		},
		// 客户名称
		getCompanyList() {
			companyList({
				page: 1,
				limit: 100000,
				partner_type: 2
			}).then(res => {
				let list_ = res.data.data;

				list_.forEach((n, k) => {
					this.kehu_list.push({
						label: n.company_name,
						value: n.id
					})
				})
			})

			companyList({
				page: 1,
				limit: 100000,
				partner_type: 1
			}).then(res => {
				res.data.data.forEach((n, k) => {
					n.name = n.company_name
				})

				// this.gongyingshang_list = res.data.data;
				let list_ = res.data.data;
				list_.forEach((n, k) => {
					this.gongyingshang_list.push({
						label: n.company_name,
						value: n.id
					})
				})
				console.log('供应商', this.gongyingshang_list)
			})
		},
		// 起运地
		getStartHarborList() {
			startHarbor({
				page: 1,
				limit: 100000
			}).then(res => {
				let list_ = res.data.data;

				list_.forEach((n, k) => {
					this.qiyundi_list.push({
						label: n.name + '/' + n.code,
						value: n.id
					})
				})
			})
		},
		// 目的地
		getEndHarborList() {
			endHarbor({
				page: 1,
				limit: 100000
			}).then(res => {
				let list_ = res.data.data;

				list_.forEach((n, k) => {
					this.mudidi_list.push({
						label: n.name + '/' + n.code,
						value: n.id
					})
				})
			})
		},
		// 选中回调
		get_search_value(e) {
			if (e.index == 1) {
				// 服务类型
				this.submit_info.service_type_id = e.id;
			}

			if (e.index == 2) {
				// 客户名称
				this.submit_info.company_id = e.value;
			}

			if (e.index == 3) {
				// 报价确认
				this.submit_info.offer_confirm = e.id;
			}

			if (e.index == 4) {
				// 起运地/起运港
				this.submit_info.shipment_id = e.value;
			}

			if (e.index == 5) {
				// 目的地/目的港
				this.submit_info.destination_id = e.value;
			}

			if (e.index == 6) {
				// 供应商
				this.submit_info.business_id = e.value;
				console.log('1111111==>', e, this.submit_info.business_id)
			}
			if (e.index == 7) {
				// 应收结算状态
				this.submit_info.receivable1_status = e.id;
				console.log('应收结算状态', e)
			}
			if (e.index == 8) {
				// 应收结算状态
				this.submit_info.handle1_status = e.id;
				console.log('应付结算状态', e)
			}
		},
		//
		get_input_value(e) {
			this.submit_info.bidder = e.value;
		},
		get_int() {
			this.submit_info = {
				start_time: '',
				end_time: '',
				service_type_id: '',
				bidder: '',
				company_id: '',
				business_id: '',
				offer_confirm: '',
				shipment_id: '',
				destination_id: '',
				waybill_no: '',
				sales_person: '',
				receivable1_status: '',
				handle1_status: '',
			}
		},
		// 重置
		get_reset() {
			this.get_int();
		},
		// 退出
		get_exit() {
			this.get_int();

			this.$emit('getExit')
		},
		// 查询
		get_inquire(e) {
			console.log('确认操作', e)
			console.log('this.submit_info', this.submit_info)
			this.$emit('getInquire', this.submit_info);
			this.get_exit();
		}
	}
}
</script>

<style lang="less" scoped>
.screen_wrapper {
	top: 100px;
	left: 170px;
	right: 0;
	bottom: 0;
	z-index: 999;
	display: flex;
	position: fixed;
	overflow: scroll;
	justify-content: flex-end;
	background-color: rgba(0, 0, 0, 0.2);

	&::-webkit-scrollbar {
		//滚动条整体部分
		width: 0;
		height: 0;
	}

	.screen_content {
		width: 366px;

		.screen_title {
			height: 52px;
			border-bottom: 1px solid #D0D0D0;
		}
	}
}

.w_137 {
	width: 137px;
}

.screen_date {
	.screen_date_line {
		width: 17px;
		height: 1px;
		margin: 0 14px;
		background-color: #666666;
	}

	/deep/ .el-date-editor.el-input,
	/deep/ .el-date-editor.el-input__inner {
		width: 100%;
	}

	/deep/ .el-input__inner {
		border-radius: 10px;
		font-size: 12px;
	}
}

.screen_form {
	overflow: hidden;
	margin: 8px 0 0 0;
}

/deep/ .el-select {
	width: 100%;
}
</style>
