<template>
	<div class="box01">
		<v-crumbs :crumbsType='crumbs'></v-crumbs>
		<div>
			<div class="form_wrapper">
				<div class="form_wrapper_left">
					<div class="form_wrapper_block">
						<div class="form_wrapper_title">
							<p></p>
							<p>帮助中心</p>
						</div>
						<div class="info_wrapper">
							<div class="info_wrapper_block">
								<div class="form_input_wrapper">
									<p class="form_input_label">标题:</p>
									<div class="form_input">
										<el-input v-model="info.title" placeholder="请填写标题"></el-input>
									</div>
								</div>
							</div>
							<div class="info_wrapper_block">
								<div class="form_input_wrapper" style="display: block;">
									<p class="form_input_label m_b16">内容:</p>
									<div class="form_input" style="background-color: #fff;width: 100%;border-radius: 10px;overflow: hidden;">
										<quill-editor v-model="info.content" ref="myQuillEditor" :options="editorOption"></quill-editor>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div class="form_wrapper_block">
						<div class="form_wrapper_btn_wrapper">
							<div class="btn01" @click="get_save_info">保存</div>
						</div>
					</div>
				</div>
				<div class="form_wrapper_right form_wrapper_block" style="height: 0;"></div>
			</div>
		</div>
	</div>
</template>

<script>
	import {
		quillEditor
	} from "vue-quill-editor"; // 调用编辑器

	import 'quill/dist/quill.core.css';
	import 'quill/dist/quill.snow.css';
	import 'quill/dist/quill.bubble.css';
	import crumbs from '../../components/crumbs/index.vue'; // 面包屑

	import {
		helpList,
		helpShow,
		helpAdd,
		helpEdit,
		helpDel
	} from '@/api/index';

	export default {
		components: {
			'v-crumbs': crumbs,
			quillEditor
		},
		data() {
			return {
				id: '',
				crumbs: 22,
				info: {
					title: '',
					content: ''
				},
				editorOption: {},
			}
		},
		created() {
			if (this.$route.query.id) {
				this.id = this.$route.query.id;
				this.get_detail();
				this.crumbs = 221
			}
		},
		methods: {
			// 详情
			get_detail() {
				helpShow({
					id: this.id
				}).then(res => {
					this.info = res.data;
				})
			},
			// 保存
			get_save_info() {
				if (!this.info.title) return this.$message('请输入标题');
				if (!this.info.content) return this.$message('请输入内容');

				if (this.id) {
					// 编辑
					helpEdit({
						id: this.info.id,
						title: this.info.title,
						content: this.info.content
					}).then(res => {
						this.$message.success('修改成功');
						this.$router.go(-1);
					})
				}

				if (!this.id) {
					// 添加
					helpAdd({
						title: this.info.title,
						content: this.info.content
					}).then(res => {
						this.$message.success('添加成功');
						this.$router.go(-1);
					})
				}
			}
		},
		computed: {
			editor() {
				return this.$refs.myQuillEditor.quill;
			},
		}
	}
</script>

<style lang="less" scoped>
	.info_wrapper {
		padding: 12px 9px;

		.info_wrapper_block {
			display: block;
			padding-right: 244px;
		}

		.form_input {
			flex: 1;
		}
	}

	/deep/ .ql-container.ql-snow {
		border: none;
	}

	.quill-editor {
		min-height: 500px;
	}

	/deep/ input::-webkit-input-placeholder,
	/deep/ textarea::-webkit-input-placeholder {
		color: #5B8EF0;
	}

	/deep/ input::-moz-placeholder,
	/deep/ textarea::-webkit-input-placeholder {
		color: #5B8EF0;
	}

	/deep/ input:-moz-placeholder,
	/deep/ textarea::-webkit-input-placeholder {
		color: #5B8EF0;
	}

	/deep/ input:-ms-input-placeholder,
	/deep/ textarea::-webkit-input-placeholder {
		color: #5B8EF0;
	}
</style>
