<template>
	<div class="box01">
		<v-crumbs :crumbsType='31' v-if="!id"></v-crumbs>
		<v-crumbs :crumbsType='311' v-if="id"></v-crumbs>
		<div>
			<div class="form_wrapper">
				<div class="form_wrapper_left">
					<div class="form_wrapper_block">
						<div class="form_wrapper_title">
							<p></p>
							<p>基本信息</p>
						</div>
						<div class="info_wrapper">
							<div class="info_wrapper_block">
								<div class="form_input_wrapper">
									<p class="form_input_label">父类:</p>
									<div class="form_input w_510">
										 <!-- @change="get_change(2, $event, index)" -->
										<el-select  v-enter-next-input placeholder="请选择" v-model="submit_info.pid"
												>
												<el-option v-for="item in parentGroupList" :key="item.id"
													:label="item.name" :value="item.id"></el-option>
											</el-select>
									</div>
								</div>
							</div>
							<div class="info_wrapper_block">
								<div class="form_input_wrapper">
									<p class="form_input_label">角色名称:</p>
									<div class="form_input w_510">
										<el-input v-enter-next-input v-model="submit_info.name" placeholder="请填写角色名称"></el-input>
									</div>
								</div>
							</div>
							<div class="info_wrapper_block">
								<div class="form_input_wrapper">
									<p class="form_input_label">角色说明:</p>
									<div class="form_input w_510">
										<el-input v-enter-next-input v-model="submit_info.remark" placeholder="请填写角色说明"></el-input>
									</div>
								</div>
							</div>
							<div class="info_wrapper_block">
								<div class="form_input_wrapper" style="align-items: flex-start;">
									<p class="form_input_label" style="margin-top: 10px;">角色权限:</p>
									<div class="form_input w_510">
										<el-tree v-enter-next-input :data="auth_list" node-key="id" :props="defaultProps" :default-expanded-keys="ids_show"
										 :default-checked-keys="ids_show" show-checkbox @check="handleCheckChange"></el-tree>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div class="form_wrapper_btn_wrapper">
						<div class="btn01" @click="get_save">确认</div>
					</div>
				</div>
				<div class="form_wrapper_right form_wrapper_block" style="background-color: transparent;"></div>
			</div>
		</div>
	</div>
</template>

<script>
	import crumbs from '../../components/crumbs/index.vue'; // 面包屑

	import {
		getAuthList,
		getAuthAdd,
		getAuthShow,
		getAuthEdit,getParentGroup
	} from '@/api/index';

	export default {
		components: {
			'v-crumbs': crumbs
		},
		data() {
			return {
				id: '',
				ids: [],
				ids_show: [],
				auth_list: [],
				submit_info: {
					pid: '', // 父类id
					remark: '', // 说明
					name: '', // 角色名称
					rules: [], // 权限 多个以逗号隔开
					rules_str: '',
				},
				defaultProps: {
					children: 'childlist',
					label: 'name'
				},
				parentGroupList: [],//父类列表
			}
		},
		created() {
			if (this.$route.query.id) {
				this.id = this.$route.query.id;
				this.getAuthShow();
			}
			this.get_parent_group();
			this.get_auth_list();
		},
		methods: {
			// 详情
			getAuthShow() {
				getAuthShow({
					id: this.id
				}).then(res => {
					this.submit_info = res.data;
					this.ids = res.data.rules.split(',');
					this.ids_show = res.data.rules_list.split(',');
				})
			},
			// 获取父类列表
			get_parent_group(){
				getParentGroup().then(res=>{
					console.log('获取父类列表',res)
					this.parentGroupList=res.data;
				})
			},
			get_auth_list() {
				getAuthList().then(res => {
					this.auth_list = res.data;
				})
			},
			handleCheckChange(e, data) {
				this.ids = [];
				this.ids = data.checkedKeys.concat(data.halfCheckedKeys);
				this.ids_show = data.checkedKeys;
			},
			// 确认
			get_save() {
				if (!this.submit_info.pid) return this.$message('请选择父类');
				if (!this.submit_info.name) return this.$message('请填写角色名称');
				if (!this.submit_info.remark) return this.$message('请填写角色说明');
				if (this.ids.length === 0) return this.$message('请勾选角色权限');

				this.submit_info.rules = this.ids;
				this.submit_info.ids_show = this.ids_show.join(',')

				if (!this.id) {
					// 添加
					getAuthAdd(this.submit_info).then(res => {
						this.$message.success('添加成功！');
						this.$publicFun.get_link('/authority/role', {});
					})
				}

				if (this.id) {
					getAuthEdit(this.submit_info).then(res => {
						this.$message.success('编辑成功！');
						this.$publicFun.get_link('/authority/role', {});
					})
				}
			}
		}
	}
</script>

<style lang="less" scoped>
	.info_wrapper {
		padding: 12px 9px;
	}

	.form_wrapper .info_wrapper_block {
		display: block;
		margin-top: 20px;
		margin-bottom: 20px;
	}

	/deep/.el-tree {
		color: #fff;
		background-color: transparent;
	}

	/deep/ .el-tree-node {
		margin: 10px 0;

		&:hover {
			background-color: transparent;
		}
	}

	/deep/ .el-tree-node__content {
		&:hover {
			background-color: transparent;
		}
	}

	/deep/ .el-tree-node:focus>.el-tree-node__content {
		background-color: transparent;
	}

	/deep/ input::-webkit-input-placeholder,
	/deep/ textarea::-webkit-input-placeholder {
		color: #5B8EF0;
	}

	/deep/ input::-moz-placeholder,
	/deep/ textarea::-webkit-input-placeholder {
		color: #5B8EF0;
	}

	/deep/ input:-moz-placeholder,
	/deep/ textarea::-webkit-input-placeholder {
		color: #5B8EF0;
	}

	/deep/ input:-ms-input-placeholder,
	/deep/ textarea::-webkit-input-placeholder {
		color: #5B8EF0;
	}
</style>
