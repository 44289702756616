<template>
	<div>
		<div class="a4_list">
			<div class="a4_btn_wrapper">
				<div class="btn01 a4_btn" @click="getPdf('报价单' + info.order_no)">导出</div>
				<div class="a4_btn flex03 right" v-if="ids.length > 1">
					<p class="btn01" @click="get_btn('up')">上一个</p>
					<p class="btn01" style="margin-left: 20px;" @click="get_btn('down')">下一个</p>
				</div>
			</div>
			<div class="a4" :id="'报价单' + info.order_no">
				<p class="a4_title">报 价 单</p>
				<div class="a4_top">
					<img :src="$publicFun.get_LostName().logo1" />
					<p>{{$publicFun.get_LostName().nameText}}</p>
				</div>
				<p class="a4_top_line"></p>
				<p class="a4_top_line"></p>
				<div class="a4_info_block" style="padding-bottom: 0;">
					<p class="bold_">致 {{info.company_id_name}}</p>
				</div>
				<div class="a4_info">
					<div class="a4_info_block" style="padding-top: 0;">
						<div class="a4_info_block_list">
							<p>联系人：</p>
							<p>{{info.linkman_id_name}}</p>
						</div>
						<div class="a4_info_block_list">
							<p>电话：</p>
							<p>{{info.mobile}}</p>
						</div>
						<div class="a4_info_block_list">
							<p>邮箱：</p>
							<p>{{info.email}}</p>
						</div>
					</div>
					<div class="a4_info_block" style="padding-top: 0;">
						<div class="a4_info_block_list">
							<p>有效期至：</p>
							<p>{{info.validity}}</p>
						</div>
						<div class="a4_info_block_list">
							<p>报价编号：</p>
							<p>{{info.order_no}}</p>
						</div>
						<div class="a4_info_block_list">
							<p>服务类型：</p>
							<p>{{info.service_type_id_name}}</p>
						</div>
					</div>
				</div>
				<div class="a4_b_line">
					<div class="a4_info" style="border-bottom: none;">
						<div class="a4_info_block">
							<div class="a4_info_block_list">
								<p>服务范围：</p>
								<p>{{info.services_scope_id_name}}</p>
							</div>
							<div class="a4_info_block_list">
								<p>起运港：</p>
								<p>{{info.shipment_id_name}}</p>
							</div>
							<div class="a4_info_block_list">
								<p>件数：</p>
								<p>{{info.cases}}</p>
							</div>
							<div class="a4_info_block_list">
								<p>毛重：</p>
								<p>{{info.rough_weight}} Kgs</p>
							</div>
							<div class="a4_info_block_list">
								<p>计重：</p>
								<p>{{info.weight}} Kgs</p>
							</div>
						</div>
						<div class="a4_info_block">
							<div class="a4_info_block_list">
								<p>航司/船司：</p>
								<p>{{info.shipping_company_name}}</p>
							</div>
							<div class="a4_info_block_list">
								<p>目的港：</p>
								<p>{{info.destination_id_name}}</p>
							</div>
							<div class="a4_info_block_list">
								<p>尺寸：</p>
								<p v-html="info.chicun"></p>
							</div>
							<div class="a4_info_block_list">
								<p>货物名称：</p>
								<p>{{info.chinese_name}}</p>
							</div>
						</div>
					</div>
					<p class="a4_des01"><span>尊敬的客户，</span><span>关于上述服务涉及的</span><span>费用明细如下</span>：</p>
					<div>
						<div class="a4_table_list">
							<div class="a4_table_block title_">
								<p>费用名称</p>
								<p>数量</p>
								<p>单价</p>
								<p>金额</p>
								<p>币种</p>
								<p>备注</p>
							</div>
							<div class="a4_table_content">
								<div class="a4_table_block" v-for="(item, index) in info.cost" :key="index">
									<p>{{item.name_id_name}}</p>
									<p>{{item.number}}</p>
									<p>{{item.money}}</p>
									<p>{{item.price}}</p>
									<p>{{item.currency_system_name}}</p>
									<p>{{item.remark}}</p>
								</div>
							</div>
						</div>
						<div class="a4_table_des">
							<!-- <div class="a4_table_des_list">
								<p class="a4_table_des_label">多币种汇总汇率：</p>
								<p class="a4_table_des_content">
									<span v-for="(item, index) in info.offer_rate" :key="index">{{item.rate_name}} {{item.input_id}}</span>
								</p>
							</div> -->
							<div class="a4_table_des_list">
								<p class="a4_table_des_label">多币种汇总合计：</p>
								<p class="a4_table_des_content">
									<span v-for="(item, index) in currency_list" :key="index">{{item.name}} {{item.all_price.toFixed(2)}}</span>
								</p>
							</div>
							<div class="a4_table_des_list">
								<p class="a4_table_des_label bold_">总计金额：</p>
								<p class="a4_table_des_content">
									<span class="bold_">RMB {{info.total_price}}</span>
								</p>
							</div>
						</div>
					</div>
				</div>
				<!-- 报价说明 -->
				<div class="a4_explain_wrapper">
					<div class="a4_explain_text">
						<p>报价说明：</p>
						<!-- <p>1、已签合作协议用户，世鑫可先行垫付全程物流费用，服务完成后按约定账期结算；</p>
						<p>2、未签合作协议客户，按现结方式执行（特殊情况请与我司销售联系）；</p> -->
						<p>1、以上报价不含货物保险，如需代上物流保险，请提前告知；</p>
						<p>2、无特殊需求，正常开具电子版增值税普通发票。</p>
					</div>
					<div>
						<p class="a4_explain_title">我司公账付款信息如下：</p>
						<div class="a4_explain_table">
							<div class="a4_explain_table_list">
								<p>开户名</p>
								<p>:</p>
								<p>{{$publicFun.get_LostName().companyInfo.name}}</p>
							</div>
							<div class="a4_explain_table_list">
								<p>开户银行名称</p>
								<p>:</p>
								<p>{{$publicFun.get_LostName().companyInfo.bank}}</p>
							</div>
							<div class="a4_explain_table_list">
								<p>开户银行账户</p>
								<p>:</p>
								<p>{{$publicFun.get_LostName().companyInfo.bankAaccount}}</p>
							</div>
						</div>
					</div>
					<!-- 章 -->
					<div class="chapter_icon">
						<img :src="$publicFun.get_LostName().zhangImg" />
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import {
		offerShow,
		services
	} from '@/api/index';

	export default {
		data() {
			return {
				info: {},
				index: 0,
				all_price: 0,
				ids: [], // id合集
				now_id: '', // 当前的id
				currency_list: [], // 多币种
			}
		},
		created() {
			this.ids = this.$route.query.id.split(',');
			this.get_int();
		},
		methods: {
			// 详情数据
			get_detail() {
				offerShow({
					id: this.now_id
				}).then(res => {
					this.info = res.data;

					this.get_fuwufanwei();

					// 费用名称
					let cost_list = res.data.cost;

					if (cost_list.length > 0) {
						let tempArr = [];

						for (let i = 0; i < cost_list.length; i++) {
							this.all_price += parseFloat(cost_list[i].price);

							if (tempArr.indexOf(cost_list[i].currency_system_name) === -1) {
								this.currency_list.push({
									name: cost_list[i].currency_system_name,
									origin: [cost_list[i]]
								});
								tempArr.push(cost_list[i].currency_system_name);
							} else {
								for (let j = 0; j < this.currency_list.length; j++) {
									if (this.currency_list[j].name == cost_list[i].currency_system_name) {
										this.currency_list[j].origin.push(cost_list[i]);
										break;
									}
								}
							}
						}

						this.currency_list.forEach((n, k) => {
							n.all_price = 0;

							n.origin.forEach((n_, k_) => {
								n.all_price += parseFloat(n_.price);
							})
						})
					}

					// 货物信息
					let cargo_ = res.data.cargo;
					let cases = 0; // 件数
					let rough_weight = 0; // 毛重
					let chinese_name = ''; // 货物名称
					let chicun = ''; // 尺寸
					let weight = 0; // 计重

					if (cargo_.length > 0) {
						cargo_.forEach((n, k) => {
							cases += parseFloat(n.cases);
							rough_weight += parseFloat(n.rough_weight);
							chinese_name += n.chinese_name + '/';
							chicun += n.cases + '*' + n.long + '*' + n.broad + '*' + n.tall + ' CM</br>';
							weight += parseFloat(n.weight);
						})
					}

					this.info.cases = cases;
					this.info.rough_weight = rough_weight.toFixed(2);
					this.info.chinese_name = chinese_name.substr(0, chinese_name.length - 1);
					this.info.chicun = chicun;
					this.info.weight = weight.toFixed(2);
				})
			},
			// 服务范围
			get_fuwufanwei() {
				services({
					page: 1,
					limit: 100000
				}).then(res => {
					let data_ = res.data.data;

					data_.forEach((n, k) => {
						if (n.id === this.info.services_scope_id) {
							this.info.services_scope_id_name = n.name;
						}
					})

					this.$forceUpdate();
				})
			},
			//
			get_int() {
				this.all_price = 0;
				this.currency_list = [];
				this.now_id = this.ids[this.index];
				this.get_detail();
			},
			get_btn(e) {
				if (e === 'up') {
					// 上
					if (this.index === 0) return this.$message('没有上一个了！');

					this.index--;
					this.get_int();
				}

				if (e === 'down') {
					// 下
					if (this.index === (this.ids.length - 1)) return this.$message('没有下一个了！');

					this.index++;
					this.get_int();
				}
			}
		}
	}
</script>

<style>
</style>
