<template>
	<div class="">
		<div class="right_nav_content hid_">
			<div class="right_nav_list" @click="$publicFun.get_link(item.url, {})" :class="{'active': index == index_}" v-for="(item, index_) in list"
			 :key="index_">
				<img :src="item.icon" />
				<p>{{item.name}}</p>
			</div>
		</div>
	</div>
</template>

<script>
	export default {
		props: {
			index: {
				type: Number
			}
		},
		data() {
			return {
				list: [{
					name: '轮播图列表',
					icon: require('../../assets/wx/01@2x.png'),
					url: '/weixin/parament/banner'
				}, {
					name: '服务列表',
					icon: require('../../assets/wx/02@2x.png'),
					url: '/weixin/parament/serve'
				}, {
					name: '帮助中心',
					icon: require('../../assets/wx/03@2x.png'),
					url: '/weixin/parament/help'
				}, {
					name: '包装列表',
					icon: require('../../assets/wx/04@2x.png'),
					url: '/weixin/parament/pack'
				}]
			}
		}
	}
</script>

<style lang="less">
	.right_nav_content {
		height: 100%;
		background-color: #35465A;

		.right_nav_list {
			color: #fff;
			height: 35px;
			cursor: pointer;
			margin: 13px 10px;
			display: flex;
			font-size: 12px;
			line-height: 17px;
			align-items: center;
			border-radius: 7px;
			background-color: #3C4D5F;

			img {
				width: 14px;
				margin: 0 9px 0 15px;
			}

			&.active {
				background-color: #5B8EF0;
			}
		}
	}
</style>
