<template>
	<div class="box01">
		<v-crumbs :crumbsType="191" @get_mxy="get_mxy"></v-crumbs>
		<!-- <div class="offer_top flex02">
			<div class="flex03">
				<v-select02 :p_text="'文件名称'" :value_="formInfo.file_name" @serch="get_serch" @empty="get_empty"></v-select02>
			</div>
		</div> -->
		<div class="flex03">
			<div class="btn01" @click="get_collect">预览汇总账单</div>
		</div><br />
		<div class="form_box">
			<template>
				<el-table ref="multipleTable" :data="list" style="width: 100%">
					<el-table-column prop="id" label="ID" width="70"></el-table-column>
					<el-table-column prop="receivable_no" label="应收结算单号" v-if="typedetail==1"></el-table-column>
					<el-table-column prop="receivable_no" label="应付结算单号" v-if="typedetail==2"></el-table-column>
					<el-table-column prop="service_type_id_name" label="服务类型"></el-table-column>
					<el-table-column prop="waybill_no" label="运单编号"></el-table-column>
					<el-table-column prop="shipment_id_name" label="起运地/起运港"></el-table-column>
					<el-table-column prop="destination_id_name" label="目的地/目的港"></el-table-column>
					<el-table-column prop="number" label="件数"></el-table-column>
					<el-table-column prop="shipment_time" label="起运时间"></el-table-column>
					<el-table-column prop="weigh" label="计重"></el-table-column>
					<el-table-column prop="total_price" label="应收金额合计" v-if="typedetail==1"></el-table-column>
					<el-table-column prop="total_price" label="应付金额合计" v-if="typedetail==2"></el-table-column>
					<el-table-column prop="createtime_text" label="创建时间"></el-table-column>
				</el-table>
			</template>
		</div>
		<!-- <div class="pagination">
			<el-pagination background :page-size="formInfo.limit" :current-page="formInfo.page" layout="prev, pager, next, jumper, sizes"
			 :total="total" :page-sizes="[10, 20, 30, 40, 50, 60, 70, 80, 90, 100]" @current-change="currentChange" @size-change="handleSizeChange"></el-pagination>
		</div> -->
	
	
	</div>
</template>

<script>
	import crumbs from '../../components/crumbs/index.vue'; // 面包屑
	// import select02 from '../../components/select/index01.vue'; // 搜索框

	import {
		showCollect,
		getItemsData
	} from '@/api/index';

	export default {
		components: {
			'v-crumbs': crumbs,
			// 'v-select02': select02,
		},
		data() {
			return {
				list: [],
				ids: null,
				id:'',
        type:null,
		typedetail:{},
				// formInfo: {
				// 	page: 1,
				// 	limit: 10,
				// 	file_name: '', // 文件名称
				// },
			}
		},
		created() {
			this.id = this.$route.query.id;		
			this.type = this.$route.query.type;		
			this.get_list();
		},
		methods: {
			get_mxy() {
				this.formInfo = {
					page: 1,
					limit: 10,
					file_name: '', // 文件名称
				}

				this.get_list();
			},
			// 列表
			get_list() {
				showCollect({
					id:this.id
				}).then(res => {
					this.ids = res.data.ids;
					this.list = res.data.items;
					this.typedetail = res.data.type_id
				})
			},
			get_collect() {
				
				let c_id = this.ids;
				
				getItemsData({
					sid: c_id
				}).then(res => {
					if(this.typedetail==1){
						this.$publicFun.get_link('/settle/collect/pdf?id=' + c_id+'&type=' + this.type);
					}else{
						this.$publicFun.get_link('/settle/collect/pdffz?id=' + c_id+'&type=' + this.type);
					}
					
				})
			}
			// 清空搜索
			// get_empty(e) {
			// 	this.formInfo.page = 1;
			// 	this.formInfo.keyword = '';
			
			// 	this.get_list();
			// },
			
			// 点击页码数
			// currentChange(newPage) {
			// 	this.formInfo.page = newPage;
			// 	this.get_list();
			// 	window.scroll(0, 0);
			// },
			// 点击一页请求数据
			// handleSizeChange(num) {
			// 	this.formInfo.page = 1;
			// 	this.formInfo.limit = num;
			// 	this.get_list();
			// 	window.scroll(0, 0);
			// },
			// 搜索
			// get_serch(e) {
			// 	if (!e) return this.$message('请输入文件名称');

			// 	this.formInfo.page = 1;
			// 	this.formInfo.file_name = e, // 文件名称 = e;
			// 	this.get_list();
			// },
		}
	}
</script>

<style lang="less" scoped>
	.offer_top {
		margin-bottom: 15px;
	}
</style>
