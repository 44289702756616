<template>
	<div class="box01">
		<v-crumbs :crumbsType='32' v-if="!id"></v-crumbs>
		<v-crumbs :crumbsType='322' v-if="id"></v-crumbs>
		<div>
			<div class="form_wrapper">
				<div class="form_wrapper_left">
					<div class="form_wrapper_block">
						<div class="form_wrapper_title">
							<p></p>
							<p>基本信息</p>
						</div>
						<div class="info_wrapper">
							<div class="info_wrapper_block">
								<div class="form_input_wrapper">
									<p class="form_input_label">用户名:</p>
									<div class="form_input w_510">
										<el-input v-enter-next-input v-model="submit_info.username" placeholder="请填写用户名" :readonly="readonly"></el-input>
									</div>
								</div>
							</div>
							<div class="info_wrapper_block">
								<div class="form_input_wrapper">
									<p class="form_input_label">密码:</p>
									<div class="form_input w_510">
										<el-input v-enter-next-input show-password v-model="submit_info.password" placeholder="请填写密码"></el-input>
									</div>
								</div>
							</div>
							<div class="info_wrapper_block">
								<div class="form_input_wrapper">
									<p class="form_input_label">所属权限组:</p>
									<div class="form_input w_510">
										<v-select v-enter-next-input :icon_num="true" :list="juse_list" :value_name="juse_name" @result="get_juese_type"></v-select>
									</div>
								</div>
							</div>
							<div class="info_wrapper_block">
								<div class="form_input_wrapper">
									<p class="form_input_label">姓名:</p>
									<div class="form_input w_510">
										<el-input v-enter-next-input v-model="submit_info.nickname" placeholder="请填写姓名"></el-input>
									</div>
								</div>
							</div>
							<div class="info_wrapper_block">
								<div class="form_input_wrapper">
									<p class="form_input_label">联系方式:</p>
									<div class="form_input w_510">
										<el-input v-enter-next-input v-model="submit_info.mobile" placeholder="请填写联系方式"></el-input>
									</div>
								</div>
							</div>
							<div class="form_choose_wrapper">
								<p class="form_input_label">状态:</p>
								<div class="form_choose_content">
									<div class="form_choose_block" :class="{'active': item.is_show}" v-for="(item, index) in fanwei_list">
										<i @click="get_choose(fanwei_list, item, 1)"></i>
										<p>{{item.name}}</p>
									</div>
								</div>
							</div>
							<div class="form_choose_wrapper">
								<p class="form_input_label">是否查看订单:</p>
								<div class="form_choose_content">
									<div class="form_choose_block" :class="{'active': item.is_show}" v-for="(item, index) in chakan_list">
										<i @click="get_choose(chakan_list, item, 2)"></i>
										<p>{{item.name}}</p>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div class="form_wrapper_btn_wrapper">
						<div class="btn01" @click="get_save">确认</div>
					</div>
				</div>
				<div class="form_wrapper_right form_wrapper_block" style="background-color: transparent;"></div>
			</div>
		</div>
	</div>
</template>

<script>
	import crumbs from '../../components/crumbs/index.vue'; // 面包屑
	import select05 from '../../components/select/index05.vue'; // 模糊查询

	import {
		managerShow,
		managerEdit,
		managerAdd,
		showList
	} from '@/api/index';

	export default {
		components: {
			'v-crumbs': crumbs,
			'v-select': select05
		},
		data() {
			return {
				id: '',
				readonly: false,
				juse_name: '',
				juse_list: [],
				submit_info: {
					username: '', // 用户名
					nickname: '', // 昵称
					password: '', // 密码
					avatar: '', // 头像
					email: '', // 
					mobile: '',
					group_id: '', // 角色id
					status: 'normal', // normal显示  hidden隐藏
					show_orders: 0, // 是否查看订单 1：是，0：否
				},
				fanwei_list: [{
					name: '开启',
					id: 'normal',
					is_show: true
				}, {
					name: '关闭',
					id: 'hidden',
					is_show: false
				}],
				chakan_list: [{
					name: '是',
					id: 1,
					is_show: false
				}, {
					name: '否',
					id: 0,
					is_show: true
				}]
			}
		},
		created() {
			if (this.$route.query.id) {
				this.id = this.$route.query.id;
				this.managerShow();
				this.readonly = true;
			}

			this.get_list();
		},
		methods: {
			// 详情
			managerShow() {
				managerShow({
					id: this.id
				}).then(res => {
					this.submit_info = res.data;
					this.submit_info.group_id = res.data.groups.group.id;
					this.juse_name = res.data.groups.group.name;

					this.fanwei_list.forEach((n, k) => {
						if (n.id == res.data.status) {
							n.is_show = true
						} else {
							n.is_show = false
						}
					})

					this.chakan_list.forEach((n, k) => {
						if (n.id == res.data.show_orders) {
							n.is_show = true
						} else {
							n.is_show = false
						}
					})
				})
			},
			get_list() {
				showList(this.formInfo).then(res => {
					let list_ = res.data;

					list_.forEach((n, k) => {
						this.juse_list.push({
							label: n.name,
							value: n.id
						})
					})
				})
			},
			get_juese_type(e) {
				this.submit_info.group_id = e.value;
				this.juse_name = e.label;
			},
			get_choose(list, item, index) {
				list.forEach((n, k) => {
					n.is_show = false
				})

				item.is_show = true

				if (index === 1) {
					this.submit_info.status = item.id;
				}

				if (index === 2) {
					this.submit_info.show_orders = item.id;
				}
			},
			// 确认
			get_save() {
				if (!this.submit_info.username) return this.$message('请填写用户名');
				if (!this.submit_info.group_id) return this.$message('请选择所属权限组');
				if (!this.submit_info.nickname) return this.$message('请填写姓名');
				if (!this.submit_info.mobile) return this.$message('请填写联系方式');

				if (!this.id) {
					if (!this.submit_info.password) return this.$message('请填写密码');

					managerAdd(this.submit_info).then(res => {
						this.$message.success('添加成功！');
						this.$publicFun.get_link('/authority/administrator', {});
					})
				}

				if (this.id) {
					delete this.submit_info.groups;
					delete this.submit_info.logintime_text;

					managerEdit(this.submit_info).then(res => {
						this.$message.success('编辑成功！');
						this.$publicFun.get_link('/authority/administrator', {});
					})
				}
			}
		}
	}
</script>

<style lang="less" scoped>
	.info_wrapper {
		padding: 12px 9px;
	}

	.form_wrapper .info_wrapper_block {
		display: block;
		margin-top: 20px;
		margin-bottom: 20px;
	}

	/deep/ input::-webkit-input-placeholder,
	/deep/ textarea::-webkit-input-placeholder {
		color: #5B8EF0;
	}

	/deep/ input::-moz-placeholder,
	/deep/ textarea::-webkit-input-placeholder {
		color: #5B8EF0;
	}

	/deep/ input:-moz-placeholder,
	/deep/ textarea::-webkit-input-placeholder {
		color: #5B8EF0;
	}

	/deep/ input:-ms-input-placeholder,
	/deep/ textarea::-webkit-input-placeholder {
		color: #5B8EF0;
	}

	.form_input_label {
		width: 100px;
	}
</style>
