<template>
	<div class="select_wrapper01">
		<el-select v-model="value_name" filterable placeholder="请选择" @change="get_change"
			@visible-change="get_visible_change">
			<el-option v-for="item in list" :key="item.value" :label="item.label" :value="item"></el-option>
		</el-select>
		<div class="icon flex04" v-if="icon_num">
			<img src="../../assets/01.png" />
		</div>
	</div>
</template>

<script>
	import {
		getAllList,
		startHarbor,
		endHarbor,
		hsCode
	} from '@/api/index';

	export default {
		props: {
			list: {
				type: Array
			},
			icon_num: {
				type: Boolean
			},
			index: {
				type: Number
			},
			indexI: {
				type: Number
			},
			value_name: {
				type: String
			},
			from: {
				type: Number
			},
			service_type: {
				type: Number
			}
		},
		data() {
			return {

			}
		},
		methods: {
			get_change(e) {
				if (this.index != undefined) {
					e.index = this.index;
				}

				if (this.indexI != undefined) {
					e.indexI = this.indexI;
				}

				this.$emit('result', e);
			},
			get_visible_change(e) {
				if (e && this.list.length === 0) {
					if (this.from === 1) {
						this.get_getAllList();
					}

					if (this.from === 2) {
						this.getStartHarborList();
					}

					if (this.from === 3) {
						this.getEndHarborList();
					}

					if (this.from === 4) {
						this.getHsCodeList();
					}
				}
			},
			// 获取船司/航司
			get_getAllList() {
				getAllList({
					page: 1,
					limit: 100000
				}).then(res => {
					let list_ = res.data;

					list_.forEach((n, k) => {
						this.list.push({
							label: n.ship_name,
							value: n.id
						})
					})
				})
			},
			// 获取起运港列表
			getStartHarborList() {
				startHarbor({
					page: 1,
					limit: 100000,
					service_type: this.service_type
				}).then(res => {
					let list_ = res.data.data;
					this.list = [];

					list_.forEach((n, k) => {
						this.list.push({
							label: n.name + '/' + n.code,
							value: n.id
						})
					})
				})
			},
			// 获取目的港列表
			getEndHarborList() {
				endHarbor({
					page: 1,
					limit: 100000,
					service_type: this.service_type
				}).then(res => {
					let list_ = res.data.data;
					this.list = [];

					list_.forEach((n, k) => {
						this.list.push({
							label: n.name + '/' + n.code,
							value: n.id
						})
					})
				})
			},
			// 获取hs编码列表
			getHsCodeList() {
				hsCode({
					page: 1,
					limit: 100000
				}).then(res => {
					let list_ = res.data.data;

					list_.forEach((n, k) => {
						this.list.push({
							label: n.code,
							value: n.id
						})
					})
				})
			},

		}
	}
</script>

<style lang="less" scoped>
	.select_wrapper01 {
		position: relative;

		/deep/ .el-autocomplete {
			display: block;
		}

		/deep/ .el-input__inner {
			width: 100%;
		}

		/deep/ .el-input__inner {
			border-radius: 10px;
			padding-left: 17px;
			font-size: 12px;
			padding-right: 50px;
		}

		.icon {
			top: 0;
			right: 0;
			width: 28px;
			bottom: 0;
			position: absolute;
			background-color: #5B8EF0;
			border-radius: 0 10px 10px 0;

			img {
				width: 10px;
			}
		}
	}
</style>
