<template>
	<div class="serach_wrapper">
		<el-input :placeholder="p_text" @input="get_input" v-model="internalValue" @change="get_input_change">
			<el-button slot="append" icon="el-icon-search" @click="isClick"></el-button>
		</el-input>
	</div>
</template>

<script>
	export default {
		props: {
			p_text: {
				type: String
			},
			value_: {
				type: String
			}
		},
		data() {
			return {
				internalValue: this.value_
			}
		},
		methods: {
			isClick() {
				console.log('发送isClick',this.internalValue)
				this.$emit('serch', this.internalValue);
			},
			get_input(e) {
				if (!e) {
					console.log('发送get_input',this.internalValue)
					this.$emit('empty', this.internalValue);
				}
			},
			get_input_change(){
				console.log('发送get_input_change',this.internalValue)
				this.$emit('serch', this.internalValue);
			}
		}
	}
</script>

<style lang="less" scoped>
	.serach_wrapper {
		width: 350px;

		/deep/ .el-input__inner {
			padding-left: 17px;
			border: none;
			border-radius: 5px 0 0 5px;
		}

		/deep/ .el-button {
			border: none;
			height: 40px;
			background-color: #fff;
			padding-left: 15px;
			padding-right: 15px;
			border-radius: 0 5px 5px 0;
		}
	}
</style>
