<template>
     <!-- 批注子弹窗 -->
     <el-dialog title="批注" :visible.sync="pageShow" width="400px" append-to-body>
          <el-input type="textarea" v-model="content"></el-input>
          <span slot="footer" class="dialog-footer">
               <el-button size="small" @click="pageShow = false">取 消</el-button>
               <el-button size="small" type="primary" @click="save">确 定</el-button>
          </span>
     </el-dialog>
</template>

<script>
export default {
     data() {
          return {
               pageShow: false,
               content:'',
               curId:'',
          }
     },
     methods: {
          open(d) {
               this.pageShow=true;
               if(d && d.content){
                    this.curId=d.id;
                    this.content=d.content;
               }
          },
          save(){
               if(!this.content){
                    this.$message.warning('批注内容不能为空');
                    return;
               }
               this.pageShow=false;
               this.$emit('ok',{id:this.curId,content:this.content})
               this.content='';
          }
     },
}
</script>

<style lang="scss" scoped></style>