<template>
	<div class="box01">
		<v-crumbs :crumbsType='111'></v-crumbs>
		<div>
			<div class="form_wrapper">
				<div class="form_wrapper_left">
					<div class="form_wrapper_block" style="position: relative;">
						<div class="shade_wrapper"></div>
						<div class="form_wrapper_title">
							<p></p>
							<p>基本信息</p>
						</div>
						<div class="info_wrapper">
							<div class="info_wrapper_block">
								<div class="form_input_wrapper">
									<p class="form_input_label">应收结算单号:</p>
									<div class="form_input">
										<el-input placeholder="保存时自动生成" v-model="info.receivable_no"></el-input>
									</div>
								</div>
							</div>
							<div class="info_wrapper_block">
								<div class="form_input_wrapper">
									<p class="form_input_label">报价人员:</p>
									<div class="form_input">
										<el-input placeholder="请填写报价人员" v-model="info.orders.bidder"></el-input>
									</div>
								</div>
							</div>
							<div class="info_wrapper_block">
								<div class="form_input_wrapper">
									<p class="form_input_label">报价有效期:</p>
									<div class="form_input">
										<el-date-picker type="date" placeholder="选择日期" v-model="info.orders.validity">
										</el-date-picker>
									</div>
								</div>
							</div>
							<div class="info_wrapper_block">
								<div class="form_input_wrapper">
									<p class="form_input_label">服务类型:</p>
									<div class="form_input">
										<el-select placeholder="" v-model="info.orders.service_type_id_name">
										</el-select>
										<div class="icon flex04">
											<img src="../../assets/01.png" />
										</div>
									</div>
								</div>
							</div>
							<div class="info_wrapper_block">
								<div class="form_input_wrapper">
									<p class="form_input_label">销售人员:</p>
									<div class="form_input">
										<el-input placeholder=" " v-model="info.orders.sales_person"></el-input>
									</div>
								</div>
							</div>
							<div class="form_choose_wrapper">
								<p class="form_input_label">服务范围:</p>
								<div class="form_choose_content" style="flex-wrap: wrap;">
									<div class="form_choose_block"
										:class="{'active': item.id == info.orders.services_scope_id}"
										v-for="(item, index) in services_scope_list">
										<i></i>
										<p>{{item.name}}</p>
									</div>
								</div>
							</div>
							<div class="form_choose_wrapper">
								<p class="form_input_label">运输方式:</p>
								<div class="form_choose_content" style="flex-wrap: wrap;">
									<div class="form_choose_block"
										:class="{'active': item.id == info.orders.transport_id}"
										v-for="(item, index) in transport_list">
										<i></i>
										<p>{{item.name}}</p>
									</div>
								</div>
							</div>
							<div class="form_choose_wrapper">
								<p class="form_input_label">运输流向:</p>
								<div class="form_choose_content" style="flex-wrap: wrap;">
									<div class="form_choose_block"
										:class="{'active': item.id == info.orders.transportation_id}"
										v-for="(item, index) in transportation_list">
										<i></i>
										<p>{{item.name}}</p>
									</div>
								</div>
							</div>
							<div class="info_wrapper_block">
								<div class="form_input_wrapper">
									<p class="form_input_label">航司/船司:</p>
									<div class="form_input">
										<el-input placeholder="请填写航司/船司" v-model="info.orders.shipping_company_name">
										</el-input>
										<div class="icon flex04">
											<img src="../../assets/01.png" />
										</div>
									</div>
								</div>
							</div>
							<div class="info_wrapper_block">
								<div class="form_input_wrapper">
									<p class="form_input_label">线路:</p>
									<div class="form_input">
										<el-select placeholder="选择线路" v-model="info.orders.circuit_id_name"></el-select>
										<div class="icon flex04">
											<img src="../../assets/01.png" />
										</div>
									</div>
								</div>
							</div>
							<div class="info_wrapper_block">
								<div class="form_input_wrapper">
									<p class="form_input_label">运单编号:</p>
									<div class="form_input w_510">
										<el-input v-model="info.orders.waybill_no" placeholder="请填写运单编号"></el-input>
									</div>
								</div>
							</div>
							<div class="info_wrapper_block">
								<div class="form_input_wrapper">
									<p class="form_input_label w_118">起运地/起运港:</p>
									<div class="form_input w_480">
										<el-select placeholder="选择起运地/起运港" v-model="info.orders.shipment_id_name">
										</el-select>
										<div class="icon flex04">
											<img src="../../assets/01.png" />
										</div>
									</div>
								</div>
							</div>
							<div class="info_wrapper_block">
								<div class="form_input_wrapper">
									<p class="form_input_label w_118">目的地/目的港:</p>
									<div class="form_input w_480">
										<el-select placeholder="选择目的地/目的港" v-model="info.orders.destination_id_name">
										</el-select>
										<div class="icon flex04">
											<img src="../../assets/01.png" />
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div class="form_wrapper_block" style="position: relative;">
						<div class="shade_wrapper"></div>
						<div class="form_wrapper_title">
							<p></p>
							<p>货物信息</p>
						</div>
						<div class="form_table_wrapper">
							<div>
								<div class="form_table_list" v-for="(item, index) in info.cargo" :key="index">
									<div class="info_wrapper_block">
										<div class="form_input_wrapper">
											<p class="form_input_label">件数:</p>
											<div class="form_input">
												<el-input placeholder="请填写件数" v-model="item.cases"></el-input>
											</div>
										</div>
									</div>
									<div class="info_wrapper_block">
										<div class="form_input_wrapper">
											<p class="form_input_label">包装:</p>
											<div class="form_input">
												<el-input placeholder="请填写包装" v-model="item.pack"></el-input>
											</div>
										</div>
									</div>
									<div class="info_wrapper_block">
										<div class="form_input_wrapper">
											<p class="form_input_label">毛重:</p>
											<div class="form_input">
												<el-input placeholder="请填写毛重" v-model="item.rough_weight"></el-input>
											</div>
										</div>
									</div>
									<div class="info_wrapper_block">
										<div class="form_input_wrapper">
											<p class="form_input_label">计重:</p>
											<div class="form_input" style="display: flex;align-items: center;">
												<el-input placeholder="自动算出" v-model="item.weight"></el-input>
												<p class="form_input_unit">KG</p>
											</div>
										</div>
									</div>
									<div class="info_wrapper_block">
										<div class="form_input_wrapper">
											<p class="form_input_label">长:</p>
											<div class="form_input">
												<el-input placeholder="请填写长度" v-model="item.long"></el-input>
											</div>
										</div>
									</div>
									<div class="info_wrapper_block">
										<div class="form_input_wrapper">
											<p class="form_input_label">宽:</p>
											<div class="form_input">
												<el-input placeholder="请填写宽度" v-model="item.broad"></el-input>
											</div>
										</div>
									</div>
									<div class="info_wrapper_block">
										<div class="form_input_wrapper">
											<p class="form_input_label">高:</p>
											<div class="form_input">
												<el-input placeholder="请填写高度" v-model="item.tall"></el-input>
											</div>
										</div>
									</div>
									<div class="info_wrapper_block">
										<div class="form_input_wrapper">
											<p class="form_input_label">体积:</p>
											<div class="form_input" style="display: flex;align-items: center;">
												<el-input placeholder="自动算出" v-model="item.bulk"></el-input>
												<p class="form_input_unit">CBM</p>
											</div>
										</div>
									</div>
									<div class="info_wrapper_block">
										<div class="form_input_wrapper">
											<p class="form_input_label">中文品名:</p>
											<div class="form_input">
												<el-input placeholder="请填写中文品名" v-model="item.chinese_name"></el-input>
											</div>
										</div>
									</div>
									<div class="info_wrapper_block">
										<div class="form_input_wrapper">
											<p class="form_input_label">英文品名:</p>
											<div class="form_input w_476">
												<el-input placeholder="请填写英文品名" v-model="item.english_name"></el-input>
											</div>
										</div>
									</div>
									<div class="info_wrapper_block">
										<div class="form_input_wrapper">
											<p class="form_input_label">HS编码:</p>
											<div class="form_input">
												<el-input placeholder="请填写HS编码" v-model="item.hs_code_name"></el-input>
											</div>
										</div>
									</div>
									<div class="info_wrapper_block">
										<div class="form_input_wrapper">
											<p class="form_input_label">货值:</p>
											<div class="form_input">
												<el-input placeholder="请填写货值" v-model="item.low_value"></el-input>
											</div>
										</div>
									</div>
									<div class="info_wrapper_block">
										<div class="form_input_wrapper">
											<p class="form_input_label">币制:</p>
											<div class="form_input">
												<el-input placeholder="请填写币制" v-model="item.currency_system_name">
												</el-input>
											</div>
										</div>
									</div>
									<div class="info_wrapper_block">
										<div class="form_input_wrapper">
											<p class="form_input_label">船名/航次:</p>
											<div class="form_input">
												<el-input placeholder="请填写船名/航次" v-model="item.flight_name"></el-input>
											</div>
										</div>
									</div>
									<div class="info_wrapper_block">
										<div class="form_input_wrapper">
											<p class="form_input_label">起运时间:</p>
											<div class="form_input">
												<el-date-picker type="date" placeholder="选择日期"
													v-model="item.shipment_date"></el-date-picker>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div class="form_wrapper_block" style="position: relative;">
						<div class="shade_wrapper" v-if="type_"></div>
						<div class="form_wrapper_title">
							<p></p>
							<p>费用信息</p>
						</div>
						<div class="form_table_wrapper">
							<div class="form_table_add">
								<div class="form_table_add_block">
									<img src="../../assets/04@2x.png" @click="get_add_feiyong" />
									<p>新增费用</p>
								</div>
							</div>
							<div>
								<div class="form_th_wrapper">
									<div class="form_th_list">
										<div class="form_th_list_block">序号</div>
										<div class="form_th_list_block active">费用名称</div>
										<div class="form_th_list_block">数量</div>
										<div class="form_th_list_block">单价</div>
										<div class="form_th_list_block">金额</div>
										<div class="form_th_list_block">币制</div>
										<div class="form_th_list_block active">备注</div>
										<div class="form_th_list_block active">操作</div>
									</div>
									<div class="form_th_list" v-for="(item, index) in info.cost" :key="index">
										<div class="form_th_list_block">{{index + 1}}</div>
										<div class="form_th_list_block active" v-if="!item.is_save">
											<el-select v-model="item.cost_name.name" filterable placeholder="请选择"
												@change="get_change(1, $event, index)">
												<el-option v-for="item in feiyong_list" :key="item.id"
													:label="item.name" :value="item"></el-option>
											</el-select>
										</div>
										<div class="form_th_list_block active" v-else>{{item.cost_name.name}}</div>
										<div class="form_th_list_block" v-if="!item.is_save">
											<el-input placeholder="请输入数量" v-model="item.number"></el-input>
										</div>
										<div class="form_th_list_block" v-else>{{item.number}}</div>
										<div class="form_th_list_block" v-if="!item.is_save">
											<el-input placeholder="请输入单价" v-model="item.money"></el-input>
										</div>
										<div class="form_th_list_block" v-else>{{item.money}}</div>
										<div class="form_th_list_block" v-if="!item.is_save">
											<el-input placeholder="请输入金额" v-model="item.price"></el-input>
										</div>
										<div class="form_th_list_block" v-else>{{item.price}}</div>
										<div class="form_th_list_block" v-if="!item.is_save">
											<el-select v-model="item.currency_info.name" filterable placeholder="请选择"
												@change="get_change(2, $event, index)">
												<el-option v-for="item in bizhi_list" :key="item.id" :label="item.name"
													:value="item"></el-option>
											</el-select>
										</div>
										<div class="form_th_list_block" v-else>{{item.currency_info.name}}</div>
										<div class="form_th_list_block active" v-if="!item.is_save">
											<el-input placeholder="请输入备注" v-model="item.remark"></el-input>
										</div>
										<div class="form_th_list_block active" v-else>{{item.remark?item.remark: '-'}}
										</div>
										<div class="form_th_list_block operat active">
											<div class="c_5bbef0 pointer_" @click="get_resource_btn(item)">
												{{item.is_save?'编辑': '保存'}}</div>
											<div class="c_ff6f6f pointer_ m_l21" @click="get_resource_del(index, item)">
												删除</div>
										</div>
									</div>
								</div>
							</div>
							<div class="form_total">
								<div style="flex: 1;display: flex;flex-wrap: wrap;">
									<div class="form_total" v-if="item.id !== 7" v-for="(item, index) in hv"
										:key="index">
										<p>{{item.name}}*汇率：</p>
										<div class="form_total_input">
											<el-input placeholder="" v-model="item.hl"></el-input>
										</div>
									</div>
								</div>
								<div>
									<div class="form_total">
										<p>合计：</p>
										<div class="form_total_input">
											<el-input placeholder="" v-model="info.total_price"></el-input>
										</div>
										<p>RMB</p>
									</div>
									<p class="c_ff6f6f hj_btn" style="margin-left: 67px;cursor: pointer;"
										@click="get_heji">点击获取合计数据</p>
								</div>
							</div>
							<!-- <div class="form_total">
								<p>合计：</p>
								<div class="form_total_input">
									<el-input placeholder="" v-model="info.total_price" readonly @input="get_total"></el-input>
								</div>
								<p>RMB</p>
							</div> -->
						</div>
					</div>
				</div>
				<div class="form_wrapper_right form_wrapper_block" style="position: relative;">
					<div class="shade_wrapper" v-if="type_"></div>
					<div class="form_choose_wrapper">
						<p class="form_input_label">对账情况：</p>
						<div class="form_choose_content">
							<div class="form_choose_block" :class="{'active': info.reconciliation_id == 1}"
								style="margin-right: 52px;">
								<i @click="info.reconciliation_id = 1"></i>
								<p>已确认</p>
							</div>
							<div class="form_choose_block" :class="{'active': info.reconciliation_id == 0}">
								<i @click="info.reconciliation_id = 0"></i>
								<p>未确认</p>
							</div>
						</div>
					</div>
					<div class="form_choose_wrapper">
						<p class="form_input_label">开票情况：</p>
						<div class="form_choose_content">
							<div class="form_choose_block" :class="{'active': info.make_invoice == 1}"
								style="margin-right: 52px;">
								<i @click="info.make_invoice = 1"></i>
								<p>已开票</p>
							</div>
							<div class="form_choose_block" :class="{'active': info.make_invoice == 0}">
								<i @click="info.make_invoice = 0"></i>
								<p>未开票</p>
							</div>
						</div>
					</div>
					<div class="">
						<div class="flex01 m_b27">
							<p class="form_input_label">结算情况：</p>
							<div>
								<div class="flex01 form_right_add">
									<img src="../../assets/04@2x.png" @click="get_add_jiesuan" />
									<p>新增</p>
								</div>
							</div>
						</div>
						<div class="m_tb15" v-for="(item, index) in info.settlement_text" :key="index">
							<div class="flex01">
								<div class="m_r15" style="flex: 1;">
									<el-date-picker type="date" placeholder="选择日期" value-format="yyyy-MM-dd"
										v-model="item.settlement_date" @change="get_change_settle_date">
									</el-date-picker>
								</div>
								<div class="m_r10" style="flex: 1;">
									<el-input placeholder="" v-model="item.price" @input="get_settlement_input">
									</el-input>
								</div>
								<p class="font_14">RMB</p>
							</div>
						</div>
					</div>
					<div class="form_input_wrapper">
						<p class="form_input_label">应收金额：</p>
						<div class="form_input w_84">
							<el-input placeholder="" v-model="info.receivable" readonly></el-input>
						</div>
						<p class="font_14 m_l10">RMB</p>
					</div>
					<div class="form_input_wrapper">
						<p class="form_input_label">已收金额：</p>
						<div class="form_input w_84">
							<el-input placeholder="" v-model="info.received" readonly></el-input>
						</div>
						<p class="font_14 m_l10">RMB</p>
					</div>
					<div class="form_input_wrapper">
						<p class="form_input_label">待收金额：</p>
						<div class="form_input w_84">
							<el-input placeholder="" v-model="info.collected" readonly></el-input>
						</div>
						<p class="font_14 m_l10">RMB</p>
					</div>
					<div class="form_input_wrapper">
						<p class="form_input_label">客户名称：</p>
						<div class="form_input">
							<v-select :icon_num="true" :list="kehu_list" :index="1" :value_name="kehu_name"
								@result="get_kehu_serch"></v-select>
						</div>
					</div>
					<div class="form_input_wrapper">
						<p class="form_input_label">联系人：</p>
						<div class="form_input">
							<v-select :icon_num="true" :list="lxr_list" :index="2" :value_name="lxr_name"
								@result="get_kehu_serch"></v-select>
						</div>
					</div>
					<div class="form_input_wrapper">
						<p class="form_input_label">联系电话：</p>
						<div class="form_input">
							<el-input placeholder="请填写联系电话" v-model="info.mobile"></el-input>
						</div>
					</div>
					<div class="form_input_wrapper">
						<p class="form_input_label">联系邮箱：</p>
						<div class="form_input">
							<el-input placeholder="请填写联系邮箱" v-model="info.email"></el-input>
						</div>
					</div>
					<div class="form_input_wrapper">
						<p class="form_input_label">结算账期:</p>
						<div class="form_input">
							<el-input v-model="info.payment_days_name" placeholder="请填写联系邮箱" readonly=""></el-input>
							<!-- <v-select :icon_num="true" :list="zhangqi_list" :index="3" :value_name="zhangqi_name" @result="get_kehu_serch"></v-select> -->
						</div>
					</div>
					<div class="form_input_wrapper">
						<p class="form_input_label">结算方式:</p>
						<div class="form_input">
							<v-select :icon_num="true" :list="fangshi_list" :index="4" :value_name="fangshi_name"
								@result="get_kehu_serch"></v-select>
						</div>
					</div>
					<div style="border: 1px solid #486AA5;margin: 29px 0 0;"></div>
					<div class="form_wrapper_info">
						<p class="form_wrapper_info_title">备注:</p>
						<div>
							<el-input type="textarea" placeholder="请填写备注" v-model="info.remark"></el-input>
						</div>
					</div>
				</div>
			</div>
			<div class="form_wrapper" v-if="!type_">
				<div class="form_wrapper_block" style="width: 100%;">
					<div class="form_wrapper_btn_wrapper">
						<div class="btn01" @click="get_save_info">保存</div>
						<div class="btn01" @click="$publicFun.get_link('/settle/pdf?id=' + id, {})">预览</div>
						<div class="btn01" @click="$publicFun.get_link('/settle/pdf?id=' + id, {})">导出</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import crumbs from '../../components/crumbs/index.vue'; // 面包屑
	import select from '../../components/select/index05.vue'; // 可输入搜索框

	import {
		costInfoShow,
		costInfoEdit,
		serviceType,
		services,
		tspMode,
		transport,
		costName,
		currency,
		companyList,
		getAllLinkman,
		payDays,
		payType,
		costInfoDelById,
		companyDetail
	} from '@/api/index';

	export default {
		components: {
			'v-crumbs': crumbs,
			'v-select': select
		},
		data() {
			return {
				id: '',
				type_: false,
				lxr_id: '',
				lxr_name: '',
				lxr_list: [],
				kehu_id: '',
				kehu_name: '',
				kehu_list: [],
				feiyong_all: 0,
				bizhi_list: [],
				feiyong_list: [],
				zhangqi_list: [],
				zhangqi_id: '',
				zhangqi_name: '',
				fangshi_list: [],
				fangshi_id: '',
				fangshi_name: '',
				transport_list: [],
				services_scope_list: [],
				transportation_list: [],
				info: {
					orders: {},
					cargo: [],
					service_type: {},
					ship_company: {},
					circuit: {},
					shipment: {},
					destination: {},
					cost_info: {},
					settlement_text: [],
					remark: '',
				},
				hv: [],
			}
		},
		created() {
			this.id = this.$route.query.id;
			if (this.$route.query.type) {
				this.type_ = true
			}

			this.get_detail();
			this.getServicesList();
			this.getTspModeList();
			this.getTransportList();
			this.getCostNameList();
			this.getCurrencyList();
			this.getCompanyList();
			// this.getPayDays();
			// this.getPayTypeList();
		},
		watch: {
			'info.cost': {
				deep: true,
				handler(val) {
					let list_ = val;
					let ids = [];
					let ids_list = [];

					this.hv = [];

					list_.forEach((n, k) => {
						if (n.money && n.number) {
							n.price = (Number(n.money) * Number(n.number)).toFixed(2);
						} else {
							n.price = '';
						}

						ids.push(n.currency_system_name);
						ids_list.push(n.currency_system);
					})

					let ids_name = [ids[0]]; //结果数组
					let ids_ = [ids_list[0]]; //结果数组
					//从第二项开始遍历
					for (var i = 1; i < ids.length; i++) {
						if (ids.indexOf(ids[i]) == i) ids_name.push(ids[i]);
						if (ids_list.indexOf(ids_list[i]) == i) ids_.push(ids_list[i]);
					}

					ids_name.forEach((n, k) => {
						if (n) {
							this.hv.push({
								name: n,
								hl: '1',
								id: ids_[k]
							})
						}
					})
				}
			}
		},
		methods: {
			// 合计
			get_heji() {
				let array = this.hv;
				let p_ = 0;

				array.forEach((n_, k_) => {
					array[k_].list = [];
					array[k_].price = 0
				})

				this.info.cost.forEach((n, k) => {
					array.forEach((n_, k_) => {
						if (n.currency_system == n_.id) {
							array[k_].list.push(n)
						}
					})
				})

				array.forEach((n_, k_) => {
					if (n_.list.length > 0) {
						n_.list.forEach((n, k) => {
							n_.price = n_.price + Number(n.price)
						})
					}
				})

				array.forEach((n_, k_) => {
					n_.price = ((n_.price.toFixed(2)) * n_.hl).toFixed(2);

					p_ = p_ + Number(n_.price);
				})

				this.info.total_price = p_.toFixed(2);
			},
			// 详情
			get_detail() {
				costInfoShow({
					id: this.id
				}).then(res => {
					let l_ = res.data.orders.orders_rate; // 汇率

					if (res.data.cost.length !== 0) {
						res.data.cost.forEach((n, k) => {
							n.is_save = true
							n.name = n.name_id

							this.feiyong_all += parseFloat(n.price)
						})
					}

					if (res.data.company_id) {
						// 客户名称
						this.kehu_id = res.data.company_id;
						this.kehu_name = res.data.company_id_name;
					}

					if (res.data.linkman_id) {
						// 联系人
						this.lxr_id = res.data.linkman_id;
						this.lxr_name = res.data.linkman_id_name;
					}

					if (res.data.payment_days) {
						// 结算账期
						this.zhangqi_id = res.data.payment_days;
						this.zhangqi_name = res.data.payment_days_name;
					}

					if (res.data.pay_type) {
						// 结算方式
						this.fangshi_id = res.data.pay_type;
						this.fangshi_name = res.data.pay_type_name;
					}

					if (!res.data.settlement_text) {
						res.data.settlement_text = [{
							settlement_date: '',
							price: ''
						}]
					}

					if (res.data.cost.length !== 0) {
						res.data.cost.forEach((n, k) => {
							n.name = n.name_id;
							n.cost_name = {
								name: n.name_id_name
							}

							n.currency_info = {
								name: n.currency_system_name
							}
						})
					}

					setTimeout(() => {
						this.hv = [];
						if (l_.length > 0) {
							l_.forEach((n, k) => {
								this.hv.push({
									name: n.rate_name,
									hl: n.input_id,
									id: n.rate_id
								})
							})
						}
					}, 300)

					this.info = res.data;
					this.get_detail_();
				})
			},
			// 服务范围
			getServicesList() {
				services({
					page: 1,
					limit: 100000
				}).then(res => {
					this.services_scope_list = res.data.data;
				})
			},
			// 运输方式
			getTspModeList() {
				tspMode({
					page: 1,
					limit: 100000
				}).then(res => {
					this.transport_list = res.data.data;
				})
			},
			// 运输流向
			getTransportList() {
				transport({
					page: 1,
					limit: 100000
				}).then(res => {
					this.transportation_list = res.data.data;
				})
			},
			// 费用名称
			getCostNameList() {
				costName({
					page: 1,
					limit: 100000
				}).then(res => {
					res.data.data.forEach((n, k) => {
						n.name = n.name + '/' + n.english_name
					})

					this.feiyong_list = res.data.data;
				})
			},
			// 币制
			getCurrencyList() {
				currency({
					page: 1,
					limit: 100000
				}).then(res => {
					this.bizhi_list = res.data.data;
				})
			},
			// 客户名称
			getCompanyList() {
				companyList({
					page: 1,
					limit: 100000,
					partner_type: 2
				}).then(res => {
					let list_ = res.data.data;

					list_.forEach((n, k) => {
						this.kehu_list.push({
							label: n.company_name,
							value: n.id
						})
					})
				})
			},
			// 根据企业id 获取联系人
			get_linkman_list() {
				getAllLinkman({
					company_id: this.kehu_id
				}).then(res => {
					let list_ = res.data;
					this.lxr_list = [];

					list_.forEach((n, k) => {
						this.lxr_list.push({
							label: n.username,
							value: n.id
						})
					})
				})
			},
			// 结算账期
			getPayDays() {
				payDays({
					page: 1,
					limit: 100000
				}).then(res => {
					let name_ = '';
					let list_ = res.data.data;

					list_.forEach((n, k) => {
						if (n.id == this.zhangqi_id) {
							name_ = n.name
						}

						this.zhangqi_list.push({
							label: n.name,
							value: n.id
						})
					})

					this.info.payment_days_name = name_;
					this.zhangqi_name = name_;
				})
			},
			// 结算方式
			getPayTypeList(ids) {
				payType({
					page: 1,
					limit: 100000
				}).then(res => {
					this.fangshi_list = [];
					let list_ = res.data.data;
					let ids_array = ids.split(',');

					list_.forEach((n, k) => {
						ids_array.forEach((n_, k_) => {
							if (n.id == n_) {
								n.is_show = true;
							}
						})
					})

					list_.forEach((n, k) => {
						if (n.is_show) {
							this.fangshi_list.push({
								label: n.name,
								value: n.id
							})
						}
					})
				})
			},
			// 根据客户名称查结算账期 结算方式
			// 详情数据
			get_detail_() {
				companyDetail({
					id: this.kehu_id
				}).then(res => {
					this.zhangqi_id = res.data.finance.payment_days;

					this.getPayDays();
					this.getPayTypeList(res.data.finance.pay_type);
				})
			},
			get_kehu_serch(e) {
				if (e.index === 1) {
					// 客户名称
					this.kehu_id = e.value;
					this.kehu_name = e.label;
					this.get_linkman_list();
					this.get_detail_();
					this.lxr_id = '';
					this.lxr_name = '';
					this.fangshi_id = '';
					this.fangshi_name = '';
				}

				if (e.index === 2) {
					// 联系人
					this.lxr_id = e.value;
					this.lxr_name = e.label;
				}

				if (e.index === 3) {
					// 结算账期
					this.zhangqi_id = e.value;
					this.zhangqi_name = e.label;
				}

				if (e.index === 4) {
					this.fangshi_id = e.value;
					this.fangshi_name = e.label;
				}
			},
			// 新增费用
			get_add_feiyong() {
				this.info.cost.push({
					is_save: false,
					name: '', // 费用名称
					cost_name: {
						name: ''
					},
					number: '', // 数量
					money: '', // 单价
					currency_system: '', // 币制
					currency_info: {
						name: ''
					},
					remark: '', // 备注
					price: '' // 金额
				})
			},
			get_change(type, e, index) {
				// 费用名称
				if (type === 1) {
					this.info.cost[index].name = e.id;
					this.info.cost[index].cost_name.name = e.name;
				}

				// 币制
				if (type === 2) {
					this.info.cost[index].currency_system = e.id;
					this.info.cost[index].currency_info.name = e.name;
				}
			},
			// 保存/编辑
			get_resource_btn(item) {
				for (let i in item) {
					if (!item[i] && i !== 'is_save' && i !== 'createtime_text' && i !== 'cost_name' && i !==
						'createtime' && i !==
						'updatetime' && i !== 'is_delete') return this.$message('请将内容填写完整');
				}

				item.is_save = !item.is_save;

				if (item.is_save) {
					// 保存 计算金额
					this.feiyong_all = 0;

					this.get_all_price();
				}

				this.$forceUpdate();
			},
			// 删除
			get_resource_del(index, item) {
				if (this.info.cost.length === 1) return this.$message.error('至少保留一条数据');

				this.$confirm('确定删除吗？', '提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(() => {
					if (item.id) {
						costInfoDelById({
							id: item.id
						}).then(res => {
							this.info.cost.splice(index, 1);
							this.get_all_price();
						})
					} else {
						this.info.cost.splice(index, 1);
						this.get_all_price();
					}
				})
			},
			get_all_price() {
				if (this.info.cost.length !== 0) {
					this.feiyong_all = 0;

					this.info.cost.forEach((n, k) => {
						if (n.is_save) {
							this.feiyong_all += parseFloat(n.price)
						}
					})
				}
			},
			// 新增结算
			get_add_jiesuan() {
				this.info.settlement_text.push({
					settlement_date: '', // 日期
					price: '' // 金额
				})
			},
			// 保存
			get_save_info() {
				let settlement_text_list = []; // 结算情况
				let order_cost_list = []; // 费用信息

				if (this.info.settlement_text.length !== 0) {
					this.info.settlement_text.forEach((n, k) => {
						if (n.settlement_date && n.price) {
							settlement_text_list.push(n)
						}
					})
				}

				if (this.info.cost.length !== 0) {
					this.info.cost.forEach((n, k) => {
						if (n.is_save) {
							order_cost_list.push(n)
						}
					})
				}

				let submit_info = {
					id: this.info.id,
					order_id: this.info.orders.id,
					reconciliation_id: this.info.reconciliation_id,
					make_invoice: this.info.make_invoice,
					settlement: settlement_text_list,
					receivable: this.info.receivable,
					received: this.info.received,
					collected: this.info.collected,
					company_id: this.kehu_id,
					company_id_name: this.kehu_name,
					linkman_id: this.lxr_id,
					linkman_id_name: this.lxr_name,
					mobile: this.info.mobile,
					email: this.info.email,
					payment_days: this.zhangqi_id,
					payment_days_name: this.zhangqi_name,
					pay_type: this.fangshi_id,
					pay_type_name: this.fangshi_name,
					cost: order_cost_list,
					total_price: this.info.total_price,
					rate: [],
					remark: this.info.remark
				}

				if (this.hv.length > 0) {

					submit_info.rate = [];

					this.hv.forEach((n, k) => {
						submit_info.rate.push({
							rate_id: n.id,
							rate_name: n.name,
							input_id: n.hl
						})
					})
				}

				console.log(submit_info)

				// 验证
				for (let i in submit_info) {
					if (i !== 'cost' && i !== 'settlement' && i !== 'collected' && i !== 'reconciliation_id' && i !==
						'make_invoice' &&
						!submit_info[i] && i !== 'email' && i !== 'remark') return this.$message('请将内容填写完整');
				}

				// if (settlement_text_list.length === 0) return this.$message('请填写结算情况');
				if (order_cost_list.length === 0) return this.$message('请填写费用信息');

				if (submit_info.collected == 0) {
					this.$alert('代付款项已为零，确认之后订单将会转入已完成订单，不可在编辑。也不可再上传凭证。', '温馨提示', {
						confirmButtonText: '确定',
						callback: action => {
							if (action == 'confirm') {
								this.get_submit(submit_info);
							}
						}
					});
				} else {
					this.get_submit(submit_info);
				}
			},
			get_submit(e) {
				costInfoEdit(e).then(res => {
					this.$message.success('编辑成功！');
					this.$publicFun.get_link('/settle/list', {});
				})
			},
			// 价格计算
			// 输入的总计
			get_total(e) {
				this.info.receivable = e;

				this.info.collected = parseFloat(this.info.receivable) - parseFloat(this.info.received); // 待收金额
			},
			// 选择结算日期
			get_change_settle_date() {
				this.get_p_jisuan()
			},
			get_settlement_input() {
				this.get_p_jisuan()
			},
			get_p_jisuan() {
				let yishou_ = 0;
				let settlement_text_list = []; // 结算情况

				if (this.info.settlement_text.length !== 0) {
					this.info.settlement_text.forEach((n, k) => {
						if (n.settlement_date && n.price) {
							settlement_text_list.push(n)
						}
					})
				}

				if (settlement_text_list.length !== 0) {
					settlement_text_list.forEach((n, k) => {
						yishou_ += parseFloat(n.price)
					})
				}

				this.info.received = yishou_;

				this.info.collected = parseFloat(this.info.receivable) - parseFloat(this.info.received); // 待收金额
			}
		}
	}
</script>

<style lang="less" scoped>
	.info_wrapper {
		padding: 12px 9px;
	}

	/deep/ input::-webkit-input-placeholder,
	/deep/ textarea::-webkit-input-placeholder {
		color: #5B8EF0;
	}

	/deep/ input::-moz-placeholder,
	/deep/ textarea::-webkit-input-placeholder {
		color: #5B8EF0;
	}

	/deep/ input:-moz-placeholder,
	/deep/ textarea::-webkit-input-placeholder {
		color: #5B8EF0;
	}

	/deep/ input:-ms-input-placeholder,
	/deep/ textarea::-webkit-input-placeholder {
		color: #5B8EF0;
	}

	.el-select-dropdown__item.selected {
		color: #606266;
		font-weight: 500;
	}
</style>
