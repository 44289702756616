export default {
	get_country() {
		let list_ = ['中国', '阿尔巴尼亚', '阿尔及利亚', '阿富汗', '阿根廷', '阿拉伯联合酋长国', '阿鲁巴',
			'阿曼', '阿塞拜疆', '阿森松岛', '埃及', '埃塞俄比亚', '爱尔兰', '爱沙尼亚', '安道尔', '安哥拉',
			'安圭拉', '安提瓜岛和巴布达', '奥地利', '奥兰群岛', '澳大利亚', '巴巴多斯岛', '巴布亚新几内亚', '巴哈马',
			'巴基斯坦', '巴拉圭', '巴勒斯坦', '巴林', '巴拿马', '巴西', '白俄罗斯', '百慕大', '保加利亚', '北马里亚纳群岛',
			'贝宁', '比利时', '冰岛', '波多黎各', '波兰', '波斯尼亚和黑塞哥维那', '玻利维亚', '伯利兹', '博茨瓦纳', '不丹',
			'布基纳法索', '布隆迪', '布韦岛', '朝鲜', '丹麦', '德国', '东帝汶', '多哥', '多米尼加', '多米尼加共和国', '俄罗斯',
			'厄瓜多尔', '厄立特里亚', '法国', '法罗群岛', '法属波利尼西亚', '法属圭亚那', '法属南部领地', '梵蒂冈', '菲律宾', '斐济',
			'芬兰', '佛得角', '弗兰克群岛', '冈比亚', '刚果', '刚果民主共和国', '哥伦比亚', '哥斯达黎加', '格恩西岛', '格林纳达', '格陵兰',
			'古巴', '瓜德罗普', '关岛', '圭亚那', '哈萨克斯坦', '海地', '韩国', '荷兰', '荷属安地列斯', '赫德和麦克唐纳群岛', '洪都拉斯', '基里巴斯',
			'吉布提', '吉尔吉斯斯坦', '几内亚', '几内亚比绍', '加拿大', '加纳', '加蓬', '柬埔寨', '捷克共和国', '津巴布韦', '喀麦隆', '卡塔尔',
			'开曼群岛', '科科斯群岛', '科摩罗', '科特迪瓦', '科威特', '克罗地亚', '肯尼亚', '库克群岛', '拉脱维亚', '莱索托', '老挝', '黎巴嫩', '立陶宛', '利比里亚',
			'利比亚', '列支敦士登', '留尼旺岛', '卢森堡', '卢旺达', '罗马尼亚', '马达加斯加', '马尔代夫', '马耳他', '马拉维', '马来西亚', '马里', '马其顿',
			'马绍尔群岛', '马提尼克', '马约特岛', '曼岛', '毛里求斯', '毛里塔尼亚', '美国', '美属萨摩亚', '美属外岛', '蒙古', '蒙特塞拉特', '孟加拉', '秘鲁',
			'密克罗尼西亚', '缅甸', '摩尔多瓦', '摩洛哥', '摩纳哥', '莫桑比克', '墨西哥', '纳米比亚', '南非', '南极洲', '南乔治亚和南桑德威奇群岛', '瑙鲁',
			'尼泊尔', '尼加拉瓜', '尼日尔', '尼日利亚', '纽埃', '挪威', '诺福克', '帕劳群岛', '皮特凯恩', '葡萄牙', '乔治亚', '日本', '瑞典', '瑞士', '萨尔瓦多',
			'萨摩亚', '塞尔维亚,黑山', '塞拉利昂', '塞内加尔', '塞浦路斯', '塞舌尔', '沙特阿拉伯', '圣诞岛', '圣多美和普林西比', '圣赫勒拿', '圣基茨和尼维斯',
			'圣卢西亚', '圣马力诺', '圣皮埃尔和米克隆群岛', '圣文森特和格林纳丁斯', '斯里兰卡', '斯洛伐克', '斯洛文尼亚', '斯瓦尔巴和扬马廷', '斯威士兰', '苏丹',
			'苏里南', '所罗门群岛', '索马里', '塔吉克斯坦', '泰国', '坦桑尼亚', '汤加', '特克斯和凯克特斯群岛', '特里斯坦达昆哈', '特立尼达和多巴哥',
			'突尼斯', '图瓦卢', '土耳其', '土库曼斯坦', '托克劳', '瓦利斯和福图纳', '瓦努阿图', '危地马拉', '维尔京群岛，美属', '维尔京群岛，英属', '委内瑞拉',
			'文莱', '乌干达', '乌克兰', '乌拉圭', '乌兹别克斯坦', '西班牙', '希腊', '新加坡', '新喀里多尼亚', '新西兰', '匈牙利', '叙利亚', '牙买加', '亚美尼亚',
			'也门', '伊拉克', '伊朗', '以色列', '意大利', '印度', '印度尼西亚', '英国', '英属印度洋领地', '约旦', '越南', '赞比亚', '泽西岛', '乍得', '直布罗陀', '智利',
			'中非共和国'
		]

		return list_;
	}
}

// insert into lz_countries(no, title, code) values(1, '中国', 'CHINA');
//  insert into lz_countries(no, title, code) values(2, '阿尔巴尼亚', 'ALB');
//  insert into lz_countries(no, title, code) values(3, '阿尔及利亚', 'DZA');
//  insert into lz_countries(no, title, code) values(4, '阿富汗', 'AFG');
//  insert into lz_countries(no, title, code) values(5, '阿根廷', 'ARG');
//  insert into lz_countries(no, title, code) values(6, '阿拉伯联合酋长国', 'ARE');
//  insert into lz_countries(no, title, code) values(7, '阿鲁巴', 'ABW');
//  insert into lz_countries(no, title, code) values(8, '阿曼', 'OMN');
//  insert into lz_countries(no, title, code) values(9, '阿塞拜疆', 'AZE');
//  insert into lz_countries(no, title, code) values(10, '阿森松岛', 'ASC');
//  insert into lz_countries(no, title, code) values(11, '埃及', 'EGY');
//  insert into lz_countries(no, title, code) values(12, '埃塞俄比亚', 'ETH');
//  insert into lz_countries(no, title, code) values(13, '爱尔兰', 'IRL');
//  insert into lz_countries(no, title, code) values(14, '爱沙尼亚', 'EST');
//  insert into lz_countries(no, title, code) values(15, '安道尔', 'AND');
//  insert into lz_countries(no, title, code) values(16, '安哥拉', 'AGO');
//  insert into lz_countries(no, title, code) values(17, '安圭拉', 'AIA');
//  insert into lz_countries(no, title, code) values(18, '安提瓜岛和巴布达', 'ATG');
//  insert into lz_countries(no, title, code) values(19, '奥地利', 'AUT');
//  insert into lz_countries(no, title, code) values(20, '奥兰群岛', 'ALA');
//  insert into lz_countries(no, title, code) values(21, '澳大利亚', 'AUS');
//  insert into lz_countries(no, title, code) values(22, '巴巴多斯岛', 'BRB');
//  insert into lz_countries(no, title, code) values(23, '巴布亚新几内亚', 'PNG');
//  insert into lz_countries(no, title, code) values(24, '巴哈马', 'BHS');
//  insert into lz_countries(no, title, code) values(25, '巴基斯坦', 'PAK');
//  insert into lz_countries(no, title, code) values(26, '巴拉圭', 'PRY');
//  insert into lz_countries(no, title, code) values(27, '巴勒斯坦', 'PSE');
//  insert into lz_countries(no, title, code) values(28, '巴林', 'BHR');
//  insert into lz_countries(no, title, code) values(29, '巴拿马', 'PAN');
//  insert into lz_countries(no, title, code) values(30, '巴西', 'BRA');
//  insert into lz_countries(no, title, code) values(31, '白俄罗斯', 'BLR');
//  insert into lz_countries(no, title, code) values(32, '百慕大', 'BMU');
//  insert into lz_countries(no, title, code) values(33, '保加利亚', 'BGR');
//  insert into lz_countries(no, title, code) values(34, '北马里亚纳群岛', 'MNP');
//  insert into lz_countries(no, title, code) values(35, '贝宁', 'BEN');
//  insert into lz_countries(no, title, code) values(36, '比利时', 'BEL');
//  insert into lz_countries(no, title, code) values(37, '冰岛', 'ISL');
//  insert into lz_countries(no, title, code) values(38, '波多黎各', 'PRI');
//  insert into lz_countries(no, title, code) values(39, '波兰', 'POL');
//  insert into lz_countries(no, title, code) values(40, '波斯尼亚和黑塞哥维那', 'BIH');
//  insert into lz_countries(no, title, code) values(41, '玻利维亚', 'BOL');
//  insert into lz_countries(no, title, code) values(42, '伯利兹', 'BLZ');
//  insert into lz_countries(no, title, code) values(43, '博茨瓦纳', 'BWA');
//  insert into lz_countries(no, title, code) values(44, '不丹', 'BTN');
//  insert into lz_countries(no, title, code) values(45, '布基纳法索', 'BFA');
//  insert into lz_countries(no, title, code) values(46, '布隆迪', 'BDI');
//  insert into lz_countries(no, title, code) values(47, '布韦岛', 'BVT');
//  insert into lz_countries(no, title, code) values(48, '朝鲜', 'PRK');
//  insert into lz_countries(no, title, code) values(49, '丹麦', 'DNK');
//  insert into lz_countries(no, title, code) values(50, '德国', 'DEU');
//  insert into lz_countries(no, title, code) values(51, '东帝汶', 'TLS');
//  insert into lz_countries(no, title, code) values(52, '多哥', 'TGO');
//  insert into lz_countries(no, title, code) values(53, '多米尼加', 'DMA');
//  insert into lz_countries(no, title, code) values(54, '多米尼加共和国', 'DOM');
//  insert into lz_countries(no, title, code) values(55, '俄罗斯', 'RUS');
//  insert into lz_countries(no, title, code) values(56, '厄瓜多尔', 'ECU');
//  insert into lz_countries(no, title, code) values(57, '厄立特里亚', 'ERI');
//  insert into lz_countries(no, title, code) values(58, '法国', 'FRA');
//  insert into lz_countries(no, title, code) values(59, '法罗群岛', 'FRO');
//  insert into lz_countries(no, title, code) values(60, '法属波利尼西亚', 'PYF');
//  insert into lz_countries(no, title, code) values(61, '法属圭亚那', 'GUF');
//  insert into lz_countries(no, title, code) values(62, '法属南部领地', 'ATF');
//  insert into lz_countries(no, title, code) values(63, '梵蒂冈', 'VAT');
//  insert into lz_countries(no, title, code) values(64, '菲律宾', 'PHL');
//  insert into lz_countries(no, title, code) values(65, '斐济', 'FJI');
//  insert into lz_countries(no, title, code) values(66, '芬兰', 'FIN');
//  insert into lz_countries(no, title, code) values(67, '佛得角', 'CPV');
//  insert into lz_countries(no, title, code) values(68, '弗兰克群岛', 'FLK');
//  insert into lz_countries(no, title, code) values(69, '冈比亚', 'GMB');
//  insert into lz_countries(no, title, code) values(70, '刚果', 'COG');
//  insert into lz_countries(no, title, code) values(71, '刚果民主共和国', 'COD');
//  insert into lz_countries(no, title, code) values(72, '哥伦比亚', 'COL');
//  insert into lz_countries(no, title, code) values(73, '哥斯达黎加', 'CRI');
//  insert into lz_countries(no, title, code) values(74, '格恩西岛', 'GGY');
//  insert into lz_countries(no, title, code) values(75, '格林纳达', 'GRD');
//  insert into lz_countries(no, title, code) values(76, '格陵兰', 'GRL');
//  insert into lz_countries(no, title, code) values(77, '古巴', 'CUB');
//  insert into lz_countries(no, title, code) values(78, '瓜德罗普', 'GLP');
//  insert into lz_countries(no, title, code) values(79, '关岛', 'GUM');
//  insert into lz_countries(no, title, code) values(80, '圭亚那', 'GUY');
//  insert into lz_countries(no, title, code) values(81, '哈萨克斯坦', 'KAZ');
//  insert into lz_countries(no, title, code) values(82, '海地', 'HTI');
//  insert into lz_countries(no, title, code) values(83, '韩国', 'KOR');
//  insert into lz_countries(no, title, code) values(84, '荷兰', 'NLD');
//  insert into lz_countries(no, title, code) values(85, '荷属安地列斯', 'ANT');
//  insert into lz_countries(no, title, code) values(86, '赫德和麦克唐纳群岛', 'HMD');
//  insert into lz_countries(no, title, code) values(87, '洪都拉斯', 'HND');
//  insert into lz_countries(no, title, code) values(88, '基里巴斯', 'KIR');
//  insert into lz_countries(no, title, code) values(89, '吉布提', 'DJI');
//  insert into lz_countries(no, title, code) values(90, '吉尔吉斯斯坦', 'KGZ');
//  insert into lz_countries(no, title, code) values(91, '几内亚', 'GIN');
//  insert into lz_countries(no, title, code) values(92, '几内亚比绍', 'GNB');
//  insert into lz_countries(no, title, code) values(93, '加拿大', 'CAN');
//  insert into lz_countries(no, title, code) values(94, '加纳', 'GHA');
//  insert into lz_countries(no, title, code) values(95, '加蓬', 'GAB');
//  insert into lz_countries(no, title, code) values(96, '柬埔寨', 'KHM');
//  insert into lz_countries(no, title, code) values(97, '捷克共和国', 'CZE');
//  insert into lz_countries(no, title, code) values(98, '津巴布韦', 'ZWE');
//  insert into lz_countries(no, title, code) values(99, '喀麦隆', 'CMR');
//  insert into lz_countries(no, title, code) values(100, '卡塔尔', 'QAT');
//  insert into lz_countries(no, title, code) values(101, '开曼群岛', 'CYM');
//  insert into lz_countries(no, title, code) values(102, '科科斯群岛', 'CCK');
//  insert into lz_countries(no, title, code) values(103, '科摩罗', 'COM');
//  insert into lz_countries(no, title, code) values(104, '科特迪瓦', 'CIV');
//  insert into lz_countries(no, title, code) values(105, '科威特', 'KWT');
//  insert into lz_countries(no, title, code) values(106, '克罗地亚', 'HRV');
//  insert into lz_countries(no, title, code) values(107, '肯尼亚', 'KEN');
//  insert into lz_countries(no, title, code) values(108, '库克群岛', 'COK');
//  insert into lz_countries(no, title, code) values(109, '拉脱维亚', 'LVA');
//  insert into lz_countries(no, title, code) values(110, '莱索托', 'LSO');
//  insert into lz_countries(no, title, code) values(111, '老挝', 'LAO');
//  insert into lz_countries(no, title, code) values(112, '黎巴嫩', 'LBN');
//  insert into lz_countries(no, title, code) values(113, '立陶宛', 'LTU');
//  insert into lz_countries(no, title, code) values(114, '利比里亚', 'LBR');
//  insert into lz_countries(no, title, code) values(115, '利比亚', 'LBY');
//  insert into lz_countries(no, title, code) values(116, '列支敦士登', 'LIE');
//  insert into lz_countries(no, title, code) values(117, '留尼旺岛', 'REU');
//  insert into lz_countries(no, title, code) values(118, '卢森堡', 'LUX');
//  insert into lz_countries(no, title, code) values(119, '卢旺达', 'RWA');
//  insert into lz_countries(no, title, code) values(120, '罗马尼亚', 'ROU');
//  insert into lz_countries(no, title, code) values(121, '马达加斯加', 'MDG');
//  insert into lz_countries(no, title, code) values(122, '马尔代夫', 'MDV');
//  insert into lz_countries(no, title, code) values(123, '马耳他', 'MLT');
//  insert into lz_countries(no, title, code) values(124, '马拉维', 'MWI');
//  insert into lz_countries(no, title, code) values(125, '马来西亚', 'MYS');
//  insert into lz_countries(no, title, code) values(126, '马里', 'MLI');
//  insert into lz_countries(no, title, code) values(127, '马其顿', 'MKD');
//  insert into lz_countries(no, title, code) values(128, '马绍尔群岛', 'MHL');
//  insert into lz_countries(no, title, code) values(129, '马提尼克', 'MTQ');
//  insert into lz_countries(no, title, code) values(130, '马约特岛', 'MYT');
//  insert into lz_countries(no, title, code) values(131, '曼岛', 'IMN');
//  insert into lz_countries(no, title, code) values(132, '毛里求斯', 'MUS');
//  insert into lz_countries(no, title, code) values(133, '毛里塔尼亚', 'MRT');
//  insert into lz_countries(no, title, code) values(134, '美国', 'USA');
//  insert into lz_countries(no, title, code) values(135, '美属萨摩亚', 'ASM');
//  insert into lz_countries(no, title, code) values(136, '美属外岛', 'UMI');
//  insert into lz_countries(no, title, code) values(137, '蒙古', 'MNG');
//  insert into lz_countries(no, title, code) values(138, '蒙特塞拉特', 'MSR');
//  insert into lz_countries(no, title, code) values(139, '孟加拉', 'BGD');
//  insert into lz_countries(no, title, code) values(140, '秘鲁', 'PER');
//  insert into lz_countries(no, title, code) values(141, '密克罗尼西亚', 'FSM');
//  insert into lz_countries(no, title, code) values(142, '缅甸', 'MMR');
//  insert into lz_countries(no, title, code) values(143, '摩尔多瓦', 'MDA');
//  insert into lz_countries(no, title, code) values(144, '摩洛哥', 'MAR');
//  insert into lz_countries(no, title, code) values(145, '摩纳哥', 'MCO');
//  insert into lz_countries(no, title, code) values(146, '莫桑比克', 'MOZ');
//  insert into lz_countries(no, title, code) values(147, '墨西哥', 'MEX');
//  insert into lz_countries(no, title, code) values(148, '纳米比亚', 'NAM');
//  insert into lz_countries(no, title, code) values(149, '南非', 'ZAF');
//  insert into lz_countries(no, title, code) values(150, '南极洲', 'ATA');
//  insert into lz_countries(no, title, code) values(151, '南乔治亚和南桑德威奇群岛', 'SGS');
//  insert into lz_countries(no, title, code) values(152, '瑙鲁', 'NRU');
//  insert into lz_countries(no, title, code) values(153, '尼泊尔', 'NPL');
//  insert into lz_countries(no, title, code) values(154, '尼加拉瓜', 'NIC');
//  insert into lz_countries(no, title, code) values(155, '尼日尔', 'NER');
//  insert into lz_countries(no, title, code) values(156, '尼日利亚', 'NGA');
//  insert into lz_countries(no, title, code) values(157, '纽埃', 'NIU');
//  insert into lz_countries(no, title, code) values(158, '挪威', 'NOR');
//  insert into lz_countries(no, title, code) values(159, '诺福克', 'NFK');
//  insert into lz_countries(no, title, code) values(160, '帕劳群岛', 'PLW');
//  insert into lz_countries(no, title, code) values(161, '皮特凯恩', 'PCN');
//  insert into lz_countries(no, title, code) values(162, '葡萄牙', 'PRT');
//  insert into lz_countries(no, title, code) values(163, '乔治亚', 'GEO');
//  insert into lz_countries(no, title, code) values(164, '日本', 'JPN');
//  insert into lz_countries(no, title, code) values(165, '瑞典', 'SWE');
//  insert into lz_countries(no, title, code) values(166, '瑞士', 'CHE');
//  insert into lz_countries(no, title, code) values(167, '萨尔瓦多', 'SLV');
//  insert into lz_countries(no, title, code) values(168, '萨摩亚', 'WSM');
//  insert into lz_countries(no, title, code) values(169, '塞尔维亚,黑山', 'SCG');
//  insert into lz_countries(no, title, code) values(170, '塞拉利昂', 'SLE');
//  insert into lz_countries(no, title, code) values(171, '塞内加尔', 'SEN');
//  insert into lz_countries(no, title, code) values(172, '塞浦路斯', 'CYP');
//  insert into lz_countries(no, title, code) values(173, '塞舌尔', 'SYC');
//  insert into lz_countries(no, title, code) values(174, '沙特阿拉伯', 'SAU');
//  insert into lz_countries(no, title, code) values(175, '圣诞岛', 'CXR');
//  insert into lz_countries(no, title, code) values(176, '圣多美和普林西比', 'STP');
//  insert into lz_countries(no, title, code) values(177, '圣赫勒拿', 'SHN');
//  insert into lz_countries(no, title, code) values(178, '圣基茨和尼维斯', 'KNA');
//  insert into lz_countries(no, title, code) values(179, '圣卢西亚', 'LCA');
//  insert into lz_countries(no, title, code) values(180, '圣马力诺', 'SMR');
//  insert into lz_countries(no, title, code) values(181, '圣皮埃尔和米克隆群岛', 'SPM');
//  insert into lz_countries(no, title, code) values(182, '圣文森特和格林纳丁斯', 'VCT');
//  insert into lz_countries(no, title, code) values(183, '斯里兰卡', 'LKA');
//  insert into lz_countries(no, title, code) values(184, '斯洛伐克', 'SVK');
//  insert into lz_countries(no, title, code) values(185, '斯洛文尼亚', 'SVN');
//  insert into lz_countries(no, title, code) values(186, '斯瓦尔巴和扬马廷', 'SJM');
//  insert into lz_countries(no, title, code) values(187, '斯威士兰', 'SWZ');
//  insert into lz_countries(no, title, code) values(188, '苏丹', 'SDN');
//  insert into lz_countries(no, title, code) values(189, '苏里南', 'SUR');
//  insert into lz_countries(no, title, code) values(190, '所罗门群岛', 'SLB');
//  insert into lz_countries(no, title, code) values(191, '索马里', 'SOM');
//  insert into lz_countries(no, title, code) values(192, '塔吉克斯坦', 'TJK');
//  insert into lz_countries(no, title, code) values(193, '泰国', 'THA');
//  insert into lz_countries(no, title, code) values(194, '坦桑尼亚', 'TZA');
//  insert into lz_countries(no, title, code) values(195, '汤加', 'TON');
//  insert into lz_countries(no, title, code) values(196, '特克斯和凯克特斯群岛', 'TCA');
//  insert into lz_countries(no, title, code) values(197, '特里斯坦达昆哈', 'TAA');
//  insert into lz_countries(no, title, code) values(198, '特立尼达和多巴哥', 'TTO');
//  insert into lz_countries(no, title, code) values(199, '突尼斯', 'TUN');
//  insert into lz_countries(no, title, code) values(200, '图瓦卢', 'TUV');
//  insert into lz_countries(no, title, code) values(201, '土耳其', 'TUR');
//  insert into lz_countries(no, title, code) values(202, '土库曼斯坦', 'TKM');
//  insert into lz_countries(no, title, code) values(203, '托克劳', 'TKL');
//  insert into lz_countries(no, title, code) values(204, '瓦利斯和福图纳', 'WLF');
//  insert into lz_countries(no, title, code) values(205, '瓦努阿图', 'VUT');
//  insert into lz_countries(no, title, code) values(206, '危地马拉', 'GTM');
//  insert into lz_countries(no, title, code) values(207, '维尔京群岛，美属', 'VIR');
//  insert into lz_countries(no, title, code) values(208, '维尔京群岛，英属', 'VGB');
//  insert into lz_countries(no, title, code) values(209, '委内瑞拉', 'VEN');
//  insert into lz_countries(no, title, code) values(210, '文莱', 'BRN');
//  insert into lz_countries(no, title, code) values(211, '乌干达', 'UGA');
//  insert into lz_countries(no, title, code) values(212, '乌克兰', 'UKR');
//  insert into lz_countries(no, title, code) values(213, '乌拉圭', 'URY');
//  insert into lz_countries(no, title, code) values(214, '乌兹别克斯坦', 'UZB');
//  insert into lz_countries(no, title, code) values(215, '西班牙', 'ESP');
//  insert into lz_countries(no, title, code) values(216, '希腊', 'GRC');
//  insert into lz_countries(no, title, code) values(217, '新加坡', 'SGP');
//  insert into lz_countries(no, title, code) values(218, '新喀里多尼亚', 'NCL');
//  insert into lz_countries(no, title, code) values(219, '新西兰', 'NZL');
//  insert into lz_countries(no, title, code) values(220, '匈牙利', 'HUN');
//  insert into lz_countries(no, title, code) values(221, '叙利亚', 'SYR');
//  insert into lz_countries(no, title, code) values(222, '牙买加', 'JAM');
//  insert into lz_countries(no, title, code) values(223, '亚美尼亚', 'ARM');
//  insert into lz_countries(no, title, code) values(224, '也门', 'YEM');
//  insert into lz_countries(no, title, code) values(225, '伊拉克', 'IRQ');
//  insert into lz_countries(no, title, code) values(226, '伊朗', 'IRN');
//  insert into lz_countries(no, title, code) values(227, '以色列', 'ISR');
//  insert into lz_countries(no, title, code) values(228, '意大利', 'ITA');
//  insert into lz_countries(no, title, code) values(229, '印度', 'IND');
//  insert into lz_countries(no, title, code) values(230, '印度尼西亚', 'IDN');
//  insert into lz_countries(no, title, code) values(231, '英国', 'GBR');
//  insert into lz_countries(no, title, code) values(232, '英属印度洋领地', 'IOT');
//  insert into lz_countries(no, title, code) values(233, '约旦', 'JOR');
//  insert into lz_countries(no, title, code) values(234, '越南', 'VNM');
//  insert into lz_countries(no, title, code) values(235, '赞比亚', 'ZMB');
//  insert into lz_countries(no, title, code) values(236, '泽西岛', 'JEY');
//  insert into lz_countries(no, title, code) values(237, '乍得', 'TCD');
//  insert into lz_countries(no, title, code) values(238, '直布罗陀', 'GIB');
//  insert into lz_countries(no, title, code) values(239, '智利', 'CHL');
//  insert into lz_countries(no, title, code) values(240, '中非共和国', 'CAF');
