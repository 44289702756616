<template>
	<div class="wx-modal_wrapper">
		<el-dialog title="" custom-class="dialog_wrapper" :visible.sync="dialogVisible" :show-close="false" width="26%">
			<div class="wx-modal_top">
				<!-- <p class="font_16">财务审批--{{financeData.source_id}}--{{financeData.type_id}}--{{financeData.remark}}</p> -->
        <p class="font_16">财务审批</p>
				<i @click="get_close"></i>
			</div>
			
      <div class="boxs">
        <el-input placeholder="请输入" class="text" v-model="financeData.remark" v-if="financeData.op_type == 0"></el-input>
        <!-- <el-checkbox v-model="checked" style="margin-top:16px">备选项</el-checkbox> -->
        <el-button type="primary" class="btn" @click="get_save">保存</el-button>
      </div>
      
		</el-dialog>
	</div>
</template>

<script>
	import {approvalAddApi,updateStatusApi} from '@/api/index';

	export default {
		props: {
			dialogVisible: {
				type: Boolean
			},
			financeData: {
				type: Object
			},
      
		},
		data() {
			return {
				checked:false
			}
		},
		created() {
			
		},
		methods: {
			// 关闭
			get_close() {
				this.$emit('getModalClose', false);
			},
			// 保存
			get_save() {
        let data = {
          type_id:this.financeData.type_id,
          source_id:this.financeData.source_id,
          remark:this.financeData.remark
        }
        approvalAddApi(data).then(res=>{
          this.$emit('getSubmit', this.financeData);
          this.$message({
            message: '操作成功',
            type: 'success'
          });
        })
			}
		}
	}
</script>

<style lang="less">
 .boxs{
   padding: 30px;
   .btn{margin: 20px auto 0;display: block;}
 }

	.wx-modal_top {
		height: 60px;
		display: flex;
		position: relative;
		align-items: center;
		justify-content: center;
		border-bottom: 1px solid #D0D0D0;

		i {
			top: 17px;
			right: 20px;
			display: block;
			width: 26px;
			height: 26px;
			cursor: pointer;
			position: absolute;
			background: url(../../assets/wx/05@2x.png) no-repeat center;
			background-size: 100% 100%;
		}
	}

	.close_icon {
		top: -10px;
		right: -10px;
		width: 20px;
		height: 20px;
		display: block;
		position: absolute;
		cursor: pointer;
		background: url(../../assets/wx/07.png) no-repeat center;
		background-size: 100% 100%;
	}
</style>
