<template>
	<div>
		<div class="a4_list mxy" v-if="is_load">
			<div class="a4_btn_wrapper">
				<div class="btn01 a4_btn" @click="clickGeneratePicture();setData();">导出</div>
				<div class="a4_btn flex03 right" v-if="pages > 1">
					<p class="btn01" @click="get_btn('up')">上一页</p>
					<p class="btn01" style="margin-left: 20px;" @click="get_btn('down')">下一页</p>
				</div>
			</div>
			<div style="overflow-x: auto;">
				<div class="a4" ref="imageDom">
					<div class="a4_mxy_top">
						<div class="pages_class c_888">第{{now_page}}页 / 共{{pages}}页</div>
						<p class="a4_title">{{$publicFun.get_LostName().nameText}}</p>
						<div class="a4_mxy_title">
							<div class="a4_top">
								<p><template v-if="type == 1">客户名称</template><template v-else>供应商名称</template>: {{detailInfo.company_info.company_name}}</p>
								<div>对账单周期: {{last_date}}</div>
								<div class="flex01">
									<p>制表人: {{detailInfo.makeUser}}</p>
									<p style="margin-left: 20px;">制表日期: {{now_date}}</p>
								</div>
							</div>
						</div>
					</div>
					<div class="collect_content">
						<div class="a4_table_block title_">
							<p>应<template v-if="type == 1">收</template><template v-else>付</template>结算单编号</p>
							<p>服务类型</p>
							<p>运单编号</p>
							<p>起运时间</p>
							<p>起运地/起运港</p>
							<p>目的地/目的港</p>
							<p>件数</p>
							<p>计重</p>
							<p>应<template v-if="type == 1">收</template><template v-else>付</template>金额合计</p>
							<p>备注</p>
						</div>
						<div class="a4_table_content">
							<div class="a4_table_block table_content" v-for="(item, index) in content_list" :key="index">
								<p v-if="type == 1">{{item.receivable_no}}</p>
                <p v-else>{{item.handle_no}}</p>
								<p>{{item.service_type_id_name}}</p>
								<p>{{item.waybill_no}}</p>
								<p>{{item.shipment_date_text}}</p>
								<p>{{item.shipment_id_name}}</p>
								<p>{{item.destination_id_name}}</p>
								<p>{{item.number}}</p>
								<p>{{item.weight}}</p>
								<p>{{item.total_price}}</p>
								<p>{{item.remark}}</p>
							</div>
						</div>
					</div>
					<div class="make_price flex02">
						<div>本页票数：{{content_list.length}}</div>
						<div>本页金额合计：RMB {{total_price}}</div>
						<div>应<template v-if="type == 1">收</template><template v-else>付</template>金额合计：RMB {{total_price}}</div>
					</div>
					<div class="make_content2">
						<div class="make_content2_center">
							<div>
								<p class="a4_explain_title"><template v-if="type == 1">我司公账</template><template v-else>贵公司</template>付款信息如下：</p>
								<div class="a4_explain_table" v-if="type == 1">
									<p>{{$publicFun.get_LostName().companyInfo.name}}</p>
									<p>（1）开户银行名称：{{$publicFun.get_LostName().companyInfo.bank}}</p>
									<p><span style="color: #fff;">（1）</span>账户：{{$publicFun.get_LostName().companyInfo.bankAaccount}}</p>
									<p v-if="$publicFun.get_LostName().companyInfo.bank1">（2）开户银行名称：{{$publicFun.get_LostName().companyInfo.bank1}}</p>
									<p v-if="$publicFun.get_LostName().companyInfo.bankAaccount1"><span style="color: #fff;">（1）</span>账户：{{$publicFun.get_LostName().companyInfo.bankAaccount1}}</p>
								</div>
                <div class="a4_explain_table" v-else="type == 1">
                	<p>{{detailInfo.company_info.company_name}}</p>
                	<p>（1）开户银行名称：{{detailInfo.company_info.finance.bank_name}}</p>
                	<p><span style="color: #fff;">（1）</span>账户：{{detailInfo.company_info.finance.bank_number}}</p>
                </div>
              </div>
							<div>
								<p class="a4_explain_title">
                  <template v-if="type == 1">贵</template><template v-else>我</template>司开票信息如下<template v-if="type == 1">，如有变更请及时通知我司</template>：
                </p>
                <div class="a4_explain_table" v-if="type == 1">
                	<div class="a4_explain_table_list">
                		<p>开票抬头</p>
                		<p>:</p>
                		<p>{{detailInfo.company_info.company_name}}</p>
                	</div>
                	<div class="a4_explain_table_list">
                		<p>公司地址</p>
                		<p>:</p>
                		<p>{{detailInfo.company_info.register_address}}</p>
                	</div>
                	<div class="a4_explain_table_list">
                		<p>电话</p>
                		<p>:</p>
                		<p>{{detailInfo.company_info.mobile}}</p>
                	</div>
                	<div class="a4_explain_table_list">
                		<p>税务登记号</p>
                		<p>:</p>
                		<p>{{detailInfo.company_info.credit_code}}</p>
                	</div>
                	<div class="a4_explain_table_list">
                		<p>开户银行名称</p>
                		<p>:</p>
                		<p>{{detailInfo.company_info.finance.bank_name}}</p>
                	</div>
                	<div class="a4_explain_table_list">
                		<p>开户银行账户</p>
                		<p>:</p>
                		<p>{{detailInfo.company_info.finance.bank_number}}</p>
                	</div>
                </div>
                
								<div class="a4_explain_table" v-else>
									<div class="a4_explain_table_list">
										<p>开票抬头</p>
										<p>:</p>
										<p>{{$publicFun.get_LostName().companyInfo.name}}</p>
									</div>
									<div class="a4_explain_table_list">
										<p>公司地址</p>
										<p>:</p>
										<p>{{$publicFun.get_LostName().companyInfo.address}}</p>
									</div>
									<div class="a4_explain_table_list">
										<p>电话</p>
										<p>:</p>
										<p>{{$publicFun.get_LostName().companyInfo.phone}}</p>
									</div>
									<div class="a4_explain_table_list">
										<p>税务登记号</p>
										<p>:</p>
										<p>{{$publicFun.get_LostName().companyInfo.num}}</p>
									</div>
									<div class="a4_explain_table_list">
										<p>开户银行名称</p>
										<p>:</p>
										<p>{{$publicFun.get_LostName().companyInfo.bank}}</p>
									</div>
									<div class="a4_explain_table_list">
										<p>开户银行账户</p>
										<p>:</p>
										<p>{{$publicFun.get_LostName().companyInfo.bankAllAaccount}}</p>
									</div>
								</div>
                
              </div>
							<div v-if="$publicFun.get_LostName().wxImg">
								<img :src="$publicFun.get_LostName().wxImg" />
							</div>
						</div>
						<div class="make_content2_title flex04" v-if="type == 1">请您于7日内核对确认，如有问题请联系业务负责人或拨打010-69467872，默认开具增值税电子普通发票，谢谢合作！</div>
					</div>
				</div>

			</div>
		</div>
	</div>
</template>

<script>
	import {
		getItemsData,
		setItemsData,
    getCopeWithApi,
    exportCopeWithPdfApi
    
	} from '@/api/index';

	import html2canvas from "html2canvas";

	export default {
		data() {
			return {
				ids: '',
				pages: 1,
				is_load: false,
				now_page: 1,
				now_date: '',
				total_price: '',
				content_list: [],
				last_date: '',
				detailInfo: {
					company_info: {},
					data_list: [],
					array: []
				},
        type:1, //1-应收结算列表，2-应付结算列表
			}
		},
		created() {
			this.now_date = this.getNowDate();
      this.type = this.$route.query.type || 1;
			this.ids = this.$route.query.id;
			this.get_int();
		},
		methods: {
			// 详情数据
			get_detail() {
        let url = this.type == 2 ? getCopeWithApi : getItemsData
				url({
					sid: this.ids
				}).then(res => {
					this.is_load = true;
					res.data.array = [];
					this.detailInfo = res.data;
					if (res.data.data_list.length > 2) {
						let chunk = 20; // 每6个分一组
						for (var i = 0, j = res.data.data_list.length; i < j; i += chunk) {
							this.detailInfo.array.push(res.data.data_list.slice(i, i + chunk));
						}
						this.get_allPrce();

						this.pages = this.detailInfo.array.length;
					} else {
						this.content_list = this.detailInfo.data_list;
						this.total_price = res.data.total_price;
					}
				})
			},
			get_allPrce() {
				this.total_price = 0;
				this.content_list = this.detailInfo.array[this.now_page - 1];
				this.content_list.forEach((n, k) => {
					this.total_price += Number(n.total_price)
				})
				this.total_price = this.total_price.toFixed(2);
			},
			// 初始化
			get_int() {
				this.get_detail();
			},
			// 日期
			getNowDate() {
				var date = new Date();
				var seperator1 = "/";
				var seperator2 = "-";
				var year = date.getFullYear();
				var month = date.getMonth() + 1;
				var strDate = date.getDate();
				if (month >= 1 && month <= 9) {
					month = "0" + month;
				}
				if (strDate >= 0 && strDate <= 9) {
					strDate = "0" + strDate;
				}
				var currentdate = year + seperator1 + month + seperator1 + strDate;
				var currentdate1 = year + seperator2 + month + seperator2 + strDate;
				this.last_date = this.getPreMonth(currentdate1);
				return currentdate;
			},
			// 周期
			getPreMonth(date) {
				var arr = date.split('-');
				var year = arr[0]; // 获取当前日期的年份  
				var month = arr[1]; // 获取当前日期的月份  
				var day = arr[2]; // 获取当前日期的日  

				var year2 = year;
				var month2 = parseInt(month) - 1;
				if (month2 == 0) {
					year2 = parseInt(year2) - 1;
					month2 = 12;
				}

				var days2 = new Date(year2, month2, 0);
				days2 = days2.getDate();

				var t2 = year2 + '/' + month2 + '/01 —— ' + year2 + '/' + month2 + '/' + days2;
				return t2;
			},
			// 分页
			get_btn(e) {
				if (e === 'up') {
					// 上
					if (this.now_page === 1) return this.$message('没有上一个了！');

					this.now_page--;
					this.get_allPrce();
				}

				if (e === 'down') {
					// 下
					if (this.now_page === this.pages) return this.$message('没有下一个了！');

					this.now_page++;
					this.get_allPrce();
				}
			},
			setData() {
        let url = this.type == 2 ? exportCopeWithPdfApi : setItemsData
				url({
					sid: this.ids
				}).then(res => {
					if (res.code == 200) {
						this.$publicFun.get_link('/settle/list', {});
					}
				})
			},
			clickGeneratePicture() {
				html2canvas(this.$refs.imageDom, {
					async: true
				}).then(canvas => {
					// 转成图片，生成图片地址
					this.imgUrl = canvas.toDataURL("image/png"); //可将 canvas 转为 base64 格式
					console.log(this.imgUrl)
					let link = document.createElement('a');
					link.href = this.imgUrl;
					link.setAttribute('download', this.detailInfo.company_info.company_name + '预览汇总账单第' + this.now_page + '页');
					link.style.display = 'none';
					document.body.appendChild(link);
					link.click();
				});
			}
		}
	}
</script>

<style lang="less">
	.mxy {
		.a4 {
			width: 1300px;
			padding: 0;
		}

		.a4_mxy_top {
			padding: 0 20px;
			overflow: hidden;
			position: relative;

			.pages_class {
				top: 30px;
				right: 20px;
				font-size: 12px;
				position: absolute;
			}
		}

		.a4_title {
			font-size: 24px;
			margin: 16px 0;
		}

		.a4_top {
			padding: 0;
			font-weight: bold;
			font-size: 15px;
			line-height: 28px;
		}

		.title_ p {
			padding-bottom: 10px;
		}

		.a4_mxy_title,
		.a4_top p {
			font-size: 15px;
			line-height: 28px;
		}

		.a4_table_block.table_content {
			font-size: 14px;
		}

		.collect_content {
			padding: 0 10px;
			margin: 5px 0 0 0;
		}

		.a4_table_block {
			padding: 0 10px;
			font-size: 14px;
		}

		.a4_table_block p:first-child {
			flex: 1.3;
		}

		.a4_table_block p:nth-child(2) {
			flex: 1;
		}

		.a4_table_block p:nth-child(3) {
			flex: 3.2;
			word-break: break-all;
		}

		.a4_table_block p:nth-child(4) {
			flex: 1;
		}

		.a4_table_block p:nth-child(5),
		.a4_table_block p:nth-child(6) {
			flex: 1.7;
			text-align: left;
		}

		.a4_table_block p:nth-child(7) {
			flex: 0.4;
		}

		.a4_table_block p:nth-child(8) {
			flex: 1;
		}

		.a4_table_block p:nth-child(7),
		.a4_table_block p:last-child {
			text-align: right;
		}

		.a4_table_block p:last-child {
			flex: 1;
		}

		.make_price {
			font-size: 15px;
			font-weight: bold;
			margin: 0 10px;
			padding: 0 10px 10px 10px;
			border-bottom: 1px dashed #333;
		}

		.make_content {
			margin: 0 10px;
			padding: 0 10px;
			position: relative;
			border-bottom: 1px dashed #333;
		}

		.make_content2 {
			margin: 0 10px;
			padding: 10px;

			.make_content2_title {
				font-size: 14px;
				line-height: 20px;
			}

			.make_content2_center {
				display: flex;
				justify-content: space-between;

				img {
					height: 180px;
				}
			}
		}

		.a4_explain_table {
			border: none;
			margin-right: 0;
			padding: 6px 0;
		}

		.a4_explain_table_list p:first-child {
			width: 100px;
		}

		.a4_table_content {
			padding: 5px 0;
			min-height: 580px;
		}
	}
</style>
