<template>
	<div class="select_wrapper01">
		<el-select v-model="value_content" :placeholder="placeText" @change="get_change">
			<el-option v-for="item in list" :key="item.id" :label="item.name" :value="item"></el-option>
		</el-select>
		<div class="icon flex04">
			<img src="../../assets/01.png" />
		</div>
	</div>
</template>

<script>
	export default {
		props: {
			placeText: {
				type: String
			},
			list: {
				type: Array
			},
			index: {
				type: Number
			},
			value_content: {
				type: String
			}
		},
		data() {
			return {
				value: '',
			}
		},
		methods: {
			get_change(e) {
				if (this.index != undefined) {
					e.index = this.index;
				}
				
				this.value_content = e.name;

				this.$emit('get_search_value', e);
			}
		}
	}
</script>

<style lang="less" scoped>
	.select_wrapper01 {
		position: relative;

		/deep/ .el-select {
			width: 100%;
		}

		/deep/ .el-input__inner {
			border-radius: 10px;
			padding-left: 17px;
			font-size: 12px;
		}

		.icon {
			top: 0;
			right: 0;
			width: 28px;
			bottom: 0;
			position: absolute;
			background-color: #5B8EF0;
			border-radius: 0 10px 10px 0;

			img {
				width: 10px;
			}
		}
	}
	
	.el-select-dropdown__item.selected {
		color: #606266;
		font-weight: 500;
	}
</style>
