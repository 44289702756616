<template>
	<div>
		<div class="a4_list">
			<div class="a4_btn_wrapper">
				<div class="btn01 a4_btn" @click="getPdf('订舱单' + info.order_no)">导出</div>
				<div class="a4_btn flex03 right" v-if="ids.length > 1">
					<p class="btn01" @click="get_btn('up')">上一个</p>
					<p class="btn01" style="margin-left: 20px;" @click="get_btn('down')">下一个</p>
				</div>
			</div>
			<div class="a4" :id="'订舱单' + info.order_no">
				<p class="a4_title">订 舱 单</p>
				<div class="a4_top">
					<img :src="$publicFun.get_LostName().logo1" />
					<p>{{$publicFun.get_LostName().nameText}}</p>
				</div>
				<p class="a4_top_line"></p>
				<p class="a4_top_line"></p>
				<div class="a4_info">
					<div class="a4_info_block">
						<p class="bold_">致 {{info.company_id_name}}</p>
					</div>
					<div class="a4_info_block">
						<div class="a4_info_block_list">
							<p>日期：</p>
							<p>{{now_date}}</p>
						</div>
						<div class="a4_info_block_list">
							<p>联系人：</p>
							<p>{{info.linkman_id_name}}</p>
						</div>
						<div class="a4_info_block_list">
							<p>电话：</p>
							<p>{{info.mobile}}</p>
						</div>
						<div class="a4_info_block_list">
							<p>邮箱：</p>
							<p>{{info.email}}</p>
						</div>
					</div>
				</div>
				<div>
					<div class="a4_info" style="border-bottom: none;">
						<div class="a4_info_block">
							<div class="a4_info_block_list">
								<p>开航日期：</p>
								<p>{{info.shipment_date}}</p>
							</div>
							<div class="a4_info_block_list">
								<p>起运港：</p>
								<p>{{info.shipment_id_name}}</p>
							</div>
							<div class="a4_info_block_list">
								<p>件数：</p>
								<p>{{info.cases}}</p>
							</div>
							<div class="a4_info_block_list">
								<p>毛重：</p>
								<p>{{info.rough_weight}} Kgs</p>
							</div>
							<div class="a4_info_block_list">
								<p>货物名称：</p>
								<p>{{info.chinese_name}}</p>
							</div>
							<div class="a4_info_block_list">
								<p>参考价格：</p>
								<p>{{info.price}} RMB/KG</p>
							</div>
						</div>
						<div class="a4_info_block">
							<div class="a4_info_block_list">
								<p>船名/航次：</p>
								<p>{{info.vessel_voyage}}</p>
							</div>
							<div class="a4_info_block_list">
								<p>目的港：</p>
								<p>{{info.destination_id_name}}</p>
							</div>
							<div class="a4_info_block_list">
								<p>尺寸：</p>
								<p v-html="info.chicun"></p>
							</div>
							<div class="a4_info_block_list">
								<p>计重：</p>
								<p>{{info.weight}} Kgs</p>
							</div>
							<div class="a4_info_block_list">
								<p>备注：</p>
								<p>{{info.remark}}</p>
							</div>
						</div>
					</div>
				</div>
				<p class="a4_des">感谢贵方的支持！</p>
			</div>
		</div>
	</div>
</template>

<script>
	import {
		dcDetail
	} from '@/api/index';

	export default {
		data() {
			return {
				info: {},
				index: 0,
				ids: [], // id合集
				now_id: '', // 当前的id		
				now_date: '', // 年月日
			}
		},
		created() {
			this.now_date = this.$publicFun.addDate();

			this.ids = this.$route.query.id.split(',');
			this.get_int();
		},
		methods: {
			// 详情数据
			get_detail() {
				dcDetail({
					id: this.now_id
				}).then(res => {
					this.info = res.data;

					// 货物信息
					let cargo_ = res.data.info;
					let shipment_date = ''; // 开航日期
					let flight_name = ''; // 船名/航次
					let cases = 0; // 件数
					let rough_weight = 0; // 毛重
					let chinese_name = ''; // 货物名称
					let chicun = ''; // 尺寸
					let weight = 0; // 计重
					let low_value = 0; // 参考价格

					if (cargo_.length > 0) {
						cargo_.forEach((n, k) => {
							shipment_date += n.shipment_date + '/';
							flight_name += n.flight_name + '/';
							cases += parseFloat(n.cases);
							rough_weight += parseFloat(n.rough_weight);
							chinese_name += n.chinese_name + '/';
							chicun += n.cases + '*' + n.long + '*' + n.broad + '*' + n.tall + ' CM</br>';
							weight += parseFloat(n.weight);

							if (n.low_value) {
								low_value += parseFloat(n.low_value);
							}
						})
					}

					this.info.shipment_date = shipment_date.substr(0, shipment_date.length - 1);
					this.info.flight_name = flight_name.substr(0, flight_name.length - 1);
					this.info.cases = cases;
					this.info.rough_weight = rough_weight.toFixed(2);
					this.info.chinese_name = chinese_name.substr(0, chinese_name.length - 1);
					this.info.chicun = chicun;
					this.info.weight = weight.toFixed(2);
					this.info.low_value = low_value.toFixed(2);
				})
			},
			//
			get_int() {
				this.now_id = this.ids[this.index];
				this.get_detail();
			},
			get_btn(e) {
				if (e === 'up') {
					// 上
					if (this.index === 0) return this.$message('没有上一个了！');

					this.index--;
					this.get_int();
				}

				if (e === 'down') {
					// 下
					if (this.index === (this.ids.length - 1)) return this.$message('没有下一个了！');

					this.index++;
					this.get_int();
				}
			}
		}
	}
</script>

<style lang="less">

</style>
