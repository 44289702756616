import Vue from "vue";
import Vuex from "vuex";
import offerAdd from './offeradd'
import moduleAdd from './moduleadd'

Vue.use(Vuex);

export default new Vuex.Store({
  state: {},
  mutations: {},
  actions: {},
  modules: {
    offerAdd,moduleAdd
  },
});
